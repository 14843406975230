import React from 'react';
import {connect} from 'react-redux'
import {getItem, getFormData, submit} from '../../reducers/payable'
import {get} from "../../axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SelectInput from "../common/SelectInput";
import {awaitAll} from "../../helper";
import DateInput from "../common/DateInput";
import Date from "../common/Date";
import {Link} from "react-router-dom";

class PayableItem extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            tab:null,
            invoice_date:null,
            items:[],
            remarks:'',
        };
        this.submit = this.submit.bind(this);
        this.addItem = this.addItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
    }

    componentDidMount(){
        if(this.props.match && this.props.match.params.id){
            this.props.getItem(this.props.match.params.id);
        }
        this.getData();
    }


    componentWillReceiveProps(props){
        if(props.item && props.item !== this.props.item){
            console.log(props.item);
            this.setState({...props.item})
        }
    }

    async getData(){
        let {getFormData, get} = this.props;
        try{

             let paymentMethods = get(`/payment_methods`);
             let data = await awaitAll({paymentMethods});
             getFormData(data)
        }catch (e) {
            console.error(e)
        }
    }


    addItem(item){
        let {items} = this.state;
        items.push(item);
        this.setState({items, tab:null});
    }


    removeItem(index){
        let {items} = this.state;
        let _items = items.map((item,i)=>{
            if(index === i){
                return {...item, is_deleted:true}
            }else{
                return item
            }
        });
        this.setState({items : _items, edit:null});
    }

    submit(){
        let {history} = this.props;
        this.props.submit( this.state, history);
    }

    render(){
        const {data} = this.props;
        const { tab, invoice_number, order_number, currency, amount, created_at, supplier, invoice_date, remarks, items} = this.state;
        let activeTab = 'btn btn-sm btn-primary mr-2';
        let inactiveTab = 'btn btn-sm btn-outline-primary mr-2';
        return <div style={{position:'relative', height:'100%'}}>
            <div style={{position:'absolute', left:0, top:0, bottom:0, right:0}}>
                <div className="mt-3">
                    <button className="btn btn-primary btn-sm" onClick={this.submit}>
                        儲存
                    </button>
                </div>
                <div className="p-3 mt-3" style={{background:'#fff'}}>
                    <div className="row">
                        <label className="col-md-2 font-weight-bold">帳單編號</label>
                        <div className="col-md-3 font-weight-bold">
                            {invoice_number}
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-md-2 font-weight-bold">輸入日期</label>
                        <div className="col-md-10 font-weight-bold">
                            <Date date={created_at}/>
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-md-2 font-weight-bold">總數</label>
                        <div className="col-md-10 font-weight-bold">
                            {currency} {(+amount).toLocaleString()}
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-md-2 font-weight-bold">供應者</label>
                        <div className="col-md-10 font-weight-bold">
                            {supplier}
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-md-2 font-weight-bold">銷售號碼</label>
                        <div className="col-md-10 font-weight-bold">
                            <Link className="btn btn-secondary btn-sm" to={`/purchases/item/${order_number}`}>{order_number}</Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            <label>帳單日期</label>
                        </div>
                        <div className="col-md-10">
                            <DateInput  value={invoice_date} onChange={(invoice_date)=>this.setState({invoice_date})}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            <label>備註</label>
                        </div>
                        <div className="col-md-10">
                            <textarea className="form-control" value={remarks} onChange={(e)=>this.setState({remarks:e.target.value})}/>
                        </div>
                    </div>
                </div>
                <div className="p-3 mt-3" style={{background:'#fff'}}>
                    <h6 className="font-weight-bold">付款紀錄</h6>
                    <table className="table">
                        <thead>
                        <tr>
                            <th>付款日期</th>
                            <th>付款方付</th>
                            <th>總數</th>
                            <th>輸入者</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {items.filter((item)=>!item.is_deleted).map((item, index)=><tr key={index}>
                            <td><Date date={item.payment_date}/></td>
                            <td>{item.payment_method}</td>
                            <td>{item.amount}</td>
                            <td>{item.creator}</td>
                            <td>
                                <button className="btn btn-sm btn-danger" onClick={()=>this.removeItem(index)}>
                                    刪除
                                </button>
                            </td>
                        </tr>)}
                        </tbody>
                    </table>
                    <h6>加入項目</h6>
                    <button className={tab===1? activeTab:inactiveTab} onClick={()=>this.setState({tab:1, edit:null})}>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                        付款
                    </button>
                    {tab !== null && <FormWrapper tab={tab} addItem={this.addItem} data={data}/>}
                </div>
            </div>
        </div>
    }
}


class FormWrapper extends React.Component{
    constructor(props){
        super(props);
        this.state = this.initialState();
        this.add = this.add.bind(this);
    }

    initialState(){
        return {
            payment_method_id :null,
            payment_date:'',
            amount:'',
            error:null
        };
    }

    add(){
        let error = null;
        let {payment_method_id, payment_date, amount} = this.state;
        if(!payment_method_id){
            error ='請輸入付款方式'
        }else if(!payment_date){
            error ='請輸入付款日期'
        }else if(!amount){
            error ='請輸入數目'
        }
        if(error){
            this.setState({error})
        }else{
            this.props.addItem(this.state);
        }
    }

    render() {
        const {data} = this.props;
        const {payment_method_id, invoice_date , amount} = this.state;
        return <div style={{border: '1px solid #0275d8'}}>
            <div className="p-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4">
                            <label>付款方式</label>
                            <SelectInput items={data.paymentMethods} onChange={(id)=>this.setState({payment_method_id:id})} value={payment_method_id}/>
                            </div>
                        <div className="col-md-4">
                            <label>數目</label>
                            <input className="form-control" type='number' value={amount} onChange={(e)=>this.setState({amount:+e.target.value})}/>
                        </div>
                        <div className="col-md-4">
                            <label>付款日期</label>
                            <DateInput onChange={(date)=>this.setState({payment_date:date})}  value={invoice_date}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-4">
                            <div className="text-danger">{this.state.error}</div>
                        </div>
                        <div className="col-md-4"/>
                        <div className="col-md-4">
                            <button className="btn btn-primary float-right" onClick={this.add}>加入</button>
                            <div className="clearfix"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}
export default connect((state)=>{return{data: state.payable.data, item:state.payable.item,}}, {get, getItem, getFormData, submit})(PayableItem);
