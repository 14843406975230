import {get, post} from '../axios';
const GET_FORM_DATA = 'GET_PAYABLE_FORM_DATA';
const GET_ITEMS = 'GET_PAYABLE';
const GET_ITEM = 'GET_PAYABLE_ITEM';
const initialState= {
    data:null,
    list:[],
    item:null
};


export default function (state = initialState, action){
    switch (action.type){
        case GET_ITEMS:
            return Object.assign({}, state, {list: action.data});
        case GET_FORM_DATA:
            return Object.assign({}, state, {data: action.data});
        case GET_ITEM:
            return Object.assign({}, state, {item: action.data});
        default:
            return state;
    }
}

export function getItems(completed){
    return async (dispatch)=>{
        let data = await dispatch(get(`/payables`, completed ?{completed}:{} ));
        return dispatch({type:GET_ITEMS, data:data})
    }
}

export function getItem(id){
    return async (dispatch)=>{
        let data = await dispatch(get(`/payables/${id}`));
        return dispatch({type:GET_ITEM, data:data})
    }
}

export function getFormData(data){
    return  {type:GET_FORM_DATA, data}
}


export function submit(data, history){
    return async (dispatch)=>{
        try{
            await dispatch(post(`/payables/${data.invoice_number?data.invoice_number:''}`, data));
            history.push('/payable')
        }catch (e) {
            console.log(e)
        }
    }
}