import {get, post} from '../axios';
const GET_SUPPLIERS = 'GET_SUPPLIERS';
const initialState= {
    list:[]
};


export default function (state = initialState, action){
    switch (action.type){
        case GET_SUPPLIERS:
            return Object.assign({}, state, {list: action.data});
        default:
            return state;
    }
}

export function getItems(){
    return async (dispatch)=>{
        let data = await dispatch(get(`/suppliers`));
        return dispatch({type:GET_SUPPLIERS, data:data})
    }
}