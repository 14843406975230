import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {get} from '../../axios';
import {connect} from 'react-redux';
import SelectInput from "./SelectInput";
class ProductTrackingCodeInput extends React.Component{
    constructor(props){
        super(props);
        this.state  = {
            product_id:null,
            tracking_id: null,
            trackers:[],
            quantity:"",
            loading:false,
            loadingTacker:false,
            error:'',
            submitError:'',
        };
        this.search = this.search.bind(this);
        this.searchTrackers = this.searchTrackers.bind(this);
        this.submit = this.submit.bind(this);
        this.getProducts = this.getProducts.bind(this);
    }

    componentDidMount(){
        this.getProducts(this.props.category_id)
    }

    componentWillReceiveProps(props){
        if(this.props.category_id !== props.category_id){
            this.getProducts(props.category_id)
        }
    }

    getProducts(category_id){
        let {get} = this.props;
        get('/stocks/products', {category_id}).then((products)=>{
            this.setState({products})
        }).catch((e)=>{
        })
    }

    searchTrackers(e){
        e.preventDefault();
        let {product_id} = this.state;
        if(product_id){
            let {get} = this.props;
            this.setState({loadingTacker:true, error:''});
            get('/stocks', {product_id}).then((trackers)=>{
                if(trackers === null){
                    this.setState({error:'找不到', loadingTacker:false})
                }else{
                    trackers = trackers.filter(({quantity})=>+quantity>0);
                    this.setState({trackers, loadingTacker:false})
                }
            }).catch((e)=>{
                this.setState({error:'找不到', loadingTacker:false})
            })
        }
    }

    search(e){
        e.preventDefault();
        let {tracking_id} = this.state;
        if(tracking_id){
            let {get} = this.props;
            this.setState({loading:true, error:''});
            get('/stocks/available', {tracking_id}).then((data)=>{
                if(data === null){
                    this.setState({error:'找不到', loading:false})
                }else{
                    this.setState({data, loading:false})
                }
            }).catch((e)=>{
                this.setState({error:'找不到', loading:false})
            })
        }
    }


    submit(e){
        e.preventDefault();
        let {onChange, items} = this.props;
        let {quantity, data} = this.state;
        if(data.quantity < +quantity) {
            this.setState({submitError: '產品剩餘數量不足'})
        }else if(items && items.find((item)=>item.tracking_id === data.tracking_id)){
            this.setState({submitError: '同一項目不能重複加入'})
        }else{
            onChange({quantity:+quantity, tracking_id: data.tracking_id, title:data.title, price:data.price, product_id: data.product_id, remarks:data.remarks, cost:data.cost})
        }
    }


    render(){
        const {products, tracking_id, product_id, data, loading,loadingTacker, error, quantity, submitError, trackers} = this.state;
        if(!products){
            return <div/>
        }
        return <div>
            <form className="form" onSubmit={this.searchTrackers}>
                <div className="row">
                    <label className="col-md-2 font-weight-bold">產品</label>
                    <div className="col-md-10 font-weight-bold form-inline">
                        <SelectInput value={product_id}
                                     items={products}
                                     onChange={(v)=>this.setState({product_id:v})}
                                     uniqueKey="product_id"/>
                        <button className="btn btn-warning btn-sm ml-2">
                            <FontAwesomeIcon icon='search' fixedWidth={true} spin={loadingTacker}/>
                            搜索
                        </button>
                        <span className="text-danger ml-2">{error}</span>
                    </div>
                </div>
            </form>
            <form className="form" onSubmit={this.search}>
                <div className="row mt-3">
                    <label className="col-md-2 font-weight-bold">追蹤號碼</label>
                    <div className="col-md-10 font-weight-bold form-inline">
                        <SelectInput value={tracking_id}
                                     items={trackers.map((t)=>{return {...t, name:`${t.tracking_id} ${t.remarks}`}})}
                                     onChange={(v)=>this.setState({tracking_id:v})}
                                     title="name"
                                     uniqueKey="tracking_id"/>
                        <button className="btn btn-warning btn-sm ml-2">
                            <FontAwesomeIcon icon='plus' fixedWidth={true} spin={loading}/>
                            加入
                        </button>
                        <span className="text-danger ml-2">{error}</span>
                    </div>
                </div>
            </form>
            {data &&
            <form className="mt-3 pt-3" style={{borderTop:'1px solid #aaa'}} onSubmit={this.submit}>
                <div>
                    <div className="font-weight-bold">{data.title} 剩餘數量 {data.quantity}</div>
                </div>
                <div className="row mt-3">
                    <label className="col-md-2 font-weight-bold">數量</label>
                    <div className="col-md-10 font-weight-bold form-inline">
                        <input className="form-control" value={quantity} onChange={(e)=>this.setState({quantity:e.target.value})} type="number"/>
                        <button className="btn btn-primary btn-sm ml-2">
                            <FontAwesomeIcon icon='plus' fixedWidth={true} spin={loading}/>
                            加入
                        </button>
                        <span className="text-danger ml-2">{submitError}</span>
                    </div>
                </div>
            </form>
            }
        </div>
    }
}

export default connect((state)=>{return{}},{get})(ProductTrackingCodeInput)