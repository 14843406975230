import React from 'react';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Date from "../common/Date";
import {get} from '../../axios'
import {awaitAll} from '../../helper'
import {getFormData, getItem, submit, cancel} from "../../reducers/kioskSale";
import {getProduct} from "../../reducers/product";
import TrackingCodeInput from "../common/TrackingCodeInput";
import ProductTrackingCodeInput from "../common/ProductTrackingCodeInput";
import ServiceInput from "../common/ServiceInput";
import OtherItemInput from "../common/OtherItemInput";
class KioskSalesItem extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            error:'',
            items:[],
            tab:0,
        };
        this.formChange = this.formChange.bind(this);
        this.addItem = this.addItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.changeItemPrice = this.changeItemPrice.bind(this);
        this.cancel = this.cancel.bind(this);
        this.submit = this.submit.bind(this);
        this.submitAndConvert = this.submitAndConvert.bind(this);
    }


    componentDidMount(){
        if(this.props.match && this.props.match.params.id){
            this.props.getItem(this.props.match.params.id);
        }
        this.getData();
    }

    componentWillReceiveProps(props){
        if(props.item && props.item !== this.props.item){
            this.setState({...props.item})
        }
    }

    submit(){
        console.log('submit');
        let {history} = this.props;
        let {items, id} = this.state;
        this.props.submit( {items, id}, history);
    }

    submitAndConvert(){
        console.log('submitAndConvert');
        let {history} = this.props;
        let {items, id} = this.state;
        try{
            for (const item of items){
                if(!item.is_deleted && item.require_tracking_id){
                    throw '請確保所有產品有追蹤號碼'
                }
            }
            this.props.submit( {items, id}, history, true);
        }catch (e) {
            this.setState({error:e})
        }
    }

    cancel(){
        console.log('delete');
        let {history} = this.props;
        let {id} = this.state;
        this.props.cancel( id, history);
    }


    addItem(item){
        let {items} = this.state;
        items.push(item);
        this.setState({items, tab:null});
    }

    changeItemPrice(index, price){
        let {items} = this.state;
        let item = items[index];
        item.net_price = +price;
        let _items = [...items];
        _items[index] = item;
        this.setState({items});
    }

    removeItem(index){
        let {items} = this.state;
        let _items = [...items];
        _items.splice(index,1)
        this.setState({items : _items, edit:null});
    }

    formChange(values){
        this.setState({...values})
    }

    async getData(){
        let {getFormData, get} = this.props;
        try{
             let clients = get(`/clients`);
             let users = get(`/users`);
             let data = await awaitAll({clients, users});
             getFormData(data)
        }catch (e) {
            console.error(e)
        }
    }

    render() {
        let {data} = this.props;
        let {error, customer_name, car_plate, car_model, month, contact, items, tab, created_at, reservice_date, id}  = this.state;
        let activeTab = 'btn btn-sm btn-primary mr-2';
        let inactiveTab = 'btn btn-sm btn-outline-primary mr-2';
        if(!data){
            return <div/>
        }
        return <div style={{position:'relative', height:'100%'}}>
            <div style={{position:'absolute', left:0, top:0, bottom:0, right:0}}>
                <div className="mt-3">
                    {id &&
                    <button className="btn btn-primary btn-sm" onClick={this.submitAndConvert}>
                        轉為正式銷售
                    </button>
                    }
                    <button className="btn btn-success btn-sm ml-2" onClick={this.submit}>
                        臨時儲存
                    </button>
                    {id &&
                    <button className="btn btn-danger btn-sm ml-2" onClick={this.cancel}>
                        取消
                    </button>
                    }
                </div>
                <div className="text-danger">
                    {error}
                </div>
                <div className="p-3 mt-3" style={{background:'#fff'}}>
                    <div className="row">
                        <label className="col-md-2 font-weight-bold">銷售編號</label>
                        <div className="col-md-10 font-weight-bold">
                            {id}
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-md-2 font-weight-bold">建立日期</label>
                        <div className="col-md-10 font-weight-bold">
                            <Date date={created_at} time={true}/>
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-md-2 font-weight-bold">免費檢查日期</label>
                        <div className="col-md-10 font-weight-bold">
                            {reservice_date &&
                            <Date date={reservice_date} time={false}/>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-md-2 font-weight-bold">客戶名稱</label>
                        <div className="col-md-10 font-weight-bold">
                            {customer_name}
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-md-2 font-weight-bold">電話</label>
                        <div className="col-md-10 font-weight-bold">
                            {contact}
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-md-2 font-weight-bold">車款</label>
                        <div className="col-md-10 font-weight-bold">
                            {car_model}
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-md-2 font-weight-bold">車牌</label>
                        <div className="col-md-10 font-weight-bold">
                            {car_plate}
                        </div>
                    </div>
                    <h6 className="font-weight-bold">銷售項目</h6>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>追蹤號碼</th>
                                <th>產品</th>
                                <th>價格</th>
                                <th>Remarks</th>
                                <th>數量</th>
                                <th>小計</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {items.map((item, index)=><tr key={index}>
                            <td>{item.tracking_id}</td>
                            <td>{item.title}</td>
                            <td>
                                {item.net_price}
                                <input className="ml-2" style={{border:'1px solid #eaeaea'}} type="number" onChange={(e)=> this.changeItemPrice(index, e.target.value)}/>
                            </td>
                            <td>
                                {item.remarks}
                            </td>
                            <td>{item.quantity}</td>
                            <td>{+item.quantity * +item.net_price}</td>
                            <td><button className="btn btn-sm btn-danger" onClick={()=>this.removeItem(index)}>刪除</button></td>
                        </tr>)}
                        </tbody>
                    </table>
                    <h6>加入項目</h6>
                    <button className={tab===0? activeTab:inactiveTab} onClick={()=>this.setState({tab:0})}>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                        追蹤號碼
                    </button>
                    <button className={tab===2? activeTab:inactiveTab} onClick={()=>this.setState({tab:2})}>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                        輪胎
                    </button>
                    <button className={tab===3? activeTab:inactiveTab} onClick={()=>this.setState({tab:3})}>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                        電池
                    </button>
                    <button className={tab===4? activeTab:inactiveTab} onClick={()=>this.setState({tab:4})}>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                        車鈴
                    </button>
                    <button className={tab===5? activeTab:inactiveTab} onClick={()=>this.setState({tab:5})}>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                        汽車用品
                    </button>
                    <button className={tab===1? activeTab:inactiveTab}  onClick={()=>this.setState({tab:1})}>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                        服務
                    </button>
                    <button className={tab=== 6? activeTab:inactiveTab}  onClick={()=>this.setState({tab:6})}>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                        其他
                    </button>
                    {tab!==null &&
                    <FormWrapper tracking_mode={tab===0} category_id={tab} addItem={this.addItem} items={items}/>
                    }
                </div>
            </div>
        </div>
    }
}


class FormWrapper extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            tracking_id: "",
            product_id:null,
            price:'',
            quantity:1
        };
    }

    render() {
        const {item} = this.state;
        let {tracking_mode, addItem, items, category_id} = this.props;
        if(tracking_mode){
            return (
                <div style={{border: '1px solid #0275d8'}}>
                    {!item &&
                    <div className="p-3">
                        <TrackingCodeInput onChange={(item)=>addItem({...item, net_price:item.price})} items={items.filter((item)=>!item.is_deleted)}/>
                    </div>
                    }
                </div>
            );
        }else{
            if(category_id === 1){
                return (
                    <div style={{border: '1px solid #0275d8'}}>
                        {!item &&
                        <div className="p-3">
                            <ServiceInput onChange={(item)=>addItem({...item, net_price:item.price})} items={items.filter((item)=>!item.is_deleted)}/>
                        </div>
                        }
                    </div>
                );
            }else if(category_id === 6){
                return   <div style={{border: '1px solid #0275d8'}}>
                    {!item &&
                    <div className="p-3">
                        <OtherItemInput
                            onChange={(item)=>addItem({...item, net_price:item.price})}/>
                    </div>
                    }
                </div>
            }
            return (
                <div style={{border: '1px solid #0275d8'}}>
                    {!item &&
                    <div className="p-3">
                        <ProductTrackingCodeInput
                            onChange={(item)=>addItem({...item, net_price:item.price})}
                            items={items.filter((item)=>!item.is_deleted)}
                            category_id={category_id}/>
                    </div>
                    }
                </div>
            );
        }
    }
}


FormWrapper =connect((state)=>{return{list: state.product.list}}, {getProduct})(FormWrapper);
export default connect((state)=>{return{data: state.kioskSale.data, item:state.kioskSale.item,}},
    {get, getItem, getFormData, submit, cancel})(KioskSalesItem);
