import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {get, post} from '../../axios'
import {getItems, getItemDetail} from '../../reducers/sale';
import {getItems as getTempItems} from '../../reducers/tempSale';
import {getItems as getKioskItems} from '../../reducers/kioskSale';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Table from "../common/Table";
import Date from "../common/Date";
import moment from 'moment';
import Invoices from '../reports/Invoices';
import Profit from '../reports/Profit';
import Cash from '../reports/Cash';
import Outstanding from '../reports/Outstanding';
import DateInput from "../common/DateInput";
class Sales extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            tab:1, invoices:null, open : false, open2 : false,
            open3: false,
            remark:'', showRemarksInput:false
        };
        this.downloadInvoices = this.downloadInvoices.bind(this);
        this.generateProfitReport = this.generateProfitReport.bind(this);
        this.outstandingReport = this.outstandingReport.bind(this);
        this.closeReport = this.closeReport.bind(this);
        this.closeReport2 = this.closeReport2.bind(this)
        this.closeReport3 = this.closeReport3.bind(this)
        this.loadRemarks = this.loadRemarks.bind(this)
        this.submitRemark = this.submitRemark.bind(this)
    }

    componentDidMount(){
        this.props.getItems();
        this.props.getTempItems();
        this.props.getKioskItems();
    }

    closeReport(){
        this.setState({open:false})
    }

    closeReport2(){
        this.setState({open2:false})
    }

    closeReport3(){
        this.setState({open3:false})
    }

    async loadRemarks(){
        const {selectedDate} = this.state;
        const {get} = this.props;
        let remarks = await get(`/report/sales/remark`, {start_date: selectedDate, end_date:selectedDate});
        if(remarks.length>0){
            this.setState({remark:remarks[0].remarks, showRemarksInput:true})
        }else{
            this.setState({remark:'', showRemarksInput:true})
        }
    }

    async submitRemark(){
        const {selectedDate, remark} = this.state;
        const {post} = this.props;
        await post(`/report/sales/remark`, {report_date: selectedDate, remarks:remark});
        this.setState({remark:'', showRemarksInput:false})
    }

    async downloadInvoices(){
        const {selectedRows, get} = this.props;
        let invoices = Object.keys(selectedRows).map(async (invoice_number)=> await get(`/sales/${invoice_number}/profit`));
        invoices = await Promise.all(invoices);
        let items = [];
        for (let i =0 ; i<invoices.length/2; i++){
            items.push({
                sales1: invoices[i*2],
                sales2: invoices[i*2+1]
            })
        }
        let files = [];
        for (const {expenditures} of invoices){
            if(expenditures && expenditures.length>0){
                for (const {order_number} of expenditures){
                    const _files = await get(`/expenditures/${order_number}/files`);
                    files = [...files, ..._files]
                }
            }
        }
        for (const invoice_number in selectedRows){
            const _files = await get(`/sales/${invoice_number}/files`);
            files = [...files, ..._files]
        }

        this.setState({invoices:items, open:true, files})
    }

    async generateProfitReport(){
        const {selectedDate} = this.state;
        const {get, list} = this.props;
        let m = moment(selectedDate);
        let cash = await get('/report/cashflow', {start_date:m.startOf('day').toISOString(), end_date:selectedDate});
        let detail = await get('/report/profit/detail', {start_date:m.startOf('day').toISOString(), end_date:selectedDate});
        let {count:deposit_day} = await get('/report/profit/deposit', {start_date:m.startOf('day').toISOString(), end_date:selectedDate});
        let carService_day = await get('/report/profit/carService', {start_date:m.startOf('day').toISOString(), end_date:selectedDate});
        let month = await get('/report/profit/detail', {start_date:m.startOf('month').toISOString(), end_date:selectedDate});
        let remarks = await get(`/report/sales/remark`, {start_date:m.startOf('month').toISOString(), end_date:selectedDate});
        let onDuties = await get(`/management/checkinReport/daily`, {date:moment(selectedDate).format('DD/MM/YYYY')});
        let workShiftsDay = await get(`/management/workShift`, {exact_date:moment(selectedDate).format('DD/MM/YYYY')});
        let workShiftsMonth = await get(`/management/workShift`,
            {start_date:moment(selectedDate).startOf('month').format('DD/MM/YYYY'), end_date:moment(selectedDate).format('DD/MM/YYYY')});
        let {count:deposit_month} = await get('/report/profit/deposit', {start_date:m.startOf('month').toISOString(), end_date:selectedDate});
        let carService_month = await get('/report/profit/carService', {start_date:m.startOf('month').toISOString(), end_date:selectedDate});
        m = moment(selectedDate);
        this.setState({profitReport:{month,deposit_day,deposit_month,carService_day,carService_month, detail, date:m, remarks, workShiftsDay, workShiftsMonth, cash, onDuties}, open2:true})
    }

    async outstandingReport(){
        const {selectedDate} = this.state;
        const {get} = this.props;
        let m = moment(selectedDate);
        let items = await get('/report/cashflow/outstanding', {date:m.startOf('day').toISOString()});
        this.setState({outstanding:items, open3:true})
    }


    render() {
        const {tab, invoices, files, open, open2, open3, selectedDate, profitReport, outstanding, remark, showRemarksInput} = this.state;
        const {user, getItemDetail, list, list2, list3, selectedRows} = this.props;
        const tabClass = "custom-tab";
        const aTabClass = "custom-tab active";
        return (
            <div className="mt-3" ref="test">
                <div>
                    <Link to='/sales/item' className='btn btn-primary btn-sm'>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>新銷售
                    </Link>
                    <Link to='/sales/temp/item' className='btn btn-primary btn-sm ml-2'>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>臨時銷售
                    </Link>
                    {user.is_admin && selectedRows &&
                    <button className="btn btn-success btn-sm ml-2" onClick={this.downloadInvoices}>
                        列印利潤報告({Object.keys(selectedRows).length})
                    </button>
                    }
                    <div className="d-inline-block ml-2" style={{border:'1px solid #aaa'}}>
                        <span className="ml-2">選擇日期</span>
                        <DateInput className="d-inline-block ml-2" endTime={true}  onChange={(v)=>this.setState({selectedDate:v})} value={selectedDate}/>
                        {selectedDate &&
                        <React.Fragment>
                            <button className="btn btn-success btn-sm ml-2" onClick={this.generateProfitReport}>
                                建立當日利潤報告
                            </button>
                            <button className="btn btn-success btn-sm ml-2" onClick={this.outstandingReport}>
                                建立未收帳表
                            </button>
                            <button className="btn btn-success btn-sm ml-2" onClick={this.loadRemarks}>
                                輸入Remarks
                            </button>
                        </React.Fragment>
                        }
                    </div>
                    <div>
                        {showRemarksInput &&
                        <React.Fragment>
                            <textarea className="form-control" value={remark} onChange={(e)=> this.setState({remark: e.target.value})}/>
                            <button className="btn btn-success btn-sm ml-2" onClick={this.submitRemark}>
                                提交
                            </button>
                        </React.Fragment>
                        }
                    </div>
                </div>
                <div className="mt-3">
                    <div className={tab === 1 ? aTabClass: tabClass} onClick={()=>this.setState({tab:1})}>
                        完成銷售
                    </div>
                    <div className={tab === 2 ? aTabClass: tabClass}  onClick={()=>this.setState({tab:2})}>
                        臨時銷售
                    </div>
                    <div className={tab === 3 ? aTabClass: tabClass}  onClick={()=>this.setState({tab:3})}>
                        Kiosk臨時銷售
                    </div>
                </div>
                <div className="custom-tab-body">
                    {tab === 1 &&
                    <Table data={list}
                           accessors={[
                               {key:'order_number', title:'銷售編號'},
                               {key:'version', title:'版本'},
                               {key:'total', title:'總數'},
                               {key:'customer', title:'客戶名稱'},
                               {key:'contact', title:'電話'},
                               {key:'car_brand', title:'車廠'},
                               {key:'car_model', title:'車款'},
                               {key:'car_plate', title:'車牌'},
                               {key:'reference_number', title:'對單號'},
                               {key:'creator', title:'建立者'},
                               {key:'service_at', title:'維修日期', render:((item) => <Date date={item.service_at} time={true}/>)},
                               {key:'created_at', title:'更新日期', render:((item) => <Date date={item.created_at}/>)},
                               {key:'id', title:'修改', render:((item) => <Link to={`/sales/item/${item.order_number}`}>修改</Link>)}
                           ]}
                           hiddenRow={(item)=>{
                               let {data} = item;
                               if(!data) return null;
                               return <tr className="text-muted">
                               <td colSpan="8">
                                   <div className="container-fluid">
                                       <div className="row">
                                           <div className="col-2">
                                               入貨編號: {data.order_number}
                                           </div>
                                           <div className="col-2">
                                               顧客名稱: {data.customer}
                                           </div>
                                           <div className="col-2">
                                               聯絡電話: {data.contact}
                                           </div>
                                           <div className="col-2">
                                               車牌: {data.car_plate}
                                           </div>
                                           <div className="col-4"/>
                                       </div>
                                       <div className="mt2 row">
                                           <div className="col-4">
                                               產品資料:
                                           </div>
                                           <div className="col-4">
                                               數量:
                                           </div>
                                       </div>
                                       {data.items.filter((i)=>!i.is_deleted).map((item)=><div className="row mt-2" key={item.id}>
                                           <div className="col-4">
                                               {item.title}
                                           </div>
                                           <div className="col-4">
                                               {item.quantity}
                                           </div>
                                       </div>)}
                                   </div>
                               </td>
                           </tr>}}
                           onExpand={(item)=>getItemDetail(item.order_number)}
                           expandable={true}
                           selectable={true}
                           selectableKey="order_number"
                           tableKey='sales'
                           rowKey='id'
                           search={(item, text)=>item.order_number.toUpperCase().includes(text.toUpperCase())
                               || item.car_plate.toUpperCase().includes(text.toUpperCase())
                               || (item.reference_number ? item.reference_number.toUpperCase().includes(text.toUpperCase()) : false)
                               || String(item.contact).toUpperCase().includes(text.toUpperCase())
                               || item.customer.toUpperCase().includes(text.toUpperCase()) }
                    />
                    }
                    {tab === 2 &&
                    <Table data={list2}
                           accessors={[
                               {key:'id', title:'臨時銷售編號'},
                               {key:'creator', title:'建立者'},
                               {key:'created_at', title:'建立日期', render:((item) => <Date date={item.created_at} time={true}/>)},
                               {key:'id', title:'修改', render:((item) => <Link to={`/sales/temp/item/${item.id}`}>修改</Link>)}
                           ]}
                           tableKey='tempSales'
                           rowKey='id'
                    />
                    }
                    {tab === 3 &&
                    <Table data={list3}
                           accessors={[
                               {key:'id', title:'KIOSK臨時銷售編號'},
                               {key:'customer_name', title:'客戶名稱'},
                               {key:'contact', title:'電話'},
                               {key:'car_model', title:'車款'},
                               {key:'car_plate', title:'車牌'},
                               {key:'creator', title:'建立者'},
                               {key:'created_at', title:'建立日期', render:((item) => <Date date={item.created_at} time={true}/>)},
                               {key:'id', title:'修改', render:((item) => <Link to={`/sales/kiosk/item/${item.id}`}>修改</Link>)}
                           ]}
                           tableKey='kioskSales'
                           rowKey='id'
                    />
                    }
                </div>
                {invoices &&
                <Invoices items={invoices} files={files} open={open} close={this.closeReport}/>
                }
                <Profit open={open2} profitReport={profitReport} close={this.closeReport2}/>
                <Cash open={open2} profitReport={profitReport} close={this.closeReport2}/>
                <Outstanding open={open3}
                             date={moment(selectedDate)}
                             outstanding={outstanding}
                             close={this.closeReport3}/>
            </div>
        );
    }
}

export default connect((state)=>{return {
    user:state.core.user,
    list:state.sale.list, list2:state.tempSale.list, list3:state.kioskSale.list,
    selectedRows: state.table['sales']? state.table['sales'].selectedRows : null}},
    {get, post,getItems, getItemDetail, getTempItems, getKioskItems}
    )(Sales)
