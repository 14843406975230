import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {connect} from 'react-redux';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {get, post} from '../../axios'
import Date from "../common/Date";
import Table from "../common/Table";
import DateInput from '../common/DateInput';
function Discount({get, post}){
    const [list, setList] = useState([]);
    const [type, setType] = useState('1');
    const [quantity, setQuantity] = useState('');
    const [amount, setAmount] = useState('');
    const [expiry_date, setExpiryDate] = useState(null);
    useEffect(()=>{
        getData();
    },[]);

    const getData = async()=> {
        const data = await get(`/discounts`);
        setList(data);
    };
    const submit = async(e)=>{
        e.preventDefault();
        if(quantity && amount && expiry_date){
            const {result} = await post(`/discounts`, {type, quantity, amount, expiry_date});
            if(result){
                await getData();
            }
        }
    };
    return <div className="mt-3">
        <form onSubmit={submit}>
            <input className="mr-2" placeholder="Type" type="number"
                   value={type}
                   onChange={(e)=>setType(e.target.value)}/>
            <input className="mr-2" placeholder="數量" type="number"
                   value={quantity}
                   onChange={(e)=>setQuantity(e.target.value)}/>
            <input placeholder="價值" type="number" onChange={(e)=>setAmount(e.target.value)}/>
            <div className="d-flex mt-3">
                <label className="mr-2">完結日期</label>
                <DateInput onChange={setExpiryDate} value={expiry_date} />
            </div>
            <button className='btn btn-primary btn-sm my-3'>
                <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                新增優惠卷
            </button>
        </form>
        <div className="p-2" style={{backgroundColor:'#fff'}}>
            <Table data={list}
                   accessors={[
                       {key:'id', title:'ID'},
                       {key:'type', title:'Type'},
                       {key:'discount_code', title:'Code'},
                       {key:'amount', title:'價值'},
                       {key:'is_used', title:'已使用', checkbox:true},
                       {key:'created_at', title:'建立日期', render:((item) => <Date date={item.created_at}/>)},
                       {key:'expiry_date', title:'完結日期', render:((item) => <Date date={item.expiry_date}/>)},
                       {key:'id', title:'修改', render:((item) => <Link to={`/counterparty/item/${item.id}`}>修改</Link>)}
                   ]}
                   tableKey='discounts'
                   search={(item, text)=>item.discount_code.toUpperCase().includes(text.toUpperCase())}
                   rowKey='id'/>
        </div>
    </div>
}

/**
class Discount extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            tab:1
        }
    }
    componentDidMount(){
        this.props.getItems();
    }
    
    render() {
        const {list} = this.props;
        const {tab} = this.state;
        const tabClass = "custom-tab";
        const aTabClass = "custom-tab active";
        return (
            <div className="mt-3">
                <div>
                    <Link to='/counterparty/item' className='btn btn-primary btn-sm'>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                        新增客戶
                    </Link>
                </div>
                <div className="mt-3">
                    <div className={tab === 1 ? aTabClass: tabClass} onClick={()=>this.setState({tab:1})}>
                        現有客戶
                    </div>
                    <div className={tab === 2 ? aTabClass: tabClass} onClick={()=>this.setState({tab:2})}>
                        已刪除客戶
                    </div>
                </div>
                <div className="custom-tab-body">
                    {tab ===1 &&
                    <Table data={list}
                           accessors={[
                               {key:'title', title:'名稱'},
                               {key:'is_company', title:'是公司', checkbox:true},
                               {key:'is_customer', title:'是客戶', checkbox:true},
                               {key:'is_supplier', title:'是供應商', checkbox:true},
                               {key:'created_at', title:'建立日期', render:((item) => <Date date={item.created_at} time={true}/>)},
                               {key:'id', title:'修改', render:((item) => <Link to={`/counterparty/item/${item.id}`}>修改</Link>)}
                           ]}
                           tableKey='counterparties'
                           rowKey='id'
                           search={(item, text)=>item.title.toUpperCase().includes(text.toUpperCase())}
                    />
                    }
                    {tab === 2 &&
                    <Table data={[]}
                           accessors={[
                               {key:'title', title:'名稱'},
                               {key:'is_customer', title:'是客戶', checkbox:true},
                               {key:'is_supplier', title:'是供應商', checkbox:true},
                               {key:'created_at', title:'建立日期', render:((item) => <Date date={item.created_at} time={true}/>)},
                               {key:'id', title:'修改', render:((item) => <Link to={`/counterparty/item/${item.id}`}>修改</Link>)}
                           ]}
                           tableKey='counterparties_deleted'
                           rowKey='id'
                    />
                    }
                </div>
            </div>
        );
    }
}
 **/

export default connect((state)=>{return {}}, {get, post})(Discount)