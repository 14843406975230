import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from 'react-redux';
import {getItems} from '../../reducers/payable'
import Table from "../common/Table";
import Date from "../common/Date";
class Payable extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            tab:1
        };
        this.switchTab = this.switchTab.bind(this);
    }

    componentDidMount(){
        this.props.getItems(false);
    }

    switchTab(tab){
        switch (tab) {
            case 1:
                this.props.getItems(false);
                break;
            case 2:
                this.props.getItems(true);
                break;
        }
        this.setState({tab})
    }

    render() {
        const {list} = this.props;
        const {tab} = this.state;
        const tabClass = "custom-tab";
        const aTabClass = "custom-tab active";
        return (
            <div>
                <div className="mt-3">
                    <div>
                        <div className={tab === 1 ? aTabClass: tabClass} onClick={()=>this.switchTab(1)}>
                            未付款項目
                        </div>
                        <div className={tab === 2 ? aTabClass: tabClass}  onClick={()=>this.switchTab(2)}>
                            己完成項目
                        </div>
                    </div>
                    <div className="custom-tab-body">
                        <div className="custom-tab-body py-2">
                            <Table data={list}
                                   accessors={[
                                       {key:'invoice_number', title:'應付帳編號'},
                                       {key:'version', title:'版本'},
                                       {key:'amount', title:'總數'},
                                       {key:'amount', title:'剩餘', render:((item) => <span>{+item.amount - +item.paid}</span>)},
                                       {key:'supplier', title:'供應商'},
                                       {key:'reference_number', title:'供應商編號'},
                                       {key:'creator', title:'建立者'},
                                       {key:'created_at', title:'建立日期', render:((item) => <Date date={item.created_at} time={true}/>)},
                                       {key:'invoice_date', title:'帳單日期', render:((item) => <Date date={item.invoice_date} time={false}/>)},
                                       {key:'id', title:'修改', render:((item) => <Link to={`/payable/item/${item.invoice_number}`}>修改</Link>)}
                                   ]}
                                   tableKey='payable'
                                   rowKey='id'
                                   search={(item, text)=>item.invoice_number.toUpperCase().includes(text.toUpperCase())}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state)=>{return {list:state.payable.list, users:state.user.list}},{getItems})(Payable)