import React from 'react';
import DateInput from "../common/DateInput";
import {connect} from 'react-redux';
import {get} from "../../axios";
import {getFormData, getItem, submit} from "../../reducers/cash";
import SelectInput from "../common/SelectInput";
import {awaitAll} from "../../helper";
class CashItem extends React.Component{
    constructor(props){
        super(props);
        this.state = {

        };
        this.submit = this.submit.bind(this);
    }

    componentDidMount(){
        console.log(this.props.match );
        if(this.props.match && this.props.match.params.id){
            this.props.getItem(this.props.match.params.id);
        }
        this.getData();
    }

    componentWillReceiveProps(props){
        if(props.item && props.item !== this.props.item){
            this.setState({...props.item})
        }
    }

    async getData(){
        let {getFormData, get} = this.props;
        try{
            let paymentMethods = get(`/payment_methods`);
            let data = await awaitAll({paymentMethods});
            getFormData(data)
        }catch (e) {
            console.error(e)
        }
    }

    submit(){
        let {history} = this.props;
        this.props.submit( this.state, history);
    }

    render(){
        const {payment_date, amount, remarks, payment_method_id, is_deleted, id} = this.state;
        const {data} = this.props;
        if(!data){
            return <div/>
        }
        return <div style={{position:'relative', height:'100%'}}>
            <div style={{position:'absolute', left:0, top:0, bottom:0, right:0}}>
                <div className="mt-3">
                    <button className="btn btn-primary btn-sm" onClick={this.submit}>
                        儲存
                    </button>
                </div>
                <div className="p-3 mt-3" style={{background:'#fff'}}>
                    <div className="row">
                        <label className="col-md-2 font-weight-bold">ID</label>
                        <div className="col-md-3 font-weight-bold">
                            {id}
                        </div>
                    </div>
                    <div className="row mt-2">
                        <label className="col-md-2 font-weight-bold">數目</label>
                        <div className="col-md-10 font-weight-bold">
                            <input className="form-control" value={amount} onChange={(e)=>this.setState({amount:e.target.value})} type="number"/>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <label className="col-md-2 font-weight-bold">支付日期</label>
                        <div className="col-md-10">
                            <DateInput value={payment_date} onChange={(value)=>this.setState({payment_date:value})}/>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <label className="col-md-2 font-weight-bold">付款方式</label>
                        <div className="col-md-10">
                            <SelectInput items={data.paymentMethods} onChange={(id)=>this.setState({payment_method_id:id})} value={payment_method_id}/>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <label className="col-md-2 font-weight-bold">備註</label>
                        <div className="col-md-10">
                            <textarea className="form-control" onChange={(e)=>this.setState({remarks:e.target.value})} value={remarks}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}


export default connect((state)=>{
        return{data: state.cash.data, item:state.cash.item,}},
    {get, getItem, getFormData, submit})(CashItem);
