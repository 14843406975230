import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {connect} from 'react-redux';
import {register} from '../../reducers/core';
class Login extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            email: '',
            password:'',
            nick_name:''
        };
        this.submit = this.submit.bind(this);
    }

    submit(e){
        e.preventDefault();
        this.setState({loading:true});
        let {email, nick_name, password} = this.state;
        this.props.register(email, nick_name, password);
    }
    
    render(){
        const {loading, nick_name, email, password} = this.state;
        return <div style={{position:'absolute', left:0, right:0, top:'30%', bottom:0}}>
            <div className="mx-auto p-3" style={{maxWidth:350, border:'1px solid #aeaeae'}}>
                <form onSubmit={this.submit}>
                    <div>
                        Please Login to see your POS system
                    </div>
                    <div className="form-group">
                        <label>Nick Name</label>
                        <input className="form-control" name="nick_name" onChange={(e)=>this.setState({nick_name:e.target.value})} value={nick_name}/>
                    </div>
                    <div className="form-group">
                        <label>Email Address</label>
                        <input className="form-control" name="email" onChange={(e)=>this.setState({email:e.target.value})} value={email}/>
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input className="form-control" type="password" onChange={(e)=>this.setState({password:e.target.value})} value={password}/>
                    </div>
                    <button className="mt-2 btn btn-dark-border w-100" type="submit">
                        {loading &&
                        <FontAwesomeIcon icon='spinner' fixedWidth={true} spin={true}/>
                        }
                        Register
                    </button>
                </form>
            </div>
        </div>
    }
}

export default connect((state)=>{return {user:state.core.user}}, {register})(Login)