import {get, post} from '../axios';
const GET_FORM_DATA = 'GET_SALE_FORM_DATA';
const GET_ITEMS = 'GET_SALES';
const GET_ITEM = 'GET_SALE_ITEM';
const GET_ITEM_DETAIL = 'GET_SALE_ITEM_DETAIL';
const initialState= {
    data:null,
    list:[],
    item:null,
    return:null
};


export default function (state = initialState, action){
    switch (action.type){
        case GET_ITEMS:
            return Object.assign({}, state, {list: action.data});
        case GET_FORM_DATA:
            return Object.assign({}, state, {data: action.data});
        case GET_ITEM:
            return Object.assign({}, state, {item: action.data});
        case GET_ITEM_DETAIL:
            return Object.assign({}, state, {list: state.list.map((item)=>{
                if(item.order_number === action.order_number){
                    return {...item, data:action.data}
                }else{
                    return item
                }
                })});
        default:
            return state;
    }
}

export function getItems(){
    return async (dispatch)=>{
        let data = await dispatch(get(`/sales`));
        return dispatch({type:GET_ITEMS, data:data})
    }
}

export function getItem(id){
    return async (dispatch)=>{
        let data = await dispatch(get(`/sales/${id}`));
        return dispatch({type:GET_ITEM, data:data})
    }
}

export function getItemDetail(order_number){
    return async (dispatch)=>{
        let data = await dispatch(get(`/sales/${order_number}`));
        return dispatch({type:GET_ITEM_DETAIL, order_number, data})
    }
}

export function getFormData(data){
    return  {type:GET_FORM_DATA, data}
}


export function submit(data, history){
    return async (dispatch)=>{
        try{
            await dispatch(post(`/sales/${data.order_number?data.order_number:''}`, data));
            history.push('/sales')
        }catch (e) {
            console.log(e)
        }
    }
}