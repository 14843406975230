import React, {useEffect, useState} from 'react';
import {get, post} from "../../axios";
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment';
export default function CheckIn(props) {
    const dispatch = useDispatch();
    const [dataUrl, setDataUrl] = useState(null);
    const [now, setNow] = useState(null);
    useEffect(()=>{
        const getData = async()=>{
            const {dataUrl} = await dispatch(get('/management/checkin'));
            setDataUrl(dataUrl);
            setNow(moment());

        };
        getData();
        const i = setInterval(getData,60000)
        return ()=>{
            clearInterval(i);
        }
    },[]);

    if(!now){
        return <div/>
    }

    return <div className="d-flex justify-content-center mt-3">
        <div style={{backgroundColor:'#fff', width:600}} className="p-3 text-center" >
            <h4 className="font-weight-bold">打卡易</h4>
            <div className="font-weight-bold">{now.format('DD/MM/YYYY dddd HH:mm')}</div>
            <div>QR Code 只有效1分鐘</div>
            <img src={dataUrl}/>
            <Record/>
        </div>
    </div>
}

function Record(){
    const dispatch = useDispatch();
    const results = useSelector((state)=>state.core.checkIns)

    const holidayType = (type)=>{
        switch (type) {
            case 1: return '例假';
            case 2: return '病假';
            case 3: return '請假';
            case 4: return '補假';
            case 5: return '年假';
            return ''
        }
    };
   return <div>
       <table className="table table-bordered">
           <thead>
            <tr>
                <th>稱呼</th>
                <th>返工時間</th>
                <th>放工時間</th>
                <th>放假</th>
            </tr>
           </thead>
           <tbody>
           {results.map((user)=>{
               const _start_time = user.start_time ? moment(user.start_time).format('HH:mm DD/MM/YYYY'):null;
               const _end_time = user.end_time ? moment(user.end_time).format('HH:mm DD/MM/YYYY'):null;
               return <tr key={user.id}>
                   <td className="font-weight-bold">{user.nick_name}</td>
                   <td>{_start_time}</td>
                   <td>{_end_time}</td>
                   <td>
                       {holidayType(user.type)}
                   </td>
               </tr>
           })}
           </tbody>
       </table>
   </div>
}