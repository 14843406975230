import React from "react";
import moment from 'moment';
import {connect} from 'react-redux';
import Image from "../common/Image";
import Date from "../common/Date";
class CarReport extends React.Component{
    constructor(props){
        super(props);
    }

    componentDidMount(){
        if(this.props.open){
            let mywindow = window.open('', 'Profit', 'height=800,width=600');
            mywindow.document.write('<html>');
            //adding the current css file in the head
            mywindow.document.write(document.head.innerHTML);
            mywindow.document.write('<body>');
            mywindow.document.write(this.refs.container.innerHTML);
            mywindow.document.write('</body></html>');
            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/
            mywindow.onbeforeunload = this.props.close
        }
    }

    componentDidUpdate(){
        if(this.props.open){
            let mywindow = window.open('', 'Profit', 'height=800,width=600');
            mywindow.document.write('<html>');
            //adding the current css file in the head
            mywindow.document.write(document.head.innerHTML);
            mywindow.document.write('<body>');
            mywindow.document.write(this.refs.container.innerHTML);
            mywindow.document.write('</body></html>');
            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/
            mywindow.onbeforeunload = this.props.close
        }
    }


    render() {
        const {carReport, store} = this.props;
        if(!carReport){
            return <div/>
        }
        let {detail, date} = carReport;
        return (
            <div className="mt-3 d-none">
                <div ref="container" className="container print-block" media="print" style={{color:'#252b75ff', backgroundColor:'#fff'}}>
                    <style>
                        {"@media print{@page {size: portrait}}"}
                    </style>
                    <div className="p-3">
                        <div>
                            <h5 className="font-weight-bold float-left">{store.full_title}</h5>
                            <div className="float-right">{moment().format('YYYY/MM/DD hh:mm')}列印</div>
                            <div className="clearfix"/>
                        </div>
                        <h5 className="font-weight-bold mt-3">{moment(date).format('YYYY年MM月DD日')} 車輛紀錄</h5>
                        {detail.map((item, y)=>{
                            let duration;
                            if(item.left_at){
                                duration = moment.duration(moment(item.left_at).diff(moment(item.entered_at)));
                            }
                            return <div className="row mb-3" key={y} style={{borderBottom:'2px solid #aaa'}}>
                               <div className="col-4">
                                   <Image width={150} height={150} image={item}/>
                               </div>
                                <div className="col-4">
                                    <div className="font-weight-bold">車牌: {item.plate_external? item.plate_external.plate.toUpperCase():'未能偵測'}</div>
                                    <div>入車時間: {moment(item.entered_at).format('HH:mm')}</div>
                                    <div>出車時間: {item.left_at?  moment(item.left_at).format('HH:mm') :'未出車'}</div>
                                    {duration &&
                                        <div>停留時間: {duration.hours()}小時{duration.minutes()}分鐘</div>
                                    }
                                </div>
                                <div className="col-4">
                                    <div className="font-weight-bold">銷售項目</div>
                                    <div>銷售號碼 {item.order_number}</div>
                                    {item.items? item.items.map((s, a)=> <div key={a}>
                                        <div>{s.title} X {s.quantity}</div>
                                    </div>):''}
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        );
    }
}
function Report({detail, month}){
    let {profits, services, expenditures} = detail;
    let turnover = 0;
    let cogs = 0;
    let gp = 0;
    let tyres = 0;
    let u_tyres = 0;
    let batteries = 0;
    let wheels = 0;
    let align = 0;
    profits.forEach((p)=>{
        turnover += p.turnover;
        cogs += +p.cogs;
        gp += p.profit;
        if(p.title ==='tyres'){
            tyres = +p.quantity
        }
        if(p.title ==='wheels'){
            wheels = +p.quantity
        }
        if(p.title ==='battery'){
            batteries = +p.quantity
        }
    });

    services.forEach((p)=>{
        if(p.title ==='二手呔'){
            u_tyres = +p.count
        }
        if(p.title ==='日本車四輪定位'){
            align += +p.count
        }
        if(p.title ==='歐美車四輪定位'){
            align += +p.count
        }
        if(p.title ==='跑車或SUV四輪定位'){
            align += +p.count
        }
    });

    gp -=expenditures[0].total;

    return (
         <table className="table table-bordered">
             <tbody>
             <tr>
                 <td className="font-weight-bold">{month? "總營業額":"今日營業額"}</td>
                 <td className="text-right">{turnover.toLocaleString()}</td>
             </tr>
             <tr>
                 <td className="font-weight-bold">{month? "總成本":"今日成本"}</td>
                 <td className="text-right">{cogs.toLocaleString()}</td>
             </tr>
             <tr>
                 <td className="font-weight-bold">{month? "總支出":"今日支出"}</td>
                 <td className="text-right">{expenditures[0].total? expenditures[0].total.toFixed(2):0}</td>
             </tr>
             <tr>
                 <td className="font-weight-bold">{month? "總毛利":"今日毛利"}</td>
                 <td className="text-right">{gp.toLocaleString()}({(100*gp/turnover).toFixed(2)}%)</td>
             </tr>
             <tr>
                 <td className="font-weight-bold">售出輪胎</td>
                 <td className="text-right">{tyres}條</td>
             </tr>
             <tr>
                 <td className="font-weight-bold">售出二手輪胎</td>
                 <td className="text-right">{u_tyres}條</td>
             </tr>
             <tr>
                 <td className="font-weight-bold">售出電池</td>
                 <td className="text-right">{batteries}個</td>
             </tr>
             <tr>
                 <td className="font-weight-bold">售出合金鈴</td>
                 <td className="text-right">{wheels}個</td>
             </tr>
             <tr>
                 <td className="font-weight-bold">四輪定位</td>
                 <td className="text-right">{align}個</td>
             </tr>
             {month &&
             <tr>
                 <td className="font-weight-bold">累積總件數</td>
                 <td className="text-right">{+align+wheels+batteries+u_tyres+tyres}件</td>
             </tr>
             }
             </tbody>
         </table>
     )
}

export default connect((state)=>({store:state.core.store}))(CarReport);