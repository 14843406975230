import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import { createStore, applyMiddleware  } from 'redux';
import { Provider } from 'react-redux';
import reducer from './reducers';
import App from './router';
import thunk from 'redux-thunk';
import './app.scss'
import instance from 'axios';
const axios = instance.create({
    withCredentials: true,
    baseURL: "https://api2.otopac.com.hk"
});

import {fetchMiddleware} from './axios';
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {faTrash, faCaretDown, faCaretUp, faSearch, faMinus, faPlus, faCamera, faSpinner, faMinusCircle, faArrowCircleLeft, faRunning, faAngleDown, faBars, faCheck, faTimes} from '@fortawesome/free-solid-svg-icons'
library.add(faTrash, faCaretDown, faCaretUp,faSearch, faMinus, faPlus, faCamera, faSpinner, faMinusCircle, faArrowCircleLeft, faRunning, faAngleDown, faBars, faCheck, faTimes);
const middlewares = [thunk, fetchMiddleware(axios)];
if(true) {
    const { createLogger } = require(`redux-logger`);
    middlewares.push(createLogger({
        predicate: (getState, action) => action.type !== 'ADJUST_WINDOW_SIZE'
    }));
}

const store = createStore(
    reducer, applyMiddleware(...middlewares)
);
const render = ()=> ReactDOM.hydrate(
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

render();