import React from 'react';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Payment from '../component/Payment'
import ProductForm from '../component/ProductForm'
import Date from "../common/Date";
import FileUpload from "../common/FileUpload";
import {getFormData, submit, getItem, deleteItem} from "../../reducers/purchase";
import {get, post} from '../../axios'
import * as XLSX from "xlsx";

class PurchaseItem extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            items:[],
            xlsxJson:[],
            payment: {
                invoice_number:'',
                purchaser_id:null,
                invoice_date:null,
                reference_number:'',
                remarks:'',
                supplier_id:null,
            },
            tab:null,
            edit:null,
            error:'',
        };
        this.addItem = this.addItem.bind(this);
        this.editItem = this.editItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.delete = this.delete.bind(this);
        this.submit = this.submit.bind(this);
    }


    componentDidMount(){
        if(this.props.match && this.props.match.params.id){
            this.props.getItem(this.props.match.params.id);
        }
        this.socket= new WebSocket('ws://localhost:8880');
        this.socket.onopen = function() {
            console.log('Connected!');
        };
        this.socket.onclose  = function(s) {
            console.log(s)
        };
        this.socket.onerror = function(s) {
            console.error(s)
        };
        this.getData();
    }

    componentWillReceiveProps(props){
        if(props.item && props.item !== this.props.item){
            this.setState({...props.item})
        }
    }

    async getData(){
        let {getFormData, get} = this.props;
        try{
            /**
            let suppliers = get(`/suppliers`);
            let data = await awaitAll({suppliers});
            getFormData(data)
             **/
        }catch (e) {
            console.error(e)
        }
    }

    delete(){
        let {history} = this.props;
        this.props.deleteItem( this.state, history);
    }

    submit(){
        let {items, payment} = this.state;
        let {purchaser_id, invoice_date, supplier_id} = payment;
        let error = '';
        if(items.length === 0){
            error = '請最少加入一個項目'
        }else if(purchaser_id === null){
            error = '請輸入入貨員'
        }else if(invoice_date === null){
            error = '請輸入購買日期 '
        }else if(supplier_id === null){
            error = '請輸入供應商'
        }else{
            let {history} = this.props;
            this.props.submit( this.state, history);
        }
        this.setState({error:error});
    }

    addItem(item){
        let {items} = this.state;
        items.push(item);
        this.setState({items, tab:null});
    }

    editItem(item){
        let {items, edit} = this.state;
        let _items = items.filter((item)=>!item.is_deleted).map((i,index)=>{
            if(index === edit){
                return item
            }else{
                return i
            }
        });
        this.setState({items : _items, edit:null});
    }

    removeItem(index){
        let {items} = this.state;
        let _items = items.map((item , i)=>{
            if(index === i){
                return {...item, is_deleted:true}
            }else{
                return item
            }
        });
        this.setState({items : _items, edit:null});
    }

    generateQRCode(order_number){
        let {get} = this.props;
        get( `/purchases/${order_number}/qr`).then((list)=>{
            try{
                list.forEach((item)=>{
                    this.socket.send(JSON.stringify(item));
                })
            }catch (e) {
                alert('Unable to detect any printing software')
            }
        });
    }

    handleFileUpload(event){
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const ab = e.target.result;
            const wb = XLSX.read(ab, { type: 'array' });
            const ws = wb.Sheets[wb.SheetNames[0]];
            const json = XLSX.utils.sheet_to_json(ws);
            this.setState({xlsxJson:json});
            console.log(json)
        };

        reader.readAsArrayBuffer(file);
    };



    generateQRCode2(){
        console.log(this.state)
        if(this.state.xlsxJson.length>0) {
            const splitProducts = this.state.xlsxJson.flatMap(product => {
                const quantity = parseInt(product.quantity, 10);
                return Array.from({ length: quantity }, () => {
                    const randomNum = Math.floor(Math.random() * 99999) + 1; // 生成 1-99999 的随机数
                    const combinedCode = `${product.product_code}${product.year}${randomNum}`; // 合并字段
                    const base64Code = btoa(combinedCode); // 使用 btoa 进行 Base64 编码
                    const { quantity, ...rest } = product; // 去掉 quantity
                    return { ...rest, code: base64Code ,product_code:product.product_code.trim(),year:product.year.toString().trim()}; // 添加 code 字段
                });
            });
            splitProducts.forEach((item, index) => {
                // if (index < 2 ) {
                //     console.log({
                //         title1: '',
                //         title2: item.product_code,
                //         title3: `${item.year} ${item.position}`,
                //         data: item
                //     })
                    this.socket.send(JSON.stringify({
                        title1: '',
                        title2: item.product_code.trim(),
                        title3: `${item.year} ${item.position}`,
                        data: item
                    }))
                // }
            })
        }else{
        console.log('no json')
        }
    }

    render() {
        const {items, tab, edit, payment, order_number, created_at, error} = this.state;
        let activeTab = 'btn btn-sm btn-primary mr-2';
        let inactiveTab = 'btn btn-sm btn-outline-primary mr-2';
        return <div style={{position:'relative', height:'100%'}}>
            <div style={{position:'absolute', left:0, top:0, bottom:0, right:0}}>
                <div className="mt-3">
                    <button className="btn btn-primary btn-sm" onClick={this.submit}>
                        儲存
                    </button>
                    {error &&
                        <span className="ml-2 text-danger">{error}</span>
                    }
                    <button className="btn btn-success btn-sm ml-2" onClick={()=>this.generateQRCode(order_number)}>
                        下載QR Code
                    </button>
                    <input type="file" accept=".xlsx, .xls" onChange={(e)=>this.handleFileUpload(e)} />
                    <button className="btn btn-success btn-sm ml-2" onClick={()=>this.generateQRCode2()}>
                        下載QQQ
                    </button>
                    {order_number &&
                    <button className="btn btn-danger btn-sm ml-2" onClick={this.delete}>
                        取消入貨
                    </button>
                    }
                </div>
                <div className="p-3 mt-3" style={{background:'#fff'}}>
                    <div className="row">
                        <label className="col-md-2 font-weight-bold">入貨編號</label>
                        <div className="col-md-10 font-weight-bold">
                            {order_number}
                            <img ref="image"/>
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-md-2 font-weight-bold">建立日期</label>
                        <div className="col-md-10 font-weight-bold">
                            <Date date={created_at} time={true}/>
                        </div>
                    </div>
                    <h6 className="font-weight-bold">銷售項目</h6>
                    <table className="table">
                        <thead>
                        <tr>
                            <th>追蹤號碼</th>
                            <th>產品</th>
                            <th>備註</th>
                            <th>數量</th>
                            <th>價格</th>
                            <th>小計</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {items.map((item, index)=><tr key={index} className={item.is_deleted?"d-none":""}>
                            <td>{item.tracking_id}</td>
                            <td>{item.title}</td>
                            <td>{item.remarks}</td>
                            <td>{item.quantity}</td>
                            <td>{item.price}</td>
                            <td>{+item.price * +item.quantity}</td>
                            <td><button className="btn btn-sm btn-info" onClick={()=>this.setState({edit:index, tab:null})}>修改</button> <button className="btn btn-sm btn-danger" onClick={()=>this.removeItem(index)}>刪除</button></td>
                        </tr>)}
                        </tbody>
                    </table>
                    <h6>加入項目</h6>
                    <button className={tab===1? activeTab:inactiveTab} onClick={()=>this.setState({tab:1, edit:null})}>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                        輪胎
                    </button>
                    <button className={tab===2? activeTab:inactiveTab} onClick={()=>this.setState({tab:2, edit:null})}>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                        電池
                    </button>
                    <button className={tab===3? activeTab:inactiveTab} onClick={()=>this.setState({tab:3, edit:null})}>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                        車鈴
                    </button>
                    <button className={tab===4? activeTab:inactiveTab} onClick={()=>this.setState({tab:4, edit:null})}>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                        汽車用品
                    </button>
                    <button className={tab===4? activeTab:inactiveTab} onClick={()=>this.setState({tab:5, edit:null})}>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                        二手輪胎
                    </button>
                    {edit !== null && <ProductForm edit={edit} addItem={this.editItem} items={items}/>}
                    {tab !== null && <ProductForm tab={tab} addItem={this.addItem} items={items}/>}
                </div>
                <Payment onChange={(values)=>this.setState({payment: {...payment, ...values}})} values={payment}/>
                {order_number &&
                <Files order_number={order_number}/>
                }
            </div>
        </div>
    }
}

class Files extends React.Component{
     constructor(props){
         super(props);
         this.state = {
             items:[]
         };
         this.getData = this.getData.bind(this);
         this.uploaded = this.uploaded.bind(this);
     }

    componentDidMount(){
         this.getData();
    }

    async getData(){
         try{
             let {get, order_number} = this.props;
             let items = await get(`/purchases/${order_number}/files`);
             this.setState({items})
         }catch (e) {
             console.error(e)
         }
    }

     async uploaded(items){
         let {post, order_number} = this.props;
         try{
             let _items = this.state.items.concat(items);
             post(`/purchases/${order_number}/files`, _items);
             this.setState({items:_items})
         }catch (e) {
             console.error(e)
         }
     }

     openFile(url){
         let mywindow = window.open(url, 'FILE', 'height=800,width=600');
         mywindow.focus(); // necessary for IE >= 10
     }

     render(){
         const style = {
             border:'1px solid #000',
             paddingLeft:'5px'
         };
         const {items} = this.state;
         return <div className="p-3 mt-3" style={{background:'#fff'}}>
             <div className="font-weight-bold">檔案</div>
             <FileUpload uploaded={this.uploaded}/>
             <div className="row no-gutters">
                 <div className="col-1 font-weight-bold" style={style}>ID</div>
                 <div className="col-4 font-weight-bold" style={style}>上載者</div>
                 <div className="col-4 font-weight-bold" style={style}>上載日期</div>
                 <div className="col-3 font-weight-bold" style={style}></div>

                 {items.map((item)=>{return <React.Fragment key={item.id}>
                     <div className="col-1" style={style}>{item.id}</div>
                     <div className="col-4" style={style}>{item.nick_name}</div>
                     <div className="col-4" style={style}><Date date={item.created_at} time={true}/></div>
                     <div className="col-3" style={style}><button className="btn btn-sm btn-primary" onClick={()=>this.openFile(`https://api2.otopac.com.hk/files/${item.path}`)}>打開</button></div>
                 </React.Fragment>})}
             </div>
         </div>
     }
}

Files = connect(()=>{return {}}, {get, post} )(Files);



export default connect((state)=>{return{data: state.purchase.data, item:state.purchase.item,}}, {get, getItem, getFormData, submit, deleteItem})(PurchaseItem);