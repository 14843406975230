import React, {useEffect, useMemo, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Table from '../common/Table';
import Date from "../common/Date";
import {get} from "../../axios";
import {getProductList} from "../../reducers/product";


const Inventory = ({list,getProductList}) => {
    const [tab, setTab] = useState('tyres');
    const [showAll, setShowAll] = useState(true);


    useEffect(() => {
            getProductList(tab,showAll,tab === 'usedTyres')
    }, [tab, showAll])

    const showAllHandler = () => {
        setShowAll(!showAll)
    }

    return <div>
        <div>
            <Link to='/inventory/tyres/item' className='btn btn-primary btn-sm'>
                <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                新輪胎
            </Link>
            <Link to='/inventory/batteries/item' className='btn btn-primary btn-sm ml-2'>
                <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                新電池
            </Link>
            <Link to='/inventory/wheels/item' className='btn btn-primary btn-sm ml-2'>
                <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                新車鈴
            </Link>
            <Link to='/inventory/products/item' className='btn btn-primary btn-sm ml-2'>
                <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                新零件
            </Link>
            <Link to='/inventory/services/item' className='btn btn-primary btn-sm ml-2'>
                <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                新服務
            </Link>
        </div>
        <div className="mt-3 custom-tab-body2">
            <Tabs title={'輪胎'} code={'tyres'} tab={tab} setTab={setTab}/>
            <Tabs title={'電池'} code={'batteries'} tab={tab} setTab={setTab}/>
            <Tabs title={'車鈴'} code={'wheels'} tab={tab} setTab={setTab}/>
            <Tabs title={'零件'} code={'products'} tab={tab} setTab={setTab}/>
            <Tabs title={'服務'} code={'services'} tab={tab} setTab={setTab}/>
            <Tabs title={'二手輪胎'} code={'usedTyres'} tab={tab} setTab={setTab}/>
        </div>
        <div className={'mt-2'}>
            <button className="btn btn-sm btn-secondary" onClick={showAllHandler}>{showAll ? '顯示有貨' : '顯示全部'}</button>
        </div>
        <div>
            {list[tab]&&tab === 'tyres' && <Tables list={list} tab={tab}/>}
            {list[tab]&&tab === 'batteries' && <Tables list={list} tab={tab}/>}
            {list[tab]&&tab === 'wheels' && <Tables list={list} tab={tab}/>}
            {list[tab]&&tab === 'products' && <Tables list={list} tab={tab}/>}
            {list[tab]&&tab === 'services' && <Tables list={list} tab={tab}/>}
            {list[tab]&&tab === 'usedTyres' && <Tables list={list} tab={tab}/>}
        </div>
    </div>
}

const Tabs = ({code, title, tab, setTab}) => {
    const tabClass = "custom-tab";
    const aTabClass = "custom-tab active";
    return <div className={tab === code ? aTabClass : tabClass} onClick={() => setTab(code)}>
        {title}
    </div>
}

const Tables = ({list, tab}) => {
    const accessors = {
        tyres: [
            {key: 'product_code', title: '產品號碼'},
            {key: 'brand', title: '品牌'},
            {
                key: 'title', title: '名稱', render: ((item) => <div>
                    <div className="font-weight-bold">{item.title}</div>
                    <div>{item.items ? item.items.map((i, a) => <button className="btn btn-sm mr-1"
                                                                        type="button"
                                                                        key={a}>{i.remarks} x {i.quantity}</button>) : ''}</div>
                </div>)
            },
            {key: 'quantity', title: '數量'},
            {key: 'price', title: '定價'},
            {
                key: 'created_at',
                title: '建立日期',
                render: ((item) => <Date date={item.created_at} time={true}/>)
            },
            {
                key: 'id',
                title: '修改',
                render: ((item) => <Link to={`/inventory/${tab}/${item.product_id}`}>修改</Link>)
            }
        ],
        batteries: [
            {key: 'product_code', title: '產品編號'},
            {key: 'brand', title: '品牌'},
            {key: 'title', title: '名稱'},
            {key: 'quantity', title: '數量'},
            {key: 'price', title: '定價'},
            {key: 'created_at', title: '建立日期', render: ((item) => <Date date={item.created_at} time={true}/>)},
            {key: 'id', title: '修改', render: ((item) => <Link to={`/inventory/${tab}/${item.id}`}>修改</Link>)}
        ],
        wheels: [
            {key: 'product_code', title: '產品編號'},
            {key: 'brand', title: '品牌'},
            {key: 'model', title: '款式'},
            {key: 'title', title: '名稱'},
            {key: 'quantity', title: '數量'},
            {key: 'price', title: '定價'},
            {key: 'created_at', title: '建立日期', render: ((item) => <Date date={item.created_at} time={true}/>)},
            {key: 'id', title: '修改', render: ((item) => <Link to={`/inventory/${tab}/${item.id}`}>修改</Link>)}
        ],
        products: [
            {key: 'product_code', title: '產品編號'},
            {key: 'title', title: '名稱'},
            {key: 'quantity', title: '數量'},
            {key: 'price', title: '定價'},
            {key: 'created_at', title: '建立日期', render: ((item) => <Date date={item.created_at} time={true}/>)},
            {key: 'id', title: '修改', render: ((item) => <Link to={`/inventory/${tab}/${item.id}`}>修改</Link>)}
        ],
        services: [
            {key: 'product_code', title: '產品編號'},
            {key: 'title', title: '名稱'},
            {key: 'price', title: '定價'},
            {key: 'created_at', title: '建立日期', render: ((item) => <Date date={item.created_at} time={true}/>)},
            {key: 'id', title: '修改', render: ((item) => <Link to={`/inventory/${tab}/${item.id}`}>修改</Link>)}
        ],
        usedTyres: [
            {key: 'product_code', title: '產品號碼'},
            {key: 'brand', title: '品牌'},
            {
                key: 'title', title: '名稱', render: ((item) => <div>
                    <div className="font-weight-bold">{item.title}</div>
                    <div>{item.items ? item.items.map((i, a) => <button className="btn btn-sm mr-1" type="button"
                                                                        key={a}>{i.remarks} x {i.quantity}</button>) : ''}</div>
                </div>)
            },
            {key: 'store', title: '店鋪'},
            {key: 'quantity', title: '數量'},
            {key: 'price', title: '定價'},
            {key: 'created_at', title: '建立日期', render: ((item) => <Date date={item.created_at} time={true}/>)},
            {
                key: 'id',
                title: '修改',
                render: ((item) => item.current_store ?
                    <Link to={`/inventory/tyres/${item.product_id}`}>修改</Link> : null)
            }
        ],
    }
    return <Table data={list[tab]}
                  accessors={accessors[tab]}
                  tableKey={tab}
                  rowKey='id'
                  search={(item, text) => item.title.toUpperCase().includes(text.toUpperCase())}
    />
}

// class Inventory extends React.Component{
//     constructor(props) {
//         super(props);
//         this.state = {
//             tab:1,
//             showAll:false
//         }
//     }
//
//     componentDidMount(){
//         const {showAll} = this.state;
//         this.props.getProduct('tyres', showAll);
//     }
//
//     switchTab(tab){
//         const {showAll} = this.state;
//         if(tab === 1) {
//             this.props.getProduct('tyres', showAll);
//         }else if(tab ===2){
//             this.props.getProduct('batteries', showAll);
//         }else if(tab ===3){
//             this.props.getProduct('wheels', showAll);
//         }else if(tab ===4){
//             this.props.getProduct('products',showAll);
//         }else if(tab ===5){
//             this.props.getProduct('services',showAll);
//         }else if(tab ===6){
//             this.props.getProduct('usedTyres', showAll, true);
//         }
//         this.setState({tab})
//     }
//
//     switchAll(showAll){
//         this.setState({showAll:showAll});
//         const {tab} = this.state;
//         this.switchTab(tab);
//     }
//
//     render() {
//         const {list} = this.props;
//         const {tab, showAll} = this.state;
//         const tabClass = "custom-tab";
//         const aTabClass = "custom-tab active";
//         return (
//             <div className="mt-3">
//                 <div>
//                     <Link to='/inventory/tyres/item' className='btn btn-primary btn-sm'>
//                         <FontAwesomeIcon icon='plus' fixedWidth={true}/>
//                         新輪胎
//                     </Link>
//                     <Link to='/inventory/batteries/item' className='btn btn-primary btn-sm ml-2'>
//                         <FontAwesomeIcon icon='plus' fixedWidth={true}/>
//                         新電池
//                     </Link>
//                     <Link to='/inventory/wheels/item' className='btn btn-primary btn-sm ml-2'>
//                         <FontAwesomeIcon icon='plus' fixedWidth={true}/>
//                         新車鈴
//                     </Link>
//                     <Link to='/inventory/products/item' className='btn btn-primary btn-sm ml-2'>
//                         <FontAwesomeIcon icon='plus' fixedWidth={true}/>
//                         新零件
//                     </Link>
//                     <Link to='/inventory/services/item' className='btn btn-primary btn-sm ml-2'>
//                         <FontAwesomeIcon icon='plus' fixedWidth={true}/>
//                         新服務
//                     </Link>
//                 </div>
//                 <div className="mt-3">
//                     <div className={tab === 1 ? aTabClass: tabClass} onClick={()=>this.switchTab(1)}>
//                         輪胎
//                     </div>
//                     <div className={tab === 2 ? aTabClass: tabClass}  onClick={()=>this.switchTab(2)}>
//                         電池
//                     </div>
//                     <div className={tab === 6 ? aTabClass: tabClass} onClick={()=>this.switchTab(6)}>
//                         二手輪胎
//                     </div>
//                     <div className={tab === 3 ? aTabClass: tabClass}  onClick={()=>this.switchTab(3)}>
//                         車鈴
//                     </div>
//                     <div className={tab === 4 ? aTabClass: tabClass}  onClick={()=>this.switchTab(4)}>
//                         零件
//                     </div>
//                     <div className={tab === 5 ? aTabClass: tabClass}  onClick={()=>this.switchTab(5)}>
//                         服務
//                     </div>
//                 </div>
//                 {list['tyres'] && tab ===1 &&
//                 <div className="custom-tab-body py-2">
//                     <div>
//                         {!showAll?(
//                             <button className="btn btn-sm btn-secondary" onClick={()=>this.switchAll(true)}>顯示全部</button>
//                         ):(
//                             <button className="btn btn-sm btn-secondary" onClick={()=>this.switchAll(false)}>顯示有貨</button>
//                         )}
//                     </div>
//                     <Table data={list['tyres']}
//                            accessors={[
//                                {key:'product_code', title:'產品號碼'},
//                                {key:'brand', title:'品牌'},
//                                {key:'title', title:'名稱', render:((item) => <div>
//                                        <div className="font-weight-bold">{item.title}</div>
//                                        <div>{item.items? item.items.map((i, a)=><button className="btn btn-sm mr-1" type="button" key={a}>{i.remarks} x {i.quantity}</button>):''}</div>
//                                    </div>)},
//                                {key:'quantity', title:'數量'},
//                                {key:'price', title:'定價'},
//                                {key:'created_at', title:'建立日期', render:((item) => <Date date={item.created_at} time={true}/>)},
//                                {key:'id', title:'修改', render:((item) => <Link to={`/inventory/tyres/${item.product_id}`}>修改</Link>)}
//                            ]}
//                            tableKey='tyres'
//                            rowKey='id'
//                            search={(item, text)=>item.title.toUpperCase().includes(text.toUpperCase())}
//                     />
//                 </div>
//                 }
//                 {list['batteries'] && tab ===2 &&
//                 <div className="custom-tab-body py-2">
//                     <Table data={list['batteries']}
//                            accessors={[
//                                {key:'product_code', title:'產品編號'},
//                                {key:'brand', title:'品牌'},
//                                {key:'title', title:'名稱'},
//                                {key:'quantity', title:'數量'},
//                                {key:'price', title:'定價'},
//                                {key:'created_at', title:'建立日期', render:((item) => <Date date={item.created_at} time={true}/>)},
//                                {key:'id', title:'修改', render:((item) => <Link to={`/inventory/batteries/${item.id}`}>修改</Link>)}
//                            ]}
//                            tableKey='batteries'
//                            rowKey='id'
//                            search={(item, text)=>item.title.toUpperCase().includes(text.toUpperCase())}
//                     />
//                 </div>
//                 }
//                 {list['wheels'] && tab ===3 &&
//                 <div className="custom-tab-body py-2">
//                     <Table data={list['wheels']}
//                            accessors={[
//                                {key:'product_code', title:'產品編號'},
//                                {key:'brand', title:'品牌'},
//                                {key:'model', title:'款式'},
//                                {key:'title', title:'名稱'},
//                                {key:'quantity', title:'數量'},
//                                {key:'price', title:'定價'},
//                                {key:'created_at', title:'建立日期', render:((item) => <Date date={item.created_at} time={true}/>)},
//                                {key:'id', title:'修改', render:((item) => <Link to={`/inventory/wheels/${item.id}`}>修改</Link>)}
//                            ]}
//                            tableKey='wheels'
//                            rowKey='id'
//                            search={(item, text)=>item.title.toUpperCase().includes(text.toUpperCase())}
//                     />
//                 </div>
//                 }
//                 {list['products'] && tab ===4 &&
//                 <div className="custom-tab-body py-2">
//                     <Table data={list['products']}
//                            accessors={[
//                                {key:'product_code', title:'產品編號'},
//                                {key:'title', title:'名稱'},
//                                {key:'quantity', title:'數量'},
//                                {key:'price', title:'定價'},
//                                {key:'created_at', title:'建立日期', render:((item) => <Date date={item.created_at} time={true}/>)},
//                                {key:'id', title:'修改', render:((item) => <Link to={`/inventory/products/${item.id}`}>修改</Link>)}
//                            ]}
//                            tableKey='products'
//                            rowKey='id'
//                            search={(item, text)=>item.title.toUpperCase().includes(text.toUpperCase())}
//                     />
//                 </div>
//                 }
//                 {list['services'] && tab ===5 &&
//                 <div className="custom-tab-body py-2">
//                     <Table data={list['services']}
//                            accessors={[
//                                {key:'product_code', title:'產品編號'},
//                                {key:'title', title:'名稱'},
//                                {key:'price', title:'定價'},
//                                {key:'created_at', title:'建立日期', render:((item) => <Date date={item.created_at} time={true}/>)},
//                                {key:'id', title:'修改', render:((item) => <Link to={`/inventory/services/${item.id}`}>修改</Link>)}
//                            ]}
//                            tableKey='services'
//                            rowKey='id'
//                            search={(item, text)=>item.title.toUpperCase().includes(text.toUpperCase())}
//                     />
//                 </div>
//                 }
//                 {list['usedTyres'] && tab ===6 &&
//                 <div className="custom-tab-body py-2">
//                     <div>
//                         {!showAll?(
//                             <button className="btn btn-sm btn-secondary" onClick={()=>this.switchAll(true)}>顯示所有</button>
//                         ):(
//                             <button className="btn btn-sm btn-secondary" onClick={()=>this.switchAll(false)}>顯示有貨</button>
//                         )}
//                     </div>
//                     <Table data={list['usedTyres']}
//                            accessors={[
//                                {key:'product_code', title:'產品號碼'},
//                                {key:'brand', title:'品牌'},
//                                {key:'title', title:'名稱', render:((item) => <div>
//                                        <div className="font-weight-bold">{item.title}</div>
//                                        <div>{item.items? item.items.map((i, a)=><button className="btn btn-sm mr-1" type="button" key={a}>{i.remarks} x {i.quantity}</button>):''}</div>
//                                    </div>)},
//                                {key:'store', title:'店鋪'},
//                                {key:'quantity', title:'數量'},
//                                {key:'price', title:'定價'},
//                                {key:'created_at', title:'建立日期', render:((item) => <Date date={item.created_at} time={true}/>)},
//                                {key:'id', title:'修改', render:((item) =>item.current_store? <Link to={`/inventory/tyres/${item.product_id}`}>修改</Link>:null)}
//                            ]}
//                            tableKey='usedTyres'
//                            rowKey='id'
//                            search={(item, text)=>item.title.toUpperCase().includes(text.toUpperCase())}
//                     />
//                 </div>
//                 }
//             </div>
//         );
//     }
// }


export default connect((state=>{return {list: state.product.list}}), {getProductList})(Inventory)
