import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {get} from '../../axios';
import {connect} from 'react-redux';
class OtherItemInput extends React.Component{
    constructor(props){
        super(props);
        this.state  = {
            title:"",
            price:"",
            quantity:"",
            submitError:'',
        };
        this.submit = this.submit.bind(this);
    }

    submit(e){
        e.preventDefault();
        let {quantity, price, title} = this.state;
        let submitError = '';
        if(!title || !quantity || !price){
            submitError = '請輸入所有資料';
            this.setState({submitError})
        }else{
            let {onChange} = this.props;
            onChange({quantity, price, title})
        }
    }


    render(){
        const {title, price, quantity, submitError} = this.state;
        return <div>
            <form className="form" onSubmit={this.submit}>
                <div className="row">
                    <label className="col-md-2 font-weight-bold">項目名稱</label>
                    <div className="col-md-10 font-weight-bold form-inline">
                        <input className="form-control" value={title} onChange={(e)=>this.setState({title:e.target.value})}/>
                    </div>
                </div>
                <div className="row mt-2">
                    <label className="col-md-2 font-weight-bold">價格</label>
                    <div className="col-md-10 font-weight-bold form-inline">
                        <input className="form-control" value={price} type="number"  onChange={(e)=>this.setState({price:e.target.value})}/>
                    </div>
                </div>
                <div className="row mt-2">
                    <label className="col-md-2 font-weight-bold">數量</label>
                    <div className="col-md-10 font-weight-bold form-inline">
                        <input className="form-control" value={quantity} type="number" onChange={(e)=>this.setState({quantity:e.target.value})}/>
                        <button className="btn btn-warning btn-sm ml-2">
                            <FontAwesomeIcon icon='search' fixedWidth={true}/>
                            加入
                        </button>
                        <span className="text-danger ml-2">{submitError}</span>
                    </div>
                </div>
            </form>
        </div>
    }
}

export default connect((state)=>{return{}},{get})(OtherItemInput)