import React, {useMemo} from "react";
import moment from 'moment';
import {connect} from 'react-redux';
const dd = [
    {id:1, title:'例假'},
    {id:2, title:'病假'},
    {id:3, title:'事假'},
    {id:4, title:'補假'},
    {id:5, title:'年假'},
];

class Profit extends React.Component{
    constructor(props){
        super(props);
    }

    componentDidMount(){
        if(this.props.open){
            let mywindow = window.open('', 'Profit', 'height=800,width=600');
            mywindow.document.write('<html>');
            //adding the current css file in the head
            mywindow.document.write(document.head.innerHTML);
            mywindow.document.write('<body>');
            mywindow.document.write(this.refs.container.innerHTML);
            mywindow.document.write('</body></html>');
            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/
            mywindow.onbeforeunload = this.props.close
        }
    }

    componentDidUpdate(){
        if(this.props.open){
            let mywindow = window.open('', 'Profit', 'height=800,width=600');
            mywindow.document.write('<html>');
            //adding the current css file in the head
            mywindow.document.write(document.head.innerHTML);
            mywindow.document.write('<body>');
            mywindow.document.write(this.refs.container.innerHTML);
            mywindow.document.write('</body></html>');
            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/
            mywindow.onbeforeunload = this.props.close
        }
    }

    render() {
        const {profitReport, store} = this.props;
        if(!profitReport){
            return <div/>
        }
        let {detail, date, month,deposit_day,deposit_month,carService_day,carService_month, workShiftsDay, workShiftsMonth, onDuties} = profitReport;
        return (
            <div className="mt-3 d-none">
                <div ref="container" className="container print-block" media="print" style={{color:'#252b75ff', backgroundColor:'#fff'}}>
                    <div className="p-3">
                        <div>
                            <h5 className="font-weight-bold float-left">{store.full_title}</h5>
                            <div className="float-right">{moment().format('YYYY/MM/DD hh:mm')}列印</div>
                            <div className="clearfix"/>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <h5 className="font-weight-bold mt-3">{date.format('YYYY年MM月DD日')} {date.format('dddd')} 營業資料</h5>
                                <Report detail={detail} deposit={deposit_day} carService={carService_day}/>
                            </div>
                            <div className="col-6">
                                <h5 className="font-weight-bold mt-3">{date.format('MM月01日')}至{date.format('MM月DD日')} 累積營業資料</h5>
                                <Report detail={month} month={true} deposit={deposit_month} carService={carService_month}/>
                            </div>
                        </div>
                        <Holiday workShiftsDay={workShiftsDay} workShiftsMonth={workShiftsMonth} onDuties={onDuties}/>
                        {profitReport.remarks.filter(({remarks})=> remarks !== '').map((remark)=>{
                           return <div className="row no-gutters" key={remark.id}>
                               <div className="col-2 font-weight-bold" style={{border:'1px solid #aaa'}}>
                                   <span className="ml-1">{moment(remark.report_date).format('MM月DD日')}</span>
                               </div>
                                <div className="col-10" style={{border:'1px solid #aaa'}}>
                                    <span className="ml-1">{remark.remarks}</span>
                                </div>
                           </div>
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

function Holiday({workShiftsDay, workShiftsMonth, onDuties}){
    let duties;
    let temp = {};
    onDuties.forEach((d)=>{
        if(!temp[d.checkin_store_id]){
            temp[d.checkin_store_id] = {workers:[], title:d.checkin_store}
        }
        temp[d.checkin_store_id].workers.push(d)
    });
    duties = Object.keys(temp).map((k)=>temp[k]);
    return  <div className="row mb-2">
        <div className="col-6">
            <div style={{border:'2px solid #000'}} className="p-2">
                <div className="pt-2">
                    <span className="mr-2">當日上班紀錄：</span>
                    {duties.map((c)=><div key={c.title}>
                        <span className="font-weight-bold mr-3">{c.title}:</span>
                        {c.workers.map((w)=><span className="mr-3" key={w.id}>{w.nick_name}</span>)}
                    </div>)}
                    <span className="mr-2">當日缺勤紀錄：</span>
                    {workShiftsDay.map((ws, i )=>{
                        const item = dd.find(({id})=>id===ws.type);
                        return <span key={ws.id} className="font-weight-bold mr-3">{i>0?'/':''} {ws.nick_name} {item.title}</span>
                    })}
                </div>
            </div>
        </div>
        <div className="col-6">

        </div>
    </div>
}

function Report({detail, month,deposit=0,carService=[]}){
    let {turnover, profits, services, expenditures, job_count, job_report, coupon_report, referral_report} = detail;
    let cogs = 0;
    let gp = 0;
    let count1 = 0;
    let count2 = 0;
    profits.forEach((p)=>{
        cogs += +p.cogs;
    });
    job_count.forEach((j)=>{
        count1 += +j.job_count;
        count2 += +j.double_job-j.job_count
    });
    gp = turnover - cogs;
    gp -=expenditures[0].total;
    return (
         <><table className="table table-bordered border-light">
             <tbody>
             <tr>
                 <td className="font-weight-bold">{month? "總營業額":"今日營業額"}</td>
                 <td className="text-right">{turnover.toLocaleString()}</td>
             </tr>
             <tr>
                 <td className="font-weight-bold">{month? "總成本":"今日成本"}</td>
                 <td className="text-right">{cogs.toLocaleString()}</td>
             </tr>
             <tr>
                 <td className="font-weight-bold">{month? "總支出":"今日支出"}</td>
                 <td className="text-right">{expenditures[0].total? expenditures[0].total.toFixed(2):0}</td>
             </tr>
             <tr>
                 <td className="font-weight-bold">{month? "總毛利":"今日毛利"}</td>
                 <td className="text-right">{gp.toLocaleString()}({(100*gp/turnover).toFixed(2)}%)</td>
             </tr>
             </tbody>
         </table>
             <table className="table table-bordered mt-3 border-light"
                    style={{border:'2px solid #000'}}>
                 <thead>
                    <tr>
                        <th style={{borderBottom:'2px solid #000'}}></th>
                        <th style={{borderBottom:'2px solid #000'}}></th>
                        <th style={{borderLeft:'2px solid #000', borderBottom:'2px solid #000'}}>{month? "累計次數":"今日次數"}</th>
                        <th style={{borderLeft:'2px solid #000', borderBottom:'2px solid #000'}}>{month? "累計件數":"今日件數"}</th>
                        <th style={{borderLeft:'2px solid #000', borderBottom:'2px solid #000'}}>{month? "累計雙倍":"雙倍"}</th>
                    </tr>
                 </thead>
                 <tbody>
                 {job_count.map((j, index)=><tr key={j.id}>
                     <td className="font-weight-bold p-1">{j.id}</td>
                     <td className="font-weight-bold p-1">{j.name}</td>
                     <td className="font-weight-bold p-1" style={{borderLeft:'2px solid #000'}}>{j.count}</td>
                     <td className="font-weight-bold p-1" style={{borderLeft:'2px solid #000'}}>{j.job_count}</td>
                     <td className="font-weight-bold p-1" style={{borderLeft:'2px solid #000'}}>{+j.double_job-j.job_count}</td>
                 </tr>)}
                 {carService.length>0 && carService.map((j, index)=><tr key={j.title}>
                     <td className="font-weight-bold p-1">*</td>
                     <td className="font-weight-bold p-1">{j.title}</td>
                     <td className="font-weight-bold p-1" style={{borderLeft:'2px solid #000'}}>{j.count}</td>
                     <td className="font-weight-bold p-1" style={{borderLeft:'2px solid #000'}}>0</td>
                     <td className="font-weight-bold p-1" style={{borderLeft:'2px solid #000'}}>0</td>
                 </tr>)}
                 {deposit>0&&
                 <tr>
                     <td className="font-weight-bold p-1">*</td>
                     <td className="font-weight-bold p-1">訂金</td>
                     <td className="font-weight-bold p-1" style={{borderLeft:'2px solid #000'}}>{deposit}</td>
                     <td className="font-weight-bold p-1" style={{borderLeft:'2px solid #000'}}>0</td>
                     <td className="font-weight-bold p-1" style={{borderLeft:'2px solid #000'}}>0</td>
                 </tr>}
                 <tr>
                     <td className="font-weight-bold p-1" style={{borderTop:'2px solid #000'}}></td>
                     <td className="font-weight-bold p-1" style={{borderTop:'2px solid #000'}}>Total</td>
                     <td className="font-weight-bold p-1" style={{borderLeft:'2px solid #000', borderTop:'2px solid #000'}}/>
                     <td className="font-weight-bold p-1" style={{borderLeft:'2px solid #000', borderTop:'2px solid #000'}}>{count1}</td>
                     <td className="font-weight-bold p-1" style={{borderLeft:'2px solid #000', borderTop:'2px solid #000'}}>{count2}</td>
                 </tr>
                 </tbody>
             </table>
             <table className="table table-bordered mt-3"
                    style={{border:'2px solid #000'}}>
                 <thead>
                 <tr>
                     <th style={{borderBottom:'2px solid #000'}}>出勤紀錄</th>
                     <th style={{borderBottom:'2px solid #000'}}>
                         {job_report.map((j)=>`${j.nick_name} ${month? "累計":""}${j.job_count}次`).join(' , ')}
                     </th>
                 </tr>
                 <tr>
                     <th style={{borderBottom:'2px solid #000'}}>優惠券</th>
                     <th style={{borderBottom:'2px solid #000'}}>
                         {coupon_report.map((j)=>`${month? "累計":""}送出${j.send_discount_code}張 使用${j.discount_code}張`).join(' , ')}
                     </th>
                 </tr>
                 </thead>
             </table>
             <table className="table table-bordered mt-3 冢吁" style={{border:'2px solid #000'}}>
                 <thead>
                    <tr>
                        <th style={{borderBottom:'2px solid #000'}}>轉介紀錄</th>
                        <th style={{borderBottom:'2px solid #000'}}>{referral_report.filter(({referral_type})=>referral_type===2||referral_type===3).map((r)=>`${r.referral_type===2?'車房':'拖車'} ${r.referral_count}次`).join(' / ')}</th>
                    </tr>
                 </thead>
             </table>
             </>
     )
}

export default connect((state)=>({store:state.core.store}))(Profit);