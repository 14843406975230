import React, {Fragment} from 'react';
import DateInput from '../../common/DateInput'
import {connect} from 'react-redux';
import {get} from "../../../axios";
import moment from 'moment';
import Table from "../../common/Table";
class Performance extends React.Component{
    constructor(props){
        super(props);
        this.state = {data:[], detail:null, start_date:moment().startOf('month').toDate(), end_date:moment().toDate()};
        this.getData = this.getData.bind(this);
    }

    componentDidMount(){
        this.getData();
    }

    async getData(e){
        if(e) e.preventDefault();
        let {start_date, end_date} = this.state;
        let data = await this.props.get('/report/performance',  {start_date, end_date});
        this.setState({data})
    }


    render(){
        let {data, start_date, end_date} = this.state;
        data = data.map((item, index)=>({...item, index}));
        return <div className="p-3">
            <h5 className="font-weight-bold">員工表現</h5>
            <form onSubmit={this.getData}>
                <h6 className="d-flex mt-3">
                    <span className="mr-2">
                        開始日期
                    </span>
                    <DateInput value={start_date}  onChange={(v)=>this.setState({start_date:v})}/>
                    <span className="mx-2">-</span>
                    <span className="mx-2">
                        最後日期
                    </span>
                    <DateInput value={end_date} endTime={true} onChange={(v)=>this.setState({end_date:v})}/>
                    <button className="mx-2 btn btn-sm btn-primary">確定</button>
                </h6>
            </form>
            <Table data={data}
                   accessors={[
                       {key:'title', title:'產品'},
                       {key:'price', title:'定價'},
                       {key:'net_price', title:'售價'},
                       {key:'quantity', title:'數量'},
                       {key:'price', title:'價格表現', render:((item) => Number.parseInt(item.net_price - item.price).toLocaleString())},
                   ]}
                   tableKey='report/performance'
                   rowKey='index'
            />
        </div>
    }
}

export default connect((state)=>{return{}}, {get})(Performance)