import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from 'react-redux';
import {getItems} from '../../reducers/purchase'
import {getItems as getReturns} from '../../reducers/returns'
import Date from "../common/Date"
import Table from "../common/Table";
class Purchase extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            tab:1
        };
        this.switchTab = this.switchTab.bind(this);
    }

    componentDidMount(){
        this.props.getItems();
    }

    switchTab(tab){
        this.setState({tab});
        switch (tab) {
            case 1:
                this.props.getItems();
                break;
            case 2:
                this.props.getReturns();
                break;
            case 3:
                this.props.getItems(true);
                break;
        }
    }

    render() {
        const {list, returns} = this.props;
        const {tab} = this.state;
        const tabClass = "custom-tab";
        const aTabClass = "custom-tab active";
        return (
            <div>
                <div className="mt-3">
                    <Link to='/purchase/item' className='btn btn-primary btn-sm'>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                        入貨單
                    </Link>
                    <Link to='/purchase/return/item' className='btn btn-primary btn-sm ml-2'>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                        退貨單
                    </Link>
                </div>
                <div className="mt-3">
                    <div>
                        <div className={tab === 1 ? aTabClass: tabClass} onClick={()=>this.switchTab(1)}>
                            己完成項目
                        </div>
                        <div className={tab === 2 ? aTabClass: tabClass}  onClick={()=>this.switchTab(2)}>
                            退貨項目
                        </div>
                        <div className={tab === 3 ? aTabClass: tabClass}  onClick={()=>this.switchTab(3)}>
                            已取消項目
                        </div>
                    </div>
                    <div className="custom-tab-body">
                        <div className="custom-tab-body py-2">
                            {(tab === 1 || tab === 3) &&
                            <Table data={list}
                                   accessors={[
                                       {key:'order_number', title:'入貨編號', render:((item) =><div className={item.flag ===1 ?"font-weight-bold text-danger":"font-weight-bold"}>{item.order_number}</div>)},
                                       {key:'version', title:'版本'},
                                       {key:'flag', title:'Flag'},
                                       {key:'total', title:'總數'},
                                       {key:'supplier', title:'供應商'},
                                       {key:'reference_number', title:'供應商號碼'},
                                       {key:'creator', title:'建立者'},
                                       {key:'delivery_date', title:'送貨日期', render:((item) => <Date date={item.delivery_date}/>)},
                                       {key:'created_at', title:'建立日期', render:((item) => <Date date={item.created_at} time={true}/>)},
                                       {key:'id', title:'修改', render:((item) => item.sales_order_number? <Link to={`/sales/item/${item.sales_order_number}/tradein`}>修改</Link>:<Link to={`/purchase/item/${item.order_number}`}>修改</Link>)}
                                   ]}
                                   tableKey='purchases'
                                   rowKey='id'
                                   search={(item, text)=>item.order_number.toUpperCase().includes(text.toUpperCase()) || (item.reference_number ? item.reference_number.toUpperCase().includes(text.toUpperCase()):false)}
                            />
                            }
                            {tab === 2 &&
                            <Table data={returns}
                                   accessors={[
                                       {key:'return_number', title:'退貨編號'},
                                       {key:'version', title:'版本'},
                                       {key:'total', title:'總數'},
                                       {key:'supplier', title:'供應商'},
                                       {key:'reference_number', title:'供應商號碼'},
                                       {key:'creator', title:'建立者'},
                                       {key:'return_date', title:'退貨日期', render:((item) => <Date date={item.return_date}/>)},
                                       {key:'created_at', title:'建立日期', render:((item) => <Date date={item.created_at} time={true}/>)},
                                       {key:'id', title:'修改', render:((item) => <Link to={`/purchase/return/item/${item.return_number}`}>修改</Link>)}
                                   ]}
                                   tableKey='returns'
                                   rowKey='id'
                                   search={(item, text)=>item.return_number.toUpperCase().includes(text.toUpperCase()) || item.reference_number.toUpperCase().includes(text.toUpperCase())}
                            />
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state)=>{return {list:state.purchase.list,returns:state.returns.list, suppliers:state.supplier.list, users:state.user.list}},{getItems, getReturns})(Purchase)