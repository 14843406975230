import React from "react";

class ClickableImage extends React.Component{
    constructor(props){
        super(props);
        this.onClick= this.onClick.bind(this);
    }

    onClick(){
        let {image, src} = this.props;
        window.open(src?src:`https://api2.otopac.com.hk/images/${image.path}`, 'car', 'height=500,width=350')
    }

    render() {
        let {image, style, src} = this.props;
        return (
            <img width={180} height={180}
                 onClick={this.onClick}
                 style={{
                     cursor:'pointer',
                     backgroundRepeat:" no-repeat",
                     backgroundSize: 'contain',
                     backgroundColor:'#000',
                     backgroundPosition:'center',
                     backgroundImage:src?`url(${src})`:`url(https://api2.otopac.com.hk/images/${image.path})`,
                    ...style
                 }}/>
        );
    }
}

export default ClickableImage