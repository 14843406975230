import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {login, initialLogin} from '../../reducers/core';
class Login extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            email: '',
            password:''
        };
        this.submit = this.submit.bind(this);
    }

    componentDidMount(){
        this.props.initialLogin()
    }

    submit(e){
        e.preventDefault();
        this.setState({loading:true});
        let {email, password} = this.state;
        this.props.login(email, password);
    }

    render(){
        const {loading, email, password} = this.state;
        return <div style={{position:'absolute', left:0, right:0, top:'30%', bottom:0}}>
            <div className="mx-auto p-3" style={{maxWidth:350, border:'1px solid #aeaeae'}}>
                <form onSubmit={this.submit}>
                    <div>
                        Please Login to see your POS system
                    </div>
                    <div className="form-group">
                        <label>Email Address</label>
                        <input className="form-control" name="email" onChange={(e)=>this.setState({email:e.target.value})} value={email}/>
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input className="form-control" type="password" onChange={(e)=>this.setState({password:e.target.value})} value={password}/>
                    </div>
                    <button className="mt-2 btn btn-dark-border w-100" type="submit">
                        {loading &&
                        <FontAwesomeIcon icon='spinner' fixedWidth={true} spin={true}/>
                        }
                        Login
                    </button>
                    <div className="my-3 text-center">
                        <Link to={'/register'}>
                            Register
                        </Link>
                    </div>
                </form>
            </div>
        </div>
    }
}

export default connect((state)=>{return {user:state.core.user}}, {login, initialLogin})(Login)