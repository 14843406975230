import React, {useMemo, useEffect, useRef} from "react";
import moment from 'moment';
import {connect} from 'react-redux';

function Outstanding({date, outstanding, open, store, close}){
    const container = useRef(null);
    useEffect(()=>{
        if(open){
            let mywindow = window.open('', 'Outstanding', 'height=800,width=900');
            mywindow.document.write('<html>');
            //adding the current css file in the head
            mywindow.document.write(document.head.innerHTML);
            mywindow.document.write('<body>');
            mywindow.document.write(container.current.innerHTML);
            mywindow.document.write('</body></html>');
            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/
            mywindow.onbeforeunload = close
        }
    },[open])
    if(!outstanding){
        return <div/>
    }
    return <div className="mt-3 d-none">
        <div ref={container} className="container print-block"
             media="print"
             style={{color:'#252b75ff', backgroundColor:'#fff'}}>
            <div className="p-3">
                <div>
                    <h5 className="font-weight-bold float-left">{store.full_title}</h5>
                    <div className="float-right">{moment().format('YYYY/MM/DD hh:mm')}列印</div>
                    <div className="clearfix"/>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h5 className="font-weight-bold mt-3">{date.format('YYYY年MM月DD日')} {date.format('dddd')} 未收帳資料</h5>
                        <Report
                                outstanding={outstanding}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

function Report({outstanding}){
   return <table className="table table-bordered mt-3 border-light"
                 style={{border:'2px solid #000'}}>
       <thead>
            <tr>
                <th  style={{borderBottom:'2px solid #000'}}>Invoice</th>
                <th  style={{borderBottom:'2px solid #000'}}>Customer</th>
                <th  style={{borderBottom:'2px solid #000'}}>Service At</th>
                <th  style={{borderBottom:'2px solid #000'}}>Outstanding</th>
            </tr>
       </thead>
       <tbody>
       {outstanding.map((d,i)=><tr key={i}>
           <td>
               {d.order_number}
           </td>
           <td>{d.customer}</td>
           <td>{d.service_at}</td>
           <td>
               {d.outstanding}
           </td>
       </tr>)}
       </tbody>
   </table>
}

export default connect((state)=>({store:state.core.store}))(Outstanding);