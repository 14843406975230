import React from 'react';
import {Link} from "react-router-dom";
import {connect} from 'react-redux';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getItems} from '../../reducers/counterparty'
import Date from "../common/Date";
import Table from "../common/Table";
class CounterParty extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            tab:1
        }
    }
    componentDidMount(){
        this.props.getItems();
    }
    
    render() {
        const {list} = this.props;
        const {tab} = this.state;
        const tabClass = "custom-tab";
        const aTabClass = "custom-tab active";
        return (
            <div className="mt-3">
                <div>
                    <Link to='/counterparty/item' className='btn btn-primary btn-sm'>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                        新增客戶
                    </Link>
                </div>
                <div className="mt-3">
                    <div className={tab === 1 ? aTabClass: tabClass} onClick={()=>this.setState({tab:1})}>
                        現有客戶
                    </div>
                    <div className={tab === 2 ? aTabClass: tabClass} onClick={()=>this.setState({tab:2})}>
                        已刪除客戶
                    </div>
                </div>
                <div className="custom-tab-body">
                    {tab ===1 &&
                    <Table data={list}
                           accessors={[
                               {key:'title', title:'名稱'},
                               {key:'is_company', title:'是公司', checkbox:true},
                               {key:'is_customer', title:'是客戶', checkbox:true},
                               {key:'is_supplier', title:'是供應商', checkbox:true},
                               {key:'is_garage', title:'是車房', checkbox:true},
                               {key:'is_towing', title:'是拖車公司', checkbox:true},
                               {key:'created_at', title:'建立日期', render:((item) => <Date date={item.created_at} time={true}/>)},
                               {key:'id', title:'修改', render:((item) => <Link to={`/counterparty/item/${item.id}`}>修改</Link>)}
                           ]}
                           tableKey='counterparties'
                           rowKey='id'
                           search={(item, text)=>item.title.toUpperCase().includes(text.toUpperCase())}
                    />
                    }
                    {tab === 2 &&
                    <Table data={[]}
                           accessors={[
                               {key:'title', title:'名稱'},
                               {key:'is_customer', title:'是客戶', checkbox:true},
                               {key:'is_supplier', title:'是供應商', checkbox:true},
                               {key:'created_at', title:'建立日期', render:((item) => <Date date={item.created_at} time={true}/>)},
                               {key:'id', title:'修改', render:((item) => <Link to={`/counterparty/item/${item.id}`}>修改</Link>)}
                           ]}
                           tableKey='counterparties_deleted'
                           rowKey='id'
                    />
                    }
                </div>
            </div>
        );
    }
}

export default connect((state)=>{return {list:state.counterparty.list}}, {getItems})(CounterParty)