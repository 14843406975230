import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {get, post} from '../../axios';
import Table from "../common/Table";
import Date from "../common/Date";
import {Link} from "react-router-dom";
export default function Stock(){
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState({});
    const [matched, setMatched] = useState([]);
    const [excess, setExcess] = useState([]);
    const [less, setLess] = useState([]);
    const dispatch = useDispatch();
    useEffect(()=>{
        const getData = async()=>{
            const users = await dispatch(get('/users'));
            const _users = users.filter(({is_deleted, is_worker, is_admin})=>(is_worker || is_admin) && !is_deleted);
            setUsers(_users);
        };
        getData();
    },[]);

    const submit = async ()=>{
        const users = Object.keys(selectedUsers).filter((k)=>selectedUsers[k])
        const data = await dispatch(post('/stocks/analysis', {users}));
        const {matched,excess,less} = data;
        setMatched(matched);
        setExcess(excess);
        setLess(less);
    };
    return <div>
        <div className="p-3" style={{backgroundColor:'#fff'}}>
            <h5>點倉易</h5>
            <label>選擇用戶</label>
            <div>
                {users.map((user)=><div key={user.id} className="form-check form-check-inline mr-2">
                    <input checked={!!selectedUsers[user.id]}
                           id={"checkbox"+user.id}
                           type="checkbox"
                           className=""
                           onChange={()=>setSelectedUsers({...selectedUsers, ...{[user.id]:!selectedUsers[user.id]}})}
                           value={!!selectedUsers[user.id]}/>
                    <label className="pl-2" htmlFor={"checkbox"+user.id}>{user.nick_name}</label>
                </div>)}
            </div>
            <div>
                <button className="btn btn-primary badge-pill btn-sm"
                        disabled={Object.keys(selectedUsers).filter((k)=>selectedUsers[k]).length === 0}
                        onClick={submit}>下載資料</button>
            </div>
        </div>
        <div className="mt-2 p-3" style={{backgroundColor:'#fff'}}>
            <h4 className="text-center font-weight-bold text-primary">點倉數量多過系統數量</h4>
            <Table data={excess}
                   accessors={[
                       {key:'product_id', title:'產品ID',  render:((item) => {
                               let link = '/inventory';
                               switch (item.category_id) {
                                   case 2:
                                       link += "/tyres/"+item.product_id;
                                       break;
                                   case 3:
                                       link += "/batteries/"+item.product_id;
                                       break;
                                   case 4:
                                       link += "/wheels/"+item.product_id;
                                       break

                               }
                               return <a target="_blank" href={link} style={{color:'#0c56aa'}}>
                                   {item.product_id}
                               </a>
                           })},
                       {key:'product_code', title:'產品號碼'},
                       {key:'title', title:'名稱'},
                       {key:'count_quantity', title:'點倉數量'},
                       {key:'system_quantity', title:'系統數量'},
                       {key:'details', title:'備註', render:((item) => {
                           if(!item.details){
                               return <div/>
                           }
                           return <>{item.details.map((d, i)=><div key={i}>{d}</div>)}</>
                           })},
                   ]}
                   tableKey='stock-excess'
                   rowKey='product_id'
                   search={(item, text)=>item.title.toUpperCase().includes(text.toUpperCase())}
            />
        </div>
        <div className="mt-2 p-3" style={{backgroundColor:'#fff'}}>
            <h4 className="text-center font-weight-bold text-primary">點倉數量少過系統數量</h4>
            <Table data={less}
                   accessors={[
                       {key:'product_id', title:'產品ID',  render:((item) => {
                                let link = '/inventory';
                                switch (item.category_id) {
                                    case 2:
                                        link += "/tyres/"+item.product_id;
                                        break;
                                    case 3:
                                        link += "/batteries/"+item.product_id;
                                        break;
                                    case 4:
                                        link += "/wheels/"+item.product_id;
                                        break

                                }
                               return <a target="_blank" href={link} style={{color:'#0c56aa'}}>
                                       {item.product_id}
                               </a>
                           })},
                       {key:'product_code', title:'產品號碼'},
                       {key:'title', title:'名稱'},
                       {key:'count_quantity', title:'點倉數量'},
                       {key:'system_quantity', title:'系統數量'},
                       {key:'details', title:'備註', render:((item) => {
                               if(!item.details){
                                   return <div/>
                               }
                               return <>{item.details.map((d, i)=><div key={i}>{d}</div>)}</>
                           })},{
                           key:'last_purchase_date', title:'最後購貨日期', render:((item) => <Date date={item.last_purchase_date}/>)
                       },
                   ]}
                   tableKey='stock-less'
                   rowKey='product_id'
                   search={(item, text)=>item.title.toUpperCase().includes(text.toUpperCase())}
            />
        </div>
        <div className="mt-2 p-3" style={{backgroundColor:'#fff'}}>
            <h4 className="text-center font-weight-bold text-primary">己對正資料</h4>
            <Table data={matched}
                   accessors={[
                       {key:'product_id', title:'產品ID'},
                       {key:'product_code', title:'產品號碼'},
                       {key:'title', title:'名稱'},
                       {key:'count_quantity', title:'點倉數量'},
                       {key:'system_quantity', title:'系統數量'},
                       {key:'details', title:'備註', render:((item) => {
                               if(!item.details){
                                   return <div/>
                               }
                               return <>{item.details.map((d, i)=><div key={i}>{d}</div>)}</>
                           })},
                   ]}
                   tableKey='stock'
                   rowKey='product_id'
                   search={(item, text)=>item.title.toUpperCase().includes(text.toUpperCase())}
            />
        </div>
    </div>
}