import React from 'react';
import {connect} from 'react-redux';
import {getFormData} from '../../reducers/product'
import {get} from '../../axios'
import {awaitAll} from '../../helper'
import SelectInput from '../common/SelectInput';
class Wheel extends React.Component{
    componentDidMount(){
        this.getData();
    }

    async getData(){
        let {getFormData, get} = this.props;
        try{
            let brands = get(`/wheels/brands`);
            let origins = get(`/products/origins`);
            let data = await awaitAll({brands, origins});
            getFormData('wheel', data)
        }catch (e) {
            console.error(e)
        }
    }

    render() {
        let {data, onChange, values} = this.props;
        let {brand_id, model, width, diameter, holes, et_offset, color, origin_id} = values;
        if(!data){
            return <div/>
        }
        return  <div className="p-3 mt-3" style={{background:'#fff'}}>
            <h6 className="font-weight-bold">車鈴資料</h6>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4">
                        <label>品牌*</label>
                        <SelectInput items={data.brands} value={brand_id} onChange={(v)=>onChange({brand_id:v})}/>
                    </div>
                    <div className="col-md-4">
                        <label>Model*</label>
                        <input className="form-control" value={model} onChange={(v)=>onChange({model:v.target.value})}/>
                    </div>
                    <div className="col-md-4">
                        <div className="row no-gutters">
                            <div className="col-4">
                                <label>直徑*</label>
                                <input className="form-control" value={diameter} type="number"  onChange={(v)=>onChange({diameter:v.target.value.toUpperCase()})}/>
                            </div>
                            <div className="col-4">
                                <label>寬度*</label>
                                <input className="form-control" value={width} type="number"  onChange={(v)=>onChange({width:v.target.value.toUpperCase()})}/>
                            </div>
                            <div className="col-4">
                                <label>Holes*</label>
                                <input className="form-control" value={holes}  onChange={(v)=>onChange({holes:v.target.value})}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-4">
                        <label>產地*</label>
                        <SelectInput items={data.origins} value={origin_id} onChange={(v)=>onChange({origin_id:v})}/>
                    </div>
                    <div className="col-md-4">
                        <label>Offset*</label>
                        <input className="form-control" value={et_offset} onChange={(v)=>onChange({et_offset:v.target.value})}/>
                    </div>
                    <div className="col-md-4">
                        <label>顏色*</label>
                        <input className="form-control" value={color}  onChange={(v)=>onChange({color:v.target.value})}/>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default connect((state)=>{return{ data:state.product.data['wheel']}},{getFormData, get})(Wheel)