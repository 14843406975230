import React, {useState, useEffect} from "react";
import DatePicker from "react-datepicker";
import moment from 'moment';

export default function DateInput({value, endTime, time, className, onChange, inputClassName}){
    const [_value, setValue] = useState(null);
    useEffect(()=>{
        if(value){
            let _value = new moment(value).toDate();
            setValue(_value);
            console.log('setValue', value, _value);
        }else{
            setValue(null);
        }
    },[value]);

    const _onChange = (value)=>{
        if(!value){
            onChange(null);
        }
        if(endTime){
            onChange(moment(value).endOf('day').toISOString());
        }else if(time){
            onChange(moment(value).toISOString());
        }else{
            onChange(moment(value).startOf('day').toISOString());
        }
    };

    return (
        <div className={className}>
            <DatePicker
                className={inputClassName}
                showTimeSelect={!!time}
                selected={_value}
                dateFormat={time? 'Pp': 'MM/dd/yyyy'}
                onChange={_onChange}
            />
            <button className="btn btn-sm" onClick={()=>_onChange(null)}>重設</button>
        </div>
    );
}
