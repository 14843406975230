import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from 'react-redux';
import {getItems} from "../../reducers/cash";
import Table from "../common/Table";
import Date from "../common/Date";
class Cash extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            tab:1
        }
    }

    componentDidMount(){
        this.props.getItems();
    }

    render() {
        const {list} = this.props;
        if(!list){
            return <div/>
        }
        const {tab} = this.state;
        const tabClass = "custom-tab";
        const aTabClass = "custom-tab active";
        return (
            <div className="mt-3">
                <div className="mt-3">
                    <Link to='/cash/item' className='btn btn-primary btn-sm'>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                        資金調整
                    </Link>
                </div>
                <div className="mt-3">
                    <Table data={list.balance}
                           accessors={[
                               {key:'payment_method', title:'付款方式'},
                               {key:'amount', title:'數目', render:(item)=><span>{item.amount.toLocaleString()}</span>},
                           ]}
                           tableKey='cash balance'
                           rowKey='payment_method_id'
                    />
                </div>
                <div className="mt-3">
                    <div className={tab === 1 ? aTabClass: tabClass} onClick={()=>this.setState({tab:1})}>
                        資金紀錄
                    </div>
                </div>
                <div className="custom-tab-body">
                    <Table data={list.list.map((x, y)=>{x.key = y; return x;})}
                           accessors={[
                               {key:'invoice_number', title:'單號', render:(item)=><span>{item.invoice_number? item.invoice_number : `調整紀錄${item.id}`}</span>},
                               {key:'amount', title:'數目', render:(item)=><span>{item.amount.toLocaleString()}</span>},
                               {key:'payment_method', title:'付款方式'},
                               {key:'remarks', title:'備註'},
                               {key:'purchase_number', title:'入貨單', render:((item) => item.purchase_number? <Link className="btn btn-sm btn-info" to={`/purchase/item/${item.purchase_number}`}>{item.purchase_number}</Link> : <span/>)},
                               {key:'order_number', title:'銷售單', render:((item) => item.order_number? <Link className="btn btn-sm btn-info" to={`/sales/item/${item.order_number}`}>{item.order_number}</Link> : <span/>)},
                               {key:'return_number', title:'退貨單', render:((item) => item.return_number? <Link className="btn btn-sm btn-info" to={`/purchase/return/item/${item.return_number}`}>{item.return_number}</Link> : <span/>)},
                               {key:'creator', title:'建立者'},
                               {key:'payment_date', title:'紀錄日期', render:((item) => <Date date={item.payment_date} time={true}/>)},
                           ]}
                           tableKey='cash'
                           rowKey='key'
                           search={(item, text)=>item.payment_method.toUpperCase().includes(text.toUpperCase())}
                    />
                </div>
            </div>
        );
    }
}

export default connect((state)=>{return {list:state.cash.list}}, {getItems})(Cash)