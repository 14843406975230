import React from 'react';
import moment from 'moment-timezone';
moment.locale('zh-HK');
export default function Date({date, time, day}){
    let text;
    if(day && time){
        text = moment(date).tz("Asia/Hong_Kong").format('LLLL');
    }else if(time){
        text = moment(date).tz("Asia/Hong_Kong").format("YYYY/MM/DD H:mm");
    }else{
        text = moment(date).tz("Asia/Hong_Kong").format("YYYY/MM/DD");
    }
    return <span>{text}</span>
}

