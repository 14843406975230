import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Payment from "../component/Payment";
import Date from "../common/Date";
import {connect} from 'react-redux';
import {get, post} from "../../axios";
import FileUpload from "../common/FileUpload";
import {getFormData, getItem, submit, deleteItem} from "../../reducers/expenditure";
class ExpenditureItem extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            items:[],
            payment: {
                invoice_number:null,
                purchaser_id:null,
                purchased_at:null,
                reference_number:'',
                remarks:'',
                supplier_id:null,
            },
            tab:null,
            edit:null,
            error:'',
        };
        this.addItem = this.addItem.bind(this);
        this.editItem = this.editItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.delete = this.delete.bind(this);
        this.submit = this.submit.bind(this);
    }


    componentDidMount(){
        if(this.props.match && this.props.match.params.id){
            this.props.getItem(this.props.match.params.id);
        }
    }

    componentWillReceiveProps(props){
        if(props.item && props.item !== this.props.item){
            this.setState({...props.item})
        }
    }

    delete(){
        console.log('delete');
        let {history} = this.props;
        this.props.deleteItem( this.state, history);
    }

    submit(){
        console.log('submit');
        let {items, payment} = this.state;
        let {purchaser_id, invoice_date, supplier_id} = payment;
        let error = '';
        if(items.length === 0){
            error = '請最少加入一個項目'
        }else if(purchaser_id === null){
            error = '請輸入入貨員'
        }else if(invoice_date === null){
            error = '請輸入購買日期 '
        }else if(supplier_id === null){
            error = '請輸入供應商'
        }else{
            let {history} = this.props;
            this.props.submit( this.state, history);
        }
        this.setState({error:error});
    }

    addItem(item){
        let {items} = this.state;
        items.push(item);
        this.setState({items, tab:null});
    }

    editItem(item){
        let {items, edit} = this.state;
        let _items = items.filter((item)=>!item.is_deleted).map((i,index)=>{
            if(index === edit){
                return item
            }else{
                return i
            }
        });
        this.setState({items : _items, edit:null});
    }

    removeItem(index){
        let {items} = this.state;
        let _items = items.map((item,i)=>{
            if(index === i){
                return {...item, is_deleted:true}
            }else{
                return item
            }
        });
        this.setState({items : _items, edit:null});
    }

    render() {
        const {items, tab, edit, payment, order_number, created_at} = this.state;
        let _items = items.filter((item)=>!item.is_deleted);
        return (
            <div style={{position:'relative', height:'100%'}}>
                <div style={{position:'absolute', left:0, top:0, bottom:0, right:0}}>
                    <div className="mt-3">
                        <button className="btn btn-primary btn-sm" onClick={this.submit}>
                            儲存
                        </button>
                        {order_number &&
                        <button className="btn btn-danger btn-sm ml-2" onClick={this.delete}>
                            取消入貨
                        </button>
                        }
                    </div>
                    <div className="p-3 mt-3" style={{background:'#fff'}}>
                        <div className="row">
                            <label className="col-md-2 font-weight-bold">支出編號</label>
                            <div className="col-md-10 font-weight-bold">
                                {order_number}
                            </div>
                        </div>
                        <div className="row">
                            <label className="col-md-2 font-weight-bold">建立日期</label>
                            <div className="col-md-10 font-weight-bold">
                                <Date date={created_at} time={true}/>
                            </div>
                        </div>
                        <h6 className="font-weight-bold">支出項目</h6>
                        <table className="table">
                            <thead>
                            <tr>
                                <th>項目</th>
                                <th>數量</th>
                                <th>價格</th>
                                <th>小計</th>
                                <th>備註</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {_items.map((item, index)=><tr key={index}>
                                <td>{item.title}</td>
                                <td>{item.quantity}</td>
                                <td>{item.price}</td>
                                <td>{+item.price * +item.quantity}</td>
                                <td>{item.remarks}</td>
                                <td><button className="btn btn-sm btn-info" onClick={()=>this.setState({edit:index, tab:null})}>修改</button> <button className="btn btn-sm btn-danger" onClick={()=>this.removeItem(index)}>刪除</button></td>
                            </tr>)}
                            </tbody>
                        </table>
                        <h6>加入項目</h6>
                        <button onClick={()=>this.setState({tab:1, edit:null})}
                            className="ml-2 btn btn-sm btn-outline-primary">
                            <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                            加入
                        </button>
                        {edit !== null && <FormWrapper edit={edit} addItem={this.editItem} items={_items}/>}
                        {tab !== null && <FormWrapper addItem={this.addItem} items={items}/>}
                    </div>
                    <Payment onChange={(values)=>this.setState({payment: {...payment, ...values}})} values={payment}/>
                    {order_number &&
                    <Files order_number={order_number}/>
                    }
                </div>
            </div>
        );
    }
}

class FormWrapper extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            price:"",
            title:"",
            quantity:"",
            remarks:""
        };
        this.add = this.add.bind(this);
    }

    componentWillReceiveProps(props){
        if(props.tab !== this.props.tab || props.edit !== this.props.edit){
            this.update(props);
        }
    }

    componentDidMount(){
        this.update(this.props);
    }

    update(props){
        const {tab, edit, items} = props;
        if(typeof edit !== 'undefined'){
            let item = items[edit];
            this.setState({...item});
        }
    }

    add(){
        let error = null;
        let {price, title, quantity} = this.state
        if(price === ''){
            error ='請輸入價格';
        }else if(title ===''){
            error ='請輸入貨品/服務';
        }else if(quantity ===''){
            error ='請輸入數量';
        }
        console.log(error)
        if(error){
            this.setState({error})
        }else{
            this.props.addItem(this.state);
        }
    }

    render() {
        const {price, title, quantity, remarks} = this.state;
        return (
            <div style={{border: '1px solid #0275d8'}}>
                <div className="p-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-4">
                                <label>貨品/服務</label>
                                <input className="form-control" value={title} onChange={(e)=>this.setState({title:e.target.value})}/>
                            </div>
                            <div className="col-md-4">
                                <label>價格</label>
                                <input className="form-control" type='number' value={price} onChange={(e)=>this.setState({price:e.target.value})}/>
                            </div>
                            <div className="col-md-4">
                                <label>數量</label>
                                <input className="form-control" type='number' value={quantity} onChange={(e)=>this.setState({quantity:+e.target.value})}/>
                            </div>
                            <div className="col-md-4">
                                <label>備註</label>
                                <input className="form-control" value={remarks}  onChange={(e)=>this.setState({remarks:e.target.value})}/>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-4">
                                <div className="text-danger">{this.state.error}</div>
                            </div>
                            <div className="col-md-4"/>
                            <div className="col-md-4">
                                <button className="btn btn-primary float-right" onClick={this.add}>{this.props.item? "修改":"加入"}</button>
                                <div className="clearfix"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


class Files extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            items:[]
        };
        this.getData = this.getData.bind(this);
        this.uploaded = this.uploaded.bind(this);
    }

    componentDidMount(){
        this.getData();
    }

    async getData(){
        try{
            let {get, order_number} = this.props;
            let items = await get(`/expenditures/${order_number}/files`);
            this.setState({items})
        }catch (e) {
            console.error(e)
        }
    }

    async uploaded(items){
        let {post, order_number} = this.props;
        try{
            let _items = this.state.items.concat(items);
            post(`/expenditures/${order_number}/files`, _items);
            this.setState({items:_items})
        }catch (e) {
            console.error(e)
        }
    }

    openFile(url){
        let mywindow = window.open(url, 'FILE', 'height=800,width=600');
        mywindow.focus(); // necessary for IE >= 10
    }

    render(){
        const style = {
            border:'1px solid #000',
            paddingLeft:'5px'
        };
        const {items} = this.state;
        return <div className="p-3 mt-3" style={{background:'#fff'}}>
            <div className="font-weight-bold">檔案</div>
            <FileUpload uploaded={this.uploaded}/>
            <div className="row no-gutters">
                <div className="col-1 font-weight-bold" style={style}>ID</div>
                <div className="col-4 font-weight-bold" style={style}>上載者</div>
                <div className="col-4 font-weight-bold" style={style}>上載日期</div>
                <div className="col-3 font-weight-bold" style={style}></div>
                {items.map((item)=>{return <React.Fragment key={item.id}>
                    <div className="col-1" style={style}>{item.id}</div>
                    <div className="col-4" style={style}>{item.nick_name}</div>
                    <div className="col-4" style={style}><Date date={item.created_at} time={true}/></div>
                    <div className="col-3" style={style}><button className="btn btn-sm btn-primary" onClick={()=>this.openFile(`https://api2.otopac.com.hk/files/${item.path}`)}>打開</button></div>
                </React.Fragment>})}
            </div>
        </div>
    }
}

Files = connect(()=>{return {}}, {get, post} )(Files);
export default connect((state)=>{
    return{data: state.expenditure.data, item:state.expenditure.item,}},
    {get, getItem, getFormData, submit, deleteItem})(ExpenditureItem);
