import DateInput from "../common/DateInput";
import React from "react";
import connect from "react-redux/es/connect/connect";
import {get} from "../../axios";
import moment from "moment";
import Table from "../common/Table";
import Date from "../common/Date";
import ClickableImage from "../common/ClickableImage";
import CarReport from "../reports/CarReport";

class Car extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            data:[],
            open : false,
            start_date:moment().startOf('month').toDate(),
            end_date:moment().endOf('day').toISOString()};
        this.getData = this.getData.bind(this);
        this.generateReport = this.generateReport.bind(this);
        this.closeReport = this.closeReport.bind(this);
    }


    componentDidMount(){
        this.getData();
    }

    closeReport(){
        this.setState({open:false})
    }

    async getData(e){
        if(e){
            e.preventDefault();
        }
        let {start_date, end_date} = this.state;
        let data = await this.props.get('/cars',  {start_date, end_date});
        this.setState({data})
    }

    async generateReport(){
        const {selectedDate} = this.state;
        const {get} = this.props;
        let m = moment(selectedDate);
        let detail = await get('/cars',
            {start_date:m.startOf('day').toISOString(),
                end_date:selectedDate});
        this.setState({carReport:{detail, date:selectedDate}, open:true})

    }


    render(){
        const {start_date, end_date, data, carReport, open, selectedDate} = this.state;
        return <div className="mt-3 bg-faded">
            <div className="d-inline-block ml-2" style={{border:'1px solid #aaa'}}>
                <span className="ml-2">選擇日期</span>
                <DateInput className="d-inline-block ml-2" endTime={true}  onChange={(v)=>this.setState({selectedDate:v})} value={selectedDate}/>
                {selectedDate &&
                <React.Fragment>
                    <button className="btn btn-success btn-sm ml-2" onClick={this.generateReport}>
                        建立車輛報告
                    </button>
                </React.Fragment>
                }
            </div>
            <form onSubmit={this.getData}>
                <h6 className="d-flex mt-3">
                    <span className="mr-2">
                        開始日期
                    </span>
                    <DateInput  value={start_date} onChange={(v)=>this.setState({start_date:v})} />
                    <span className="mx-2">-</span>
                    <span className="mx-2">
                        最後日期
                    </span>
                    <DateInput value={end_date} endTime={true} onChange={(v)=>this.setState({end_date:v})}/>
                    <button className="mx-2 btn btn-sm btn-primary">確定</button>
                </h6>
            </form>
            <Table data={data}
                   accessors={[
                       {key:'id', title:'ID'},
                       {key:'path', title:'相片', render:((item) => item.path? <ClickableImage image={item}/> :'')},
                       {key:'object_id', title:'車輛編號'},
                       {key:'plate', title:'車牌', render:((item)=> {
                            if(item.plate_external){
                                return <div >{item.plate_external.plate.toUpperCase()}</div>
                            }
                           })},
                       {key:'entered_at', title:'入車時間', render:((item) => <Date date={item.entered_at} time={true}/>)},
                       {key:'left_at', title:'出車時間', render:((item) => item.left_at? <Date date={item.left_at} time={true}/> :'未出車')},
                       {key:'left_at', title:'停留時間', render:((item) => {
                           if(!item.left_at){
                               return <span></span>
                           }
                           let duration = moment.duration(moment(item.left_at).diff(moment(item.entered_at)));
                               return <span>{duration.hours()}小時{duration.minutes()}分鐘</span>
                           })},
                       {key:'order_number', title:'項目',render:(item)=><div>
                               <div>銷售號碼 {item.order_number}</div>
                               <div>
                                   {item.items? item.items.map((i, x)=><div key={x}>{i.title} {i.price}</div>):''}
                               </div>
                           </div>},
                       {key:'id', title:'Detail',render:(item)=><ClickableDetail get={this.props.get} item={item}/>}
                   ]}
                   tableKey='cars'
                   rowKey='id'
                   search={(item, text)=>item.plate? item.plate.toUpperCase().includes(text.toUpperCase()): true}
            />
            <CarReport open={open}
                       date={selectedDate}
                       carReport={carReport}
                       close={this.closeReport}/>
        </div>
    }
}

class ClickableDetail extends React.Component{
    constructor(props){
        super(props);
        this.onClick= this.onClick.bind(this);
        this.state = {detail:null}
    }

    async onClick(e){
        e.preventDefault();
        let {get, item} = this.props;
        let data = await get('/cars/'+ item.id);
        this.setState({detail:data}, ()=>{
            let mywindow = window.open('', 'CAR', 'height=800,width=600');
            mywindow.document.write('<html>');
            //adding the current css file in the head
            mywindow.document.write(document.head.innerHTML);
            mywindow.document.write('<body>');
            mywindow.document.write(this.refs.container.innerHTML);
            mywindow.document.write('</body></html>');
            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/
        });
    }

    render(){
        let {detail} = this.state;
        return <React.Fragment>
           <a onClick={this.onClick} href="#">詳細資料</a>
           {detail &&
           <div ref="container" className="d-none">
                <img src={`https://api2.otopac.com.hk/images/${detail.path}`}/>
               {detail.plates ? detail.plates.map((plate)=><img key={plate} src={`https://api2.otopac.com.hk/images/${plate}`}/>) :''}
           </div>
           }
       </React.Fragment>
    }
}

export default connect((state)=>{return{}}, {get})(Car)