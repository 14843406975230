import React, { useEffect, useState, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { get, post } from "../../axios";
import { awaitAll } from "../../helper";
import { getFormData, getItem, submit } from "../../reducers/sale";
import SelectInput from "../common/SelectInput";
import MultiSelectInput from "../common/MultiSelectInput";
import DateInput from "../common/DateInput";
import Invoice from "../reports/Invoice";
import moment from "moment";
import ClickableImage from "../common/ClickableImage";
import FileUpload from "../common/FileUpload";
import Date from "../common/Date";

function SalesItem({ user, match, history }) {
  const [data, setData] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [order_number, setOrderNumber] = useState(null);
  const [creator_id, setCreatorId] = useState(null);
  // const [comments,setComments] = useState('');
  // const [deposit,setDeposit] = useState('');
  const [created_at, setCreatedAt] = useState(moment().toISOString());
  const [initiated_at, setInitiatedAt] = useState(moment().toISOString());
  const [invoice_creator_id, setInvoiceCreatorId] = useState(null);
  const [disable, setDisable] = useState(false);
  const [profitMode, setProfitMode] = useState(false);
  const [send_discount_code, setSendDiscountCode] = useState(false);
  const [tyreFront, setTyreFront] = useState([]);
  const [tyreRear, setTyreRear] = useState([]);
  const [batteries, setBatteries] = useState([]);
  const [wheels, setWheels] = useState([]);
  const [accessories, setAccessories] = useState([]);
  const [services, setServices] = useState([]);
  const [serviceItems, setServiceItems] = useState([]);
  const [service_at, setServiceAt] = useState(moment().toISOString());
  const [reservice_date, setReserviceDate] = useState(null);
  const [sales_id, setSalesId] = useState(null);
  const [servicer_ids, setServicersIds] = useState([]);
  const [contact, setContact] = useState("");
  const [customer_id, setCustomerId] = useState(null);
  const [customer_name, setCustomerName] = useState("");
  const [car_plate, setCarPlate] = useState("");
  const [car_brand, setCarBrand] = useState("");
  const [car_model, setCarModel] = useState("");
  const [car_tyre_fl, setCarTyreFL] = useState("");
  const [car_tyre_fr, setCarTyreFR] = useState("");
  const [car_tyre_rl, setCarTyreRL] = useState("");
  const [car_tyre_rr, setCarTyreRR] = useState("");
  const [car_tyre_flp, setCarTyreFLP] = useState("");
  const [car_tyre_frp, setCarTyreFRP] = useState("");
  const [car_tyre_rlp, setCarTyreRLP] = useState("");
  const [car_tyre_rrp, setCarTyreRRP] = useState("");
  const [recommendation, setRecommendation] = useState("");
  const [is_new_customer, setIsNewCustomer] = useState(true);
  const [warranty, setWarranty] = useState("");
  const [discount, setDiscount] = useState(0);
  const [discount_code, setDiscountCode] = useState(null);
  const [reference_number, setReferenceNumber] = useState("");
  const [referral, setReferral] = useState("");
  const [referral_id, setReferralId] = useState(null);
  const [referral_type, setReferralType] = useState(null);
  const [expenditures, setExpenditures] = useState([]);
  const [files, setFiles] = useState([]);
  const [car_object_id, setCarObjectId] = useState(null);
  const [message_type, setMessageType] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [payment, setPayment] = useState({
    payment_method: 1,
    remarks: "",
    invoice_date: moment().toDate(),
  });
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async (id) => {
      const data = await dispatch(get(`/sales/${id}`));
      const {
        order_number,
        created_at,
        payment,
        items,
        customer_id,
        creator,
        service_at,
        car_brand,
        car_model,
        car_plate,
        sales_id,
        servicer_ids,
        contact,
        reference_number,
        reservice_date,
        car_object_id,
        discount,
        discount_code,
        send_discount_code,
        expenditures,
        warranty,
        creator_id,
        invoice_creator_id,
        car_tyre_fl,
        car_tyre_fr,
        car_tyre_rl,
        car_tyre_rr,
        car_tyre_flp,
        car_tyre_frp,
        car_tyre_rlp,
        car_tyre_rrp,
        recommendation,
        remarks,
        referral_type,
        referral,
        message_type,
        referral_id,
        initiated_at,
          // comments,
          // deposit
      } = data;
      const services = items.filter(
        (i) => i.category_id === 1 || i.category_id === null
      );
      const frontTyres = items.filter((i) => i.category_id === 2 && !i.rear);
      const rearTyres = items.filter((i) => i.category_id === 2 && i.rear);
      const batteries = items.filter((i) => i.category_id === 3);
      const wheels = items.filter((i) => i.category_id === 4);
      const accessories = items.filter((i) => i.category_id === 5);
      setCreatedAt(created_at);
      setInitiatedAt(initiated_at);
      setOrderNumber(order_number);
      setCreatorId(creator_id);
      // setComments(comments);
      // setDeposit(deposit);
      setInvoiceCreatorId(invoice_creator_id);
      setPayment({ ...payment });
      setServices(services);
      setTyreFront(frontTyres);
      setTyreRear(rearTyres);
      setBatteries(batteries);
      setWheels(wheels);
      setAccessories(accessories);
      setCustomerId(customer_id);
      setServiceAt(service_at);
      setCarBrand(car_brand);
      setCarModel(car_model);
      setCarPlate(car_plate);
      setSalesId(sales_id);
      setServicersIds(servicer_ids);
      setContact(contact);
      setReserviceDate(reservice_date);
      setDiscount(discount);
      setDiscountCode(discount_code);
      setSendDiscountCode(send_discount_code);
      setIsNewCustomer(false);
      setExpenditures(expenditures);
      setReferenceNumber(reference_number);
      setCarObjectId(car_object_id);
      setWarranty(warranty);
      setCarTyreFL(car_tyre_fl);
      setCarTyreFR(car_tyre_fr);
      setCarTyreRL(car_tyre_rl);
      setCarTyreRR(car_tyre_rr);
      setCarTyreFLP(car_tyre_flp);
      setCarTyreFRP(car_tyre_frp);
      setCarTyreRLP(car_tyre_rlp);
      setCarTyreRRP(car_tyre_rrp);
      setRecommendation(recommendation);
      setRemarks(remarks);
      setReferralType(referral_type);
      setReferral(referral);
      setReferralId(referral_id);
      setMessageType(message_type);
    };
    if (match && match.params && match.params.id) {
      getData(match.params.id);
    }
  }, [match]);

  useEffect(() => {
    const getData = async () => {
      const serviceItems = await dispatch(get("/services/items", {}));
      setServiceItems(serviceItems);
      const clients = dispatch(get(`/clients`));
      const users = dispatch(get(`/users`));
      const expenditures = dispatch(get(`/expenditures`));
      let discounts = await dispatch(get(`/discounts`));
      discounts = discounts.map((item) => {
        return {
          ...item,
          title: `${item.discount_code} / $${item.amount} / ${
            item.is_used ? "已使用" : "未使用"
          }`,
        };
      });
      const accessories = await dispatch(
        get("/stocks/products", { category_id: 5 })
      );
      const data = await awaitAll({
        clients,
        users,
        expenditures,
        discounts,
        accessories,
      });
      setData(data);
    };
    getData();
  }, []);

  useEffect(() => {
    if (customer_id && data) {
      const customer = data.clients.find(({ id }) => id === +customer_id);
      if (customer) {
        setCustomerName(customer.title);
        setContact(customer.contact);
      }
    }
  }, [data, customer_id]);
  useEffect(() => {
    if (data) {
      const item = data.discounts.find(
        ({ discount_code: _discount_code }) => _discount_code === discount_code
      );
      if (item) {
        setDiscount(item.amount);
      } else if (discount) {
        setDiscount(discount);
      } else {
        setDiscount(0);
      }
    }
  }, [discount_code, data]);

  useEffect(() => {
    const getData = async (expenditures) => {
      setFiles([]);
      let _files = [];
      for (const exp of expenditures) {
        console.log("exp", exp);
        const files = await dispatch(get(`/expenditures/${exp}/files`));
        _files = _files.concat(files);
      }
      setFiles(_files);
    };
    if (expenditures.length > 0) {
      getData(expenditures);
    }
  }, [expenditures]);

  const referrals = useMemo(() => {
    if (!referral_type || !data || !data.clients) {
      return [];
    }
    return data.clients.filter(
      ({ is_customer, is_garage, is_towing, is_company }) => {
        switch (referral_type) {
          case 1:
            return is_company;
          case 2:
            return is_garage;
          case 3:
            return is_towing;
          case 4:
            return is_customer;
          default:
            return false;
        }
      }
    );
  }, [referral_type, data]);
  const total = useMemo(() => {
    let total = -discount;
    tyreFront.forEach((i) => (total += +i.net_price * +i.quantity));
    tyreRear.forEach((i) => (total += +i.net_price * +i.quantity));
    batteries.forEach((i) => (total += +i.net_price * +i.quantity));
    wheels.forEach((i) => (total += +i.net_price * +i.quantity));
    accessories.forEach((i) => (total += +i.net_price * +i.quantity));
    services.forEach((i) => (total += +i.net_price * +i.quantity));
    
    return total;
  }, [discount, tyreFront, tyreRear, batteries, wheels, accessories, services]);

  const _submit = () => {
    setDisable(true);
    if (remarks === "") {
      if (+discount > 0 && !discount_code) {
        setMessage("請提供折扣原因");
        return setDisable(false);
      } else {
        let discount = 0;
        tyreFront.forEach(
          (i) => (discount += +(+i.price - i.net_price) * +i.quantity)
        );
        tyreRear.forEach(
          (i) => (discount += +(+i.price - i.net_price) * +i.quantity)
        );
        batteries.forEach(
          (i) => (discount += +(+i.price - i.net_price) * +i.quantity)
        );
        wheels.forEach(
          (i) => (discount += +(+i.price - i.net_price) * +i.quantity)
        );
        accessories.forEach(
          (i) => (discount += +(+i.price - i.net_price) * +i.quantity)
        );
        if (discount / total > 0.6) {
          console.log("discount", discount, total);
          console.log("need to provide reason");
          setMessage("請提供折扣原因");
          return setDisable(false);
        }
      }
    }
    const items = [
      ...tyreFront,
      ...tyreRear,
      ...accessories,
      ...batteries,
      ...services,
      ...wheels,
    ];
    dispatch(
      submit(
        {
          creator_id,
          payment,
          warranty,
          car_brand,
          car_model,
          car_plate,
          car_tyre_fl,
          car_tyre_fr,
          car_tyre_rl,
          car_tyre_rr,
          car_tyre_flp,
          car_tyre_frp,
          car_tyre_rlp,
          car_tyre_rrp,
          recommendation,
          items,
          car_object_id,
          reservice_date,
          service_at,
          sales_id,
          servicer_ids,
          expenditures,
          is_new_customer,
          customer_name,
          contact,
          customer_id,
          order_number,
          discount_code,
          discount,
          remarks,
          referral,
          referral_type,
          message_type,
          invoice_creator_id,
          send_discount_code,
          // comments,
          // deposit
        },
        history
      )
    );
    setDisable(false);
  };

  /**
    const sendCoupon = async ()=>{
        setDisable(true);
        const find1 = services.find(({product_id})=>product_id===20558);
        const find2 = services.find(({product_id})=>product_id===20559);
        if(!find1 && !find2){
            services.push({
                category_id: 1,
                product_id: 20558,
                price: 0,
                net_price: 0,
                quantity:1,
                product_code: "COUPON1",
                remarks: "",
                title: "贈送Otopac $100優惠券"
            });
            services.push({
                category_id: 1,
                product_id: 20559,
                job_count: 0,
                price: 0,
                net_price: 0,
                quantity:1,
                product_code: "COUPON2",
                remarks: "",
                title: "贈送BOX 洗車優惠券"
            });
        }
        try{
            if(!contact || (!customer_name)){
                throw '請填寫客戶名稱及電話'
            }

            const { result }= await dispatch(post('/discounts/sendCoupon',
                {contact, customer_name:customer_name}));
            if(result){
                window.alert('已送出電子禮卷')
            }
        }catch (e) {
            window.alert(JSON.stringify(e))
        }
        setDisable(false);
        this.setState({disable:false});
    }
    **/

  const generateInvoice = async (invoice_number) => {
    try {
      const invoice = await dispatch(get(`/sales/${invoice_number}/invoice`));
      setInvoice(invoice);
    } catch (e) {
      console.error(e);
    }
  };

  const generateProfitInvoice = async (invoice_number) => {
    try {
      const invoice = await dispatch(get(`/sales/${invoice_number}/profit`));
      setInvoice(invoice);
      setProfitMode(true);
    } catch (e) {
      console.error(e);
    }
  };

  const changeReferralId = (id) => {
    setReferralId(id);
    const ref = referrals.find((ref) => ref.id === id);
    if (ref) {
      let text = `${ref.title} ${ref.contact}`;
      setReferral(text);
    } else {
      setReferral("");
    }
  };

  if (!data) {
    return <div />;
  }
  return (
    <div style={{ position: "relative", height: "100%" }}>
      <div
        style={{ position: "absolute", left: 0, top: 0, bottom: 0, right: 0 }}
      >
        <div className="my-3">
          <button
            className="btn btn-primary btn-sm  badge-pill px-3"
            onClick={_submit}
            disabled={!!disable}
          >
            儲存
          </button>
          <span className="text-danger">{message}</span>
          {order_number && (
            <button
              className="btn btn-success btn-sm  badge-pill px-3 ml-2"
              onClick={() => generateInvoice(order_number)}
            >
              顯示帳單
            </button>
          )}
          {user.is_admin && order_number && (
            <button
              className="btn btn-success btn-sm  badge-pill px-3 ml-2"
              onClick={() => generateProfitInvoice(order_number)}
            >
              顯示利潤帳單
            </button>
          )}
        </div>
        {invoice && (
          <Invoice
            sales={invoice}
            profitMode={profitMode}
            close={() => setInvoice(null)}
          />
        )}
        <div className="p-3" style={{ background: "#fff" }}>
          <div className="row">
            <div className="col-8">
              <h5 className="text-center font-weight-bold">INVOICE</h5>
              <table className="table table-bordered border-light">
                <thead>
                  <tr>
                    <th style={{ width: 100 }}>項目</th>
                    <th>說明</th>
                    <th style={{ width: 100 }}>數量</th>
                    <th style={{ width: 100 }}>單價</th>
                    <th style={{ width: 200 }}>金額</th>
                  </tr>
                </thead>
                <tbody>
                  <TyreItem
                    tyreFront={tyreFront}
                    tyreRear={tyreRear}
                    setTyreFront={setTyreFront}
                    setTyreRear={setTyreRear}
                    setCarTyreFL={setCarTyreFL}
                    setCarTyreFR={setCarTyreFR}
                    setCarTyreRL={setCarTyreRL}
                    setCarTyreRR={setCarTyreRR}
                  />
                  <BatteryItem
                    batteries={batteries}
                    setBatteries={setBatteries}
                  />
                  <WheelItem wheels={wheels} setWheels={setWheels} />
                  <AccessoryItem
                    items={data.accessories}
                    accessories={accessories}
                    setAccessories={setAccessories}
                  />
                  <WheelAlignment
                    services={services}
                    setServices={setServices}
                    items={serviceItems}
                  />
                  <OtherServices
                    services={services}
                    setServices={setServices}
                    items={serviceItems}
                  />
                  <tr>
                    <td colSpan={2}>
                      <div className="">
                        <div>
                          <div>保養卡:</div>
                          <div>
                            <input
                              onChange={(e) => setWarranty(e.target.value)}
                              value={warranty}
                            />
                          </div>
                        </div>
                        <div>
                          <div>贈品</div>
                          <Gifts
                            items={data.accessories}
                            accessories={accessories}
                            setAccessories={setAccessories}
                          />
                        </div>
                      </div>
                    </td>
                    <td colSpan={2}>
                      <div>優惠碼:</div>
                      <div>
                        <SelectInput
                          items={data.discounts}
                          onChange={setDiscountCode}
                          uniqueKey="discount_code"
                          value={discount_code}
                          title="title"
                        />
                      </div>
                    </td>
                    <td colSpan={1}>
                      <div>折扣:</div>
                      <div>
                        <input
                          onChange={(e) => setDiscount(e.target.value)}
                          value={discount}
                        />
                      </div>
                      <h4 className="mt-3 font-weight-bold">總數:{total}</h4>
                    </td>
                  </tr>
                  <Payment
                    payment={payment}
                    setPayment={setPayment}
                    total={total}
                  />
                </tbody>
              </table>
              <div className="mt-3">
                <table className="table table-bordered border-light">
                  <tr>
                    <td colSpan={2}>
                      <h5>內部專用</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>車輛資料</td>
                    <td>
                      <CarImage
                        service_at={service_at}
                        onChange={(value, service_at) => {
                          setCarObjectId(value);
                          if (service_at) {
                            setServiceAt(service_at);
                          }
                        }}
                        value={car_object_id}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>對支出單</td>
                    <td>
                      <MultiSelectInput
                        items={data.expenditures}
                        onChange={setExpenditures}
                        valueKey={"order_number"}
                        value={expenditures}
                        title="order_number"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <span>快捷鍵</span>
                      <button
                        className="btn btn-sm btn-warning badge-pill ml-2"
                        onClick={() => setRemarks(remarks + " 朋友價")}
                      >
                        朋友價
                      </button>
                      <button
                        className="btn btn-sm btn-warning badge-pill ml-2"
                        onClick={() => setRemarks(remarks + " 跟Tyrepac")}
                      >
                        跟Tyrepac
                      </button>
                      <button
                        className="btn btn-sm btn-warning badge-pill ml-2"
                        onClick={() => setRemarks(remarks + " 訂金")}
                      >
                        訂金
                      </button>
                      <textarea
                        onChange={(e) => setRemarks(e.target.value)}
                        value={remarks}
                        placeholder="內部專用備註"
                        style={{ width: "100%", height: 200 }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>圄片</td>
                    <td>
                      {files.map((file) => (
                        <ClickableImage
                          key={file.path}
                          src={`https://api2.otopac.com.hk/files/${file.path}`}
                        />
                      ))}
                    </td>
                  </tr>
                </table>
              </div>
              {order_number && <Files order_number={order_number} />}
            </div>
            <div className="col-4">
              <table className="table table-bordered border-light">
                <tbody>
                <tr>
                  <td colSpan={2}>
                    <div className="d-flex justify-content-between">
                      <span>入電腦日期:</span>
                      <div className="font-weight-bold">
                        {moment(initiated_at).format("YYYY-MM-DD hh:mm a")}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div className="d-flex justify-content-between">
                      <span>最後修改日期:</span>
                      <div className="font-weight-bold">
                        {moment(created_at).format("YYYY-MM-DD hh:mm a")}
                      </div>
                    </div>
                  </td>
                </tr>
                  <tr>
                    <td colSpan={2}>
                      <div className="d-flex justify-content-between">
                        <span>發票號碼:</span>
                        <div className="font-weight-bold">{order_number}</div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <div className="d-flex justify-content-between">
                        <span>紀錄號碼:</span>
                        <input
                          value={payment.reference_number}
                          onChange={(e) =>
                            setPayment({
                              ...payment,
                              reference_number: e.target.value,
                            })
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <div className="d-flex">
                        <span>維修時間:★</span>
                        <DateInput
                          time={true}
                          className="input-simple pl-5"
                          onChange={setServiceAt}
                          value={service_at}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <div className="d-flex justify-content-between">
                        <span>銷售員:★</span>
                        <SelectInput
                          items={data.users}
                          onChange={setSalesId}
                          value={sales_id}
                          title="nick_name"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <div className="d-flex justify-content-between">
                        <span>維修員:★</span>
                        <MultiSelectInput
                          items={data.users}
                          onChange={setServicersIds}
                          value={servicer_ids}
                          title="nick_name"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <div className="d-flex justify-content-between">
                        <span>電腦輸入員:</span>
                        <SelectInput
                          items={data.users}
                          onChange={setCreatorId}
                          value={creator_id}
                          title="nick_name"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <div className="d-flex justify-content-between">
                        <span>開單員:</span>
                        <SelectInput
                          items={data.users}
                          onChange={setInvoiceCreatorId}
                          value={invoice_creator_id}
                          title="nick_name"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-bordered border-light">
                <tbody>
                  <tr>
                    <td>
                      <div className="d-flex justify-content-between">
                        <span>顧客姓名:</span>
                        <div>
                          <div>
                            <input
                              type="checkbox"
                              onChange={() => {
                                setCustomerId(0);
                                setContact("");
                                setIsNewCustomer(false);
                              }}
                              value={customer_id === 0}
                              checked={customer_id === 0}
                              id="customer_na"
                            />
                            <label htmlFor="customer_na">無留資料</label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              onChange={() => {
                                setCustomerId(null);
                                setContact("");
                                setIsNewCustomer(!is_new_customer);
                              }}
                              value={is_new_customer}
                              checked={is_new_customer}
                              id="customer_name"
                            />
                            <label htmlFor="customer_name">新顧客</label>
                          </div>
                        </div>
                        {is_new_customer && (
                          <input
                            onChange={(e) => setCustomerName(e.target.value)}
                            value={customer_name}
                          />
                        )}
                        {!is_new_customer && (
                          <SelectInput
                            items={data.clients}
                            value={customer_id}
                            onChange={setCustomerId}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {customer_id !== 0 && (
                        <div className="d-flex justify-content-between">
                          <span>聯絡電話:★</span>
                          <input
                            onChange={(e) => setContact(e.target.value)}
                            value={contact}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex justify-content-between">
                        <span>車牌號碼:</span>
                        <input
                          onChange={(e) => setCarPlate(e.target.value)}
                          value={car_plate}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex justify-content-between">
                        <span>車輛型號:</span>
                        <input
                          onChange={(e) => setCarBrand(e.target.value)}
                          value={car_brand}
                        />
                        <input
                          onChange={(e) => setCarModel(e.target.value)}
                          value={car_model}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>轉介:</div>
                      <div className="d-flex justify-content-between">
                        <div className="form-check form-check-inline ml-4">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setReferralType(referral_type === 1 ? null : 1)
                            }
                            className="form-check-input"
                            value={referral_type === 1}
                            checked={referral_type === 1}
                            id={"referral_type-1"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={"referral_type-1"}
                          >
                            公司
                          </label>
                        </div>
                        <div className="form-check form-check-inline ml-4">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setReferralType(referral_type === 2 ? null : 2)
                            }
                            className="form-check-input"
                            value={referral_type === 2}
                            checked={referral_type === 2}
                            id={"referral_type-2"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={"referral_type-2"}
                          >
                            車房
                          </label>
                        </div>
                        <div className="form-check form-check-inline ml-4">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setReferralType(referral_type === 3 ? null : 3)
                            }
                            className="form-check-input"
                            value={referral_type === 3}
                            checked={referral_type === 3}
                            id={"referral_type-3"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={"referral_type-3"}
                          >
                            拖車
                          </label>
                        </div>
                        <div className="form-check form-check-inline ml-4">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setReferralType(referral_type === 4 ? null : 4)
                            }
                            className="form-check-input"
                            value={referral_type === 4}
                            checked={referral_type === 4}
                            id={"referral_type-4"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={"referral_type-4"}
                          >
                            顧客
                          </label>
                        </div>
                        <SelectInput
                          items={referrals}
                          value={referral_id}
                          onChange={changeReferralId}
                        />
                      </div>
                      <textarea readOnly={true} value={referral} />
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-bordered border-light">
                <tbody>
                  <tr>
                    <td>輪胎品牌</td>
                    <td>花紋</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex justify-content-between">
                        <span>前左</span>
                        <div>
                          <input
                            onChange={(e) => setCarTyreFL(e.target.value)}
                            placeholder="2254018..."
                            style={{ width: 100 }}
                            value={car_tyre_fl}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex justify-content-between">
                        <span>花紋深度</span>
                        <div>
                          <input
                            onChange={(e) => setCarTyreFLP(e.target.value)}
                            placeholder="90"
                            style={{ width: 100 }}
                            value={car_tyre_flp}
                          />
                        </div>
                        <span>%</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex justify-content-between">
                        <span>前右</span>
                        <div>
                          <input
                            onChange={(e) => setCarTyreFR(e.target.value)}
                            placeholder="2254018..."
                            style={{ width: 100 }}
                            value={car_tyre_fr}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex justify-content-between">
                        <span>花紋深度</span>
                        <div>
                          <input
                            onChange={(e) => setCarTyreFRP(e.target.value)}
                            placeholder="90"
                            style={{ width: 100 }}
                            value={car_tyre_frp}
                          />
                        </div>
                        <span>%</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex justify-content-between">
                        <span>後左</span>
                        <div>
                          <input
                            onChange={(e) => setCarTyreRL(e.target.value)}
                            placeholder="2553518..."
                            style={{ width: 100 }}
                            value={car_tyre_rl}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex justify-content-between">
                        <span>花紋深度</span>
                        <div>
                          <input
                            onChange={(e) => setCarTyreRLP(e.target.value)}
                            placeholder="90"
                            style={{ width: 100 }}
                            value={car_tyre_rlp}
                          />
                        </div>
                        <span>%</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex justify-content-between">
                        <span>後右</span>
                        <div>
                          <input
                            onChange={(e) => setCarTyreRR(e.target.value)}
                            placeholder="2553518..."
                            style={{ width: 100 }}
                            value={car_tyre_rr}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex justify-content-between">
                        <span>花紋深度</span>
                        <div>
                          <input
                            onChange={(e) => setCarTyreRRP(e.target.value)}
                            placeholder="90"
                            style={{ width: 100 }}
                            value={car_tyre_rrp}
                          />
                        </div>
                        <span>%</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <span>建議:</span>
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="form-check form-check-inline ml-4">
                            <input
                              type="checkbox"
                              onClick={() =>
                                setMessageType(message_type === 1 ? null : 1)
                              }
                              className="form-check-input"
                              value={message_type === 1}
                              checked={message_type === 1}
                              id={"message-type-1"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={"message-type-1"}
                            >
                              更換輪胎
                            </label>
                          </div>
                          <div className="form-check form-check-inline ml-4">
                            <input
                              type="checkbox"
                              onClick={() =>
                                setMessageType(message_type === 2 ? null : 2)
                              }
                              className="form-check-input"
                              value={message_type === 2}
                              checked={message_type === 2}
                              id={"message-type-2"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={"message-type-2"}
                            >
                              調呔/戥呔
                            </label>
                          </div>
                          <div className="form-check form-check-inline ml-4">
                            <input
                              type="checkbox"
                              onClick={() =>
                                setMessageType(message_type === 3 ? null : 3)
                              }
                              className="form-check-input"
                              value={message_type === 3}
                              checked={message_type === 3}
                              id={"message-type-3"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={"message-type-3"}
                            >
                              四輪定位
                            </label>
                          </div>
                          <div className="form-check form-check-inline ml-4">
                            <input
                              type="checkbox"
                              onClick={() =>
                                setMessageType(message_type === 4 ? null : 4)
                              }
                              className="form-check-input"
                              value={message_type === 4}
                              checked={message_type === 4}
                              id={"message-type-3"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={"message-type-3"}
                            >
                              年檢
                            </label>
                          </div>
                        </div>
                        <textarea
                          onChange={(e) => setRecommendation(e.target.value)}
                          value={recommendation}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <div className="d-flex justify-content-between">
                        <span>下次免費檢查日期:</span>
                        <DateInput
                          onChange={setReserviceDate}
                          time={false}
                          value={reservice_date}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Payment({ payment, setPayment, total }) {
  const dispatch = useDispatch();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const list = [1, 2, 3, 21, 22];
  useEffect(() => {
    const getData = async () => {
      const paymentMethods = await dispatch(get("/payment_methods"));
      setPaymentMethods(paymentMethods);
    };
    getData();
  }, []);
  const otherPayments = useMemo(() => {
    return paymentMethods.filter(({ id }) => !list.find((i) => i === +id));
  }, [paymentMethods]);
  if (payment.invoice_number) {
    return (
      <tr>
        <td colSpan={5}>
          <span className="font-weight-bold">收款帳號碼</span>
          <Link
            className="ml-3 btn-link"
            to={"/receivable/item/" + payment.invoice_number}
          >
            {payment.invoice_number}
          </Link>
        </td>
      </tr>
    );
  }
  return (
    <>
      <tr>
        <td colSpan={2}>來訂: {payment.paid}</td>
        <td colSpan={2}>尚欠: {total - payment.paid}</td>
        <td colSpan={1} className="font-weight-bold">
          合計: {total}
        </td>
      </tr>
      <tr>
        <td colSpan={5}>
          <div className="d-flex">
            <div>付款方式</div>
            <div className="form-check form-check-inline ml-4">
              <input
                type="checkbox"
                onClick={() => setPayment({ ...payment, payment_method: 1 })}
                className="form-check-input"
                value={payment.payment_method === 1}
                checked={payment.payment_method === 1}
                id={"checkbox-payment-1"}
              />
              <label
                className="form-check-label"
                htmlFor={"checkbox-payment-1"}
              >
                即時
              </label>
            </div>
            <div className="form-check form-check-inline ml-4">
              <input
                type="checkbox"
                onClick={() => setPayment({ ...payment, payment_method: 2 })}
                className="form-check-input"
                value={payment.payment_method === 2}
                checked={payment.payment_method === 2}
                id={"checkbox-payment-2"}
              />
              <label
                className="form-check-label"
                htmlFor={"checkbox-payment-2"}
              >
                後續
              </label>
            </div>
          </div>
          <div>
            {payment.payment_method === 1 && (
              <div className="d-inline-block">
                {paymentMethods.length > 0 &&
                  list.map((id) => {
                    const item = paymentMethods.find((m) => m.id === id);
                    const checked = item.id === payment.payment_method_id;
                    const _payment = { ...payment, payment_method_id: item.id };
                    return (
                      <div
                        className="form-check form-check-inline ml-4"
                        key={item.id}
                      >
                        <input
                          type="checkbox"
                          onClick={() => setPayment(_payment)}
                          className="form-check-input"
                          value={!!checked}
                          checked={!!checked}
                          id={"checkbox-" + item.id}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={"checkbox-" + item.id}
                        >
                          {item.title}
                        </label>
                      </div>
                    );
                  })}
                <select
                  className="ml-2"
                  value={payment.payment_method_id}
                  onChange={(e) =>
                    setPayment({
                      ...payment,
                      payment_method_id: +e.target.value,
                    })
                  }
                >
                  <option>其他付款方式</option>
                  {otherPayments.map((s) => (
                    <option key={s.id} value={s.id}>
                      {s.title}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </td>
      </tr>
    </>
  );
}

function Gifts({ items, accessories, setAccessories }) {
  const [gift, setGift] = useState(null);
  const dispatch = useDispatch();
  const _items = useMemo(() => {
    return items.filter((i) => i.title.includes("贈"));
  }, []);

  useEffect(() => {
    if (gift) {
      const _accessories = accessories.filter(
        ({ product_id }) => product_id !== gift.product_id
      );
      setAccessories([
        ..._accessories,
        { ...gift, net_price: gift.price, quantity: 1 },
      ]);
      setGift(null);
    }
  }, [gift]);

  const select = async (s) => {
    const acc = accessories.filter((acc) => acc.product_id !== s.product_id);
    if (acc.length < accessories.length) {
      setAccessories(acc);
    } else {
      let trackers = await dispatch(
        get("/stocks", { product_id: s.product_id })
      );
      if (trackers) {
        trackers = trackers.filter(({ quantity }) => +quantity > 0);
        if (trackers.length > 0) {
          setGift(trackers[0]);
        }
      }
    }
  };

  return (
    <div>
      {_items.map((item) => {
        const checked = accessories.find(
          (s) => s.product_id === item.product_id
        );
        return (
          <div
            className="form-check form-check-inline ml-4"
            key={item.product_id}
          >
            <input
              type="checkbox"
              className="form-check-input"
              onClick={() => select(item)}
              value={!!checked}
              checked={!!checked}
              id={"checkbox-" + item.product_id}
            />
            <label
              className="form-check-label"
              htmlFor={"checkbox-" + item.product_id}
            >
              {item.title}
            </label>
          </div>
        );
      })}
    </div>
  );
}
function TyreItem({
  tyreFront,
  tyreRear,
  setTyreFront,
  setTyreRear,
  setCarTyreFL,
  setCarTyreFR,
  setCarTyreRR,
  setCarTyreRL,
}) {
  const [front, setFront] = useState(null);
  const [rear, setRear] = useState(null);
  useEffect(() => {
    if (front) {
      const _tyreFront = tyreFront.filter(
        (t) => t.tracking_id !== front.tracking_id
      );
      setTyreFront([..._tyreFront, { ...front, net_price: front.price }]);
      setCarTyreFL(front.size);
      setCarTyreFR(front.size);
      setFront(null);
    }
  }, [front]);
  useEffect(() => {
    if (rear) {
      const _tyreRear = tyreRear.filter(
        (t) => t.tracking_id !== rear.tracking_id
      );
      setTyreRear([
        ..._tyreRear,
        { ...rear, net_price: rear.price, rear: true },
      ]);
      setCarTyreRR(rear.size);
      setCarTyreRL(rear.size);
      setRear(null);
    }
  }, [rear]);
  return (
    <>
      <tr>
        <th>輪胎 前</th>
        <td>
          {tyreFront.length > 0 && (
            <div className="ml-2 d-inline-block">
              {tyreFront.map((t) => (
                <div className="ml-2" key={t.tracking_id}>
                  <div className="special-color font-weight-bold">
                    {t.title}
                  </div>
                  <div className="special-color">
                    追蹤號碼:{t.tracking_id} 備註:{t.remarks}
                  </div>
                </div>
              ))}
            </div>
          )}
          <TrackingCodeInput category_id={2} item={front} update={setFront} />
        </td>
        <td>
          {tyreFront.map((t) => (
            <Quantity
              key={t.tracking_id}
              quantity={t.quantity}
              update={(quantity) => {
                if (quantity > 0) {
                  setTyreFront(
                    tyreFront.map((tyre) => {
                      if (t.tracking_id === tyre.tracking_id) {
                        return { ...tyre, quantity };
                      }
                      return tyre;
                    })
                  );
                } else {
                  setTyreFront(
                    tyreFront.filter(
                      (tyre) => t.tracking_id !== tyre.tracking_id
                    )
                  );
                }
              }}
            />
          ))}
        </td>
        <td>
          {tyreFront.map((t) => (
            <div className="ml-2" key={t.tracking_id}>
              <input
                value={t.net_price}
                style={{ width: 100 }}
                onChange={(e) =>
                  setTyreFront(
                    tyreFront.map((tyre) => {
                      if (t.tracking_id === tyre.tracking_id) {
                        return { ...tyre, net_price: e.target.value };
                      }
                      return tyre;
                    })
                  )
                }
              />
            </div>
          ))}
        </td>
        <td>
          {tyreFront.map((t) => (
            <div className="ml-2" key={t.tracking_id}>
              {(+t.net_price * +t.quantity).toFixed(0).toLocaleString()}
            </div>
          ))}
        </td>
      </tr>
      <tr>
        <th>輪胎 後</th>
        <td>
          {tyreRear.length > 0 && (
            <div className="ml-2 d-inline-block">
              {tyreRear.map((t) => (
                <div className="ml-2" key={t.tracking_id}>
                  <div className="special-color font-weight-bold">
                    {t.title}
                  </div>
                  <div className="special-color">
                    追蹤號碼:{t.tracking_id} 備註:{t.remarks}
                  </div>
                </div>
              ))}
            </div>
          )}
          <TrackingCodeInput category_id={2} item={rear} update={setRear} />
        </td>
        <td>
          {tyreRear.map((t) => (
            <Quantity
              key={t.tracking_id}
              quantity={t.quantity}
              update={(quantity) => {
                if (quantity > 0) {
                  setTyreRear(
                    tyreRear.map((tyre) => {
                      if (t.tracking_id === tyre.tracking_id) {
                        return { ...tyre, quantity };
                      }
                      return tyre;
                    })
                  );
                } else {
                  setTyreRear(
                    tyreRear.filter(
                      (tyre) => t.tracking_id !== tyre.tracking_id
                    )
                  );
                }
              }}
            />
          ))}
        </td>
        <td>
          {tyreRear.map((t) => (
            <div className="ml-2" key={t.tracking_id}>
              <input
                value={t.net_price}
                style={{ width: 100 }}
                onChange={(e) =>
                  setTyreRear(
                    tyreRear.map((tyre) => {
                      if (t.tracking_id === tyre.tracking_id) {
                        return { ...tyre, net_price: e.target.value };
                      }
                      return tyre;
                    })
                  )
                }
              />
            </div>
          ))}
        </td>
        <td>
          {tyreRear.map((t) => (
            <div className="ml-2" key={t.tracking_id}>
              {(+t.net_price * +t.quantity).toFixed(0).toLocaleString()}
            </div>
          ))}
        </td>
      </tr>
    </>
  );
}

function BatteryItem({ batteries, setBatteries }) {
  const [battery, setBattery] = useState(null);
  useEffect(() => {
    if (battery) {
      const _batteries = batteries.filter(
        ({ tracking_id }) => tracking_id !== battery.tracking_id
      );
      setBatteries([..._batteries, { ...battery, net_price: battery.price }]);
      setBattery(null);
    }
  }, [battery]);
  return (
    <tr>
      <th>電池</th>
      <td>
        {batteries.length > 0 && (
          <div className="ml-2 d-inline-block">
            {batteries.map((t) => (
              <div className="ml-2" key={t.tracking_id}>
                <div className="special-color font-weight-bold">{t.title}</div>
                <div className="special-color">
                  追蹤號碼:{t.tracking_id} 備註:{t.remarks}
                </div>
              </div>
            ))}
          </div>
        )}
        <TrackingCodeInput category_id={3} item={battery} update={setBattery} />
      </td>
      <td>
        {batteries.map((t) => (
          <Quantity
            key={t.tracking_id}
            quantity={t.quantity}
            update={(quantity) => {
              if (quantity > 0) {
                setBatteries(
                  batteries.map((battery) => {
                    if (t.tracking_id === battery.tracking_id) {
                      return { ...battery, quantity };
                    }
                    return battery;
                  })
                );
              } else {
                setBatteries(
                  batteries.filter(
                    (battery) => t.tracking_id !== battery.tracking_id
                  )
                );
              }
            }}
          />
        ))}
      </td>
      <td>
        {batteries.map((t) => (
          <div className="ml-2" key={t.tracking_id}>
            <input
              value={t.net_price}
              style={{ width: 100 }}
              onChange={(e) =>
                setBatteries(
                  batteries.map((battery) => {
                    if (t.tracking_id === battery.tracking_id) {
                      return { ...battery, net_price: e.target.value };
                    }
                    return battery;
                  })
                )
              }
            />
          </div>
        ))}
      </td>
      <td>
        {batteries.map((t) => (
          <div className="ml-2" key={t.tracking_id}>
            {(+t.net_price * +t.quantity).toFixed(0).toLocaleString()}
          </div>
        ))}
      </td>
    </tr>
  );
}

function WheelItem({ wheels, setWheels }) {
  const [wheel, setWheel] = useState(null);
  useEffect(() => {
    if (wheel) {
      const _wheels = wheels.filter(
        ({ tracking_id }) => tracking_id !== wheel.tracking_id
      );
      setWheels([..._wheels, { ...wheel, net_price: wheel.price }]);
      setWheel(null);
    }
  }, [wheel]);
  return (
    <tr>
      <th>合金鈴</th>
      <td>
        {wheels.length > 0 && (
          <div className="ml-2 d-inline-block">
            {wheels.map((t) => (
              <div className="ml-2" key={t.tracking_id}>
                <div className="special-color font-weight-bold">{t.title}</div>
                <div className="special-color">
                  追蹤號碼:{t.tracking_id} 備註:{t.remarks}
                </div>
              </div>
            ))}
          </div>
        )}
        <TrackingCodeInput category_id={4} item={wheel} update={setWheel} />
      </td>
      <td>
        {wheels.map((t) => (
          <Quantity
            key={t.tracking_id}
            quantity={t.quantity}
            update={(quantity) => {
              if (quantity > 0) {
                setWheels(
                  wheels.map((wheel) => {
                    if (t.tracking_id === wheel.tracking_id) {
                      return { ...wheel, quantity };
                    }
                    return wheel;
                  })
                );
              } else {
                setWheels(
                  wheels.filter((wheel) => t.tracking_id !== wheel.tracking_id)
                );
              }
            }}
          />
        ))}
      </td>
      <td>
        {wheels.map((t) => (
          <div className="ml-2" key={t.tracking_id}>
            <input
              value={t.net_price}
              style={{ width: 100 }}
              onChange={(e) =>
                setWheels(
                  wheels.map((wheel) => {
                    if (t.tracking_id === wheel.tracking_id) {
                      return { ...wheel, net_price: e.target.value };
                    }
                    return wheel;
                  })
                )
              }
            />
          </div>
        ))}
      </td>
      <td>
        {wheels.map((t) => (
          <div className="ml-2" key={t.tracking_id}>
            {(+t.net_price * +t.quantity).toFixed(0).toLocaleString()}
          </div>
        ))}
      </td>
    </tr>
  );
}

function AccessoryItem({ items, accessories, setAccessories }) {
  const [search, setSearch] = useState("");
  const [accessory, setAccessory] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (accessory) {
      const _accessories = accessories.filter(
        ({ tracking_id }) => tracking_id !== accessory.tracking_id
      );
      setAccessories([
        ..._accessories,
        { ...accessory, net_price: accessory.price, quantity: 1 },
      ]);
      setAccessory(null);
    }
  }, [accessory]);
  const _items = useMemo(() => {
    if (search) {
      return items.filter((i) =>
        i.title.toUpperCase().includes(search.toUpperCase())
      );
    }
    return [];
  }, [items, search]);
  const select = async (s) => {
    setSearch("");
    let trackers = await dispatch(get("/stocks", { product_id: s.product_id }));
    if (trackers) {
      trackers = trackers.filter(({ quantity }) => +quantity > 0);
      if (trackers.length > 0) {
        setAccessory(trackers[0]);
      }
    }
  };

  return (
    <tr>
      <th>其他</th>
      <td>
        {accessories.length > 0 && (
          <div className="ml-2 d-inline-block">
            {accessories.map((t) => (
              <div className="ml-2" key={t.tracking_id}>
                <div className="special-color font-weight-bold">{t.title}</div>
                <div className="special-color">
                  追蹤號碼:{t.tracking_id} 備註:{t.remarks}
                </div>
              </div>
            ))}
          </div>
        )}
        <div className=" ml-2">
          <input
            placeholder="其他精品"
            style={{ minWidth: 300 }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div style={{ position: "relative", zIndex: 100 }}>
            <div
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                right: 0,
                backgroundColor: "#000",
              }}
            >
              {_items.map((s) => (
                <div
                  key={s.product_id}
                  onClick={() => select(s)}
                  className="text-white p-1"
                  style={{ cursor: "pointer" }}
                >
                  {s.title}
                </div>
              ))}
            </div>
          </div>
        </div>
      </td>
      <td>
        {accessories.map((t) => (
          <Quantity
            key={t.tracking_id}
            quantity={t.quantity}
            update={(quantity) => {
              if (quantity > 0) {
                setAccessories(
                  accessories.map((accessory) => {
                    if (t.tracking_id === accessory.tracking_id) {
                      return { ...accessory, quantity };
                    }
                    return accessory;
                  })
                );
              } else {
                setAccessories(
                  accessories.filter(
                    (accessory) => t.tracking_id !== accessory.tracking_id
                  )
                );
              }
            }}
          />
        ))}
      </td>
      <td>
        {accessories.map((t) => (
          <div className="ml-2" key={t.tracking_id}>
            <input
              value={t.net_price}
              style={{ width: 100 }}
              onChange={(e) =>
                setAccessories(
                  accessories.map((wheel) => {
                    if (t.tracking_id === wheel.tracking_id) {
                      return { ...wheel, net_price: e.target.value };
                    }
                    return wheel;
                  })
                )
              }
            />
          </div>
        ))}
      </td>
      <td>
        {accessories.map((t) => (
          <div className="ml-2" key={t.tracking_id}>
            {(+t.net_price * +t.quantity).toFixed(0).toLocaleString()}
          </div>
        ))}
      </td>
    </tr>
  );
}

function WheelAlignment({ services, setServices, items }) {
  const _items = [
    { product_code: "JPWHEELALIGN", title: "日本" },
    { product_code: "EUWHEELALIGN", title: "歐洲" },
    { product_code: "SUVWHEELALIGN", title: "跑車或SUV" },
  ];

  const select = (product_code) => {
    const service = services.find((s) => s.product_code === product_code);
    if (service) {
      const _services = services.filter((s) => s.product_code !== product_code);
      setServices(_services);
    } else {
      const i = items.find((i) => i.product_code === product_code);
      if (i) {
        const _services = services.filter(
          ({ product_code }) =>
            !_items.find((i) => i.product_code === product_code)
        );
        setServices([
          ..._services,
          {
            product_code: i.product_code,
            title: i.title,
            quantity: 1,
            price: i.price,
            net_price: i.price,
            product_id: i.id,
          },
        ]);
      }
    }
  };
  const service = useMemo(() => {
    return services.find((s) =>
      _items.find(({ product_code }) => product_code === s.product_code)
    );
  }, [services]);

  return (
    <tr>
      <th>四輪定位</th>
      <td>
        <div className="px-3">
          {_items.map((item) => {
            const checked = services.find(
              (s) => s.product_code === item.product_code
            );
            return (
              <div
                className="form-check form-check-inline ml-4"
                key={item.product_code}
              >
                <input
                  type="checkbox"
                  onClick={() => select(item.product_code)}
                  className="form-check-input"
                  value={!!checked}
                  checked={!!checked}
                  id={"checkbox-" + item.product_code}
                />
                <label
                  className="form-check-label"
                  htmlFor={"checkbox-" + item.product_code}
                >
                  {item.title}
                </label>
              </div>
            );
          })}
        </div>
      </td>
      <td>
        {service && (
          <Quantity
            quantity={service.quantity}
            update={(quantity) => {
              if (quantity > 0) {
                setServices(
                  services.map((_service) => {
                    if (service.product_code === _service.product_code) {
                      return { ..._service, quantity };
                    }
                    return _service;
                  })
                );
              } else {
                setServices(
                  services.filter(
                    (_service) => service.product_code !== _service.product_code
                  )
                );
              }
            }}
          />
        )}
      </td>
      <td>
        {service && (
          <div className="ml-2">
            <input
              value={service.net_price}
              style={{ width: 100 }}
              onChange={(e) =>
                setServices(
                  services.map((_service) => {
                    if (service.product_code === _service.product_code) {
                      return { ..._service, net_price: e.target.value };
                    }
                    return _service;
                  })
                )
              }
            />
          </div>
        )}
      </td>
      <td>
        {service && (
          <div className="ml-2">
            {(+service.net_price * +service.quantity)
              .toFixed(0)
              .toLocaleString()}
          </div>
        )}
      </td>
    </tr>
  );
}

function OtherServices({ services, setServices, items }) {
  const [search, setSearch] = useState("");
  const _items = [
    { product_code: "REPAIRINNERTYRE", title: "內補呔" },
    { product_code: "REPAIRTYRE", title: "補呔" },
    { product_code: "MO_RIM", title: "磨鈴" },
    { product_code: "SWAPTYRE", title: "調呔" },
  ];
  const select = (product_code) => {
    const service = services.find((s) => s.product_code === product_code);
    if (service) {
      const _services = services.filter((s) => s.product_code !== product_code);
      setServices(_services);
    } else {
      const i = items.find((i) => i.product_code === product_code);
      if (i) {
        setServices([
          ...services,
          {
            product_code: i.product_code,
            title: i.title,
            quantity: 1,
            price: i.price,
            net_price: i.price,
            product_id: i.id,
          },
        ]);
      }
    }
  };

  const selectOtherItem = (item) => {
    setSearch("");
    const service = services.find((s) => s.product_code === item.product_code);
    if (service) {
      const _services = services.filter(
        (s) => s.product_code !== item.product_code
      );
      setServices(_services);
    } else {
      setServices([
        ...services,
        {
          product_code: item.product_code,
          title: item.title,
          quantity: 1,
          price: item.price,
          net_price: item.price,
          product_id: item.id,
        },
      ]);
    }
  };
  const otherItems = useMemo(() => {
    if (search) {
      return items.filter(
        ({ product_code, title }) =>
          !_items.find((i) => i.product_code === product_code) &&
          title.toUpperCase().includes(search.toUpperCase())
      );
    } else {
      return [];
    }
  }, [items, search]);

  return (
    <>
      <tr>
        <td colSpan={5}>
          <div className="px-1">
            {_items.map((item) => {
              const checked = services.find(
                (s) => s.product_code === item.product_code
              );
              return (
                <div
                  className="form-check form-check-inline ml-4"
                  key={item.product_code}
                >
                  <input
                    type="checkbox"
                    onClick={() => select(item.product_code)}
                    className="form-check-input"
                    value={!!checked}
                    checked={!!checked}
                    id={"checkbox-" + item.product_code}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={"checkbox-" + item.product_code}
                  >
                    {item.title}
                  </label>
                </div>
              );
            })}
            <div className="d-inline-block ml-3" style={{ width: 300 }}>
              <input
                placeholder={"其他服務"}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ minWidth: 300 }}
              />
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    right: 0,
                    backgroundColor: "#000",
                  }}
                >
                  {otherItems.slice(0, 10).map((s) => (
                    <div
                      key={s.product_code}
                      onClick={() => selectOtherItem(s)}
                      className="text-white p-1"
                      style={{ cursor: "pointer" }}
                    >
                      {s.title}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
      {services.map((s, i) => {
        return (
          <tr key={s.product_code}>
            {i === 0 && <td rowSpan={services.length}>服務</td>}
            <td>
              <div className="special-color font-weight-bold">{s.title}</div>
            </td>
            <td>
              <Quantity
                quantity={s.quantity}
                update={(quantity) => {
                  if (quantity > 0) {
                    setServices(
                      services.map((service) => {
                        if (s.product_code === service.product_code) {
                          return { ...service, quantity };
                        }
                        return service;
                      })
                    );
                  } else {
                    setServices(
                      services.filter(
                        (service) => service.product_code !== s.product_code
                      )
                    );
                  }
                }}
              />
            </td>
            <td>
              <div className="ml-2">
                <input
                  value={s.net_price}
                  style={{ width: 100 }}
                  onChange={(e) =>
                    setServices(
                      services.map((service) => {
                        if (s.product_code === service.product_code) {
                          return { ...service, net_price: e.target.value };
                        }
                        return service;
                      })
                    )
                  }
                />
              </div>
            </td>
            <td>
              <div className="ml-2" key={s.product_code}>
                {(+s.net_price * +s.quantity).toFixed(0).toLocaleString()}
              </div>
            </td>
          </tr>
        );
      })}
    </>
  );
}

function Quantity({ quantity, update }) {
  if (quantity === null) {
    return;
  }
  return (
    <div className="d-flex align-items-center justify-content-center">
      <button onClick={() => update(Math.ceil(quantity - 1, 0))}> - </button>
      <span className="font-weight-bold mx-2">{quantity}</span>
      <button onClick={() => update(quantity + 1)}> + </button>
      <button onClick={() => update(0)}>
        <FontAwesomeIcon icon="trash" fixedWidth={true} />
      </button>
    </div>
  );
}

function TrackingCodeInput({ category_id, update, item }) {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [product_id, setProductId] = useState(null);
  const [trackers, setTrackers] = useState([]);
  const [text, setText] = useState("");
  const [readMode, setReadMode] = useState(false);
  const placeholder = useMemo(() => {
    switch (category_id) {
      case 2:
        return "輸入尺寸 2254517";
      case 3:
        return "輸入尺寸 80D23L..";
      case 4:
        return "輸入尺寸 SA20R...";
      case 5:
        return "輸入產品 AQ...";
    }
  }, [category_id]);
  useEffect(() => {
    const getData = async () => {
      const products = await dispatch(get("/stocks/products", { category_id }));
      setProducts(products);
    };
    getData();
  }, [category_id]);

  useEffect(() => {
    const getData = async (product_id) => {
      let trackers = await dispatch(get("/stocks", { product_id }));
      if (trackers) {
        trackers = trackers.filter(({ quantity }) => +quantity > 0);
        setTrackers(trackers);
      }
    };
    if (product_id) {
      getData(product_id);
    } else {
      setTrackers([]);
    }
  }, [product_id]);

  const selections = useMemo(() => {
    return products.filter((p) => {
      if (text.length > 0) {
        // return p.title.replace(/[\/\\R]/g, "").includes(text) || p.product_code.includes(text.toUpperCase());
        return p.title.replace(/[\/\\R]/g, "").includes(text);
      }
    });
  }, [products, text]);

  const select = (s) => {
    setText(s.title);
    setProductId(s.product_id);
  };

  const _update = (t) => {
    setTrackers([]);
    update({ ...t, quantity: +t.quantity });
    setText("");
    setProductId(null);
  };

  if (readMode) {
    return (
      <div className="ml-2 d-inline-block">
        <div className="font-weight-bold">{item.title}</div>
        <div>
          追蹤號碼:{item.tracking_id} 備註:{item.remarks}
        </div>
      </div>
    );
  }
  return (
    <div className=" ml-2">
      <input
        placeholder={placeholder}
        style={{ minWidth: 300 }}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <div style={{ position: "relative", zIndex: 100 }}>
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            backgroundColor: "#000",
          }}
        >
          {!product_id &&
            selections.map((s) => (
              <div
                key={s.product_id}
                onClick={() => select(s)}
                className="text-white p-1"
                style={{ cursor: "pointer" }}
              >
                <b>{s.size}</b> | {s.title}
              </div>
            ))}
        </div>
      </div>
      {trackers.map((t) => (
        <button
          key={t.tracking_id}
          onClick={() => _update(t)}
          className="btn badge-pill btn-sm mt-2"
        >
          TI:{t.tracking_id} 備註{t.remarks} {t.quantity}條
        </button>
      ))}
    </div>
  );
}

function CarImage({ service_at, value, onChange }) {
  const [cars, setCars] = useState([]);
  const dispatch = useDispatch();
  const selectedStyle = {
    border: "2px solid red",
  };
  useEffect(() => {
    async function getData(start_date, end_date) {
      const items = await dispatch(get("/cars", { start_date, end_date }));
      setCars(items);
    }
    if (service_at) {
      const start_date = moment(service_at).startOf("day");
      const end_date = moment(service_at).endOf("day");
      getData(start_date, end_date);
    }
  }, [service_at]);
  return (
    <div className="" style={{ overflowX: "" }}>
      {cars.map((image) => (
        <div
          key={image.object_id}
          className="d-inline-block"
          style={{ position: "relative" }}
        >
          <ClickableImage
            image={image}
            style={value === image.object_id ? selectedStyle : {}}
          />
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              padding: 10,
              cursor: "pointer",
            }}
            onClick={() =>
              onChange(
                value === image.object_id ? null : image.object_id,
                value === image.object_id ? null : image.entered_at
              )
            }
          >
            <FontAwesomeIcon
              icon="plus"
              fixedWidth={true}
              color="#fff"
              size="lg"
            />
          </div>
        </div>
      ))}
    </div>
  );
}

class Files extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      // images:[],
    };
    this.getData = this.getData.bind(this);
    this.uploaded = this.uploaded.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    try {
      let { get, order_number } = this.props;
      let items = await get(`/sales/${order_number}/files`);
      this.setState({ items });
      // let images = await get(`/sales/${order_number}/images`);
      // this.setState({ images });
    } catch (e) {
      console.error(e);
    }
  }

  async uploaded(items) {
    let { post, order_number } = this.props;
    try {
      let _items = this.state.items.concat(items);
      post(`/sales/${order_number}/files`, _items);
      this.setState({ items: _items });
    } catch (e) {
      console.error(e);
    }
  }

  // async uploadImage(items) {
  //   let { post, order_number } = this.props;
  //   try {
  //     let _images = this.state.images.concat(items);
  //     post(`/sales/${order_number}/images`, _images);
  //     this.setState({ images: _images });
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }

  openFile(url) {
    let mywindow = window.open(url, "FILE", "height=800,width=600");
    mywindow.focus(); // necessary for IE >= 10
  }

  render() {
    const style = {
      border: "1px solid #000",
      paddingLeft: "5px",
    };
    const { items } = this.state;
    return (<div>

          <div className="mt-3" style={{ background: "#fff" }}>
            <div className="font-weight-bold">Support檔案</div>
            <FileUpload uploaded={this.uploaded} />
            <div className="row no-gutters">
              <div className="col-1 font-weight-bold" style={style}>
                ID
              </div>
              <div className="col-4 font-weight-bold" style={style}>
                上載者
              </div>
              <div className="col-4 font-weight-bold" style={style}>
                上載日期
              </div>
              <div className="col-3 font-weight-bold" style={style}></div>
              {items.map((item) => {
                return (
                    <React.Fragment key={item.id}>
                      <div className="col-1" style={style}>
                        {item.id}
                      </div>
                      <div className="col-4" style={style}>
                        {item.nick_name}
                      </div>
                      <div className="col-4" style={style}>
                        <Date date={item.created_at} time={true} />
                      </div>
                      <div className="col-3" style={style}>
                        <button
                            className="btn btn-sm btn-primary"
                            onClick={() =>
                                this.openFile(
                                    `https://api2.otopac.com.hk/files/${item.path}`
                                )
                            }
                        >
                          打開
                        </button>
                      </div>
                    </React.Fragment>
                );
              })}
            </div>
          </div>
          {/*<div className="mt-3" style={{ background: "#fff" }}>*/}
          {/*  <div className="font-weight-bold">上傳相片</div>*/}
          {/*  <FileUpload uploaded={this.uploadImage} />*/}
          {/*  <div className="row no-gutters">*/}
          {/*    <div className="col-1 font-weight-bold" style={style}>*/}
          {/*      ID*/}
          {/*    </div>*/}
          {/*    <div className="col-4 font-weight-bold" style={style}>*/}
          {/*      上載者*/}
          {/*    </div>*/}
          {/*    <div className="col-4 font-weight-bold" style={style}>*/}
          {/*      上載日期*/}
          {/*    </div>*/}
          {/*    <div className="col-3 font-weight-bold" style={style}></div>*/}
          {/*    {images.map((image) => {*/}
          {/*      return (*/}
          {/*          <React.Fragment key={image.id}>*/}
          {/*            <div className="col-1" style={style}>*/}
          {/*              {image.id}*/}
          {/*            </div>*/}
          {/*            <div className="col-4" style={style}>*/}
          {/*              {image.nick_name}*/}
          {/*            </div>*/}
          {/*            <div className="col-4" style={style}>*/}
          {/*              <Date date={image.created_at} time={true} />*/}
          {/*            </div>*/}
          {/*            <div className="col-3" style={style}>*/}
          {/*              <button*/}
          {/*                  className="btn btn-sm btn-primary"*/}
          {/*                  onClick={() =>*/}
          {/*                      this.openFile(*/}
          {/*                          `https://api2.otopac.com.hk/files/${image.path}`*/}
          {/*                      )*/}
          {/*                  }*/}
          {/*              >*/}
          {/*                打開*/}
          {/*              </button>*/}
          {/*            </div>*/}
          {/*          </React.Fragment>*/}
          {/*      );*/}
          {/*    })}*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
    );
  }
}

Files = connect(
  () => {
    return {};
  },
  { get, post }
)(Files);
export default connect(
  (state) => {
    return {
      user: state.core.user,
      data: state.sale.data,
      item: state.sale.item,
    };
  },
  { get, getItem, getFormData, submit, post }
)(SalesItem);
