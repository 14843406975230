import {get, post} from '../axios';
const GET_USERS = 'GET_USERS';
const initialState= {
    list:[]
};


export default function (state = initialState, action){
    switch (action.type){
        case GET_USERS:
            return Object.assign({}, state, {list: action.data});
        default:
            return state;
    }
}

export function getItems(){
    return async (dispatch)=>{
        let data = await dispatch(get(`/users`));
        return dispatch({type:GET_USERS, data:data})
    }
}