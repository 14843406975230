import React from "react";
import moment from "moment";
import DateInput from "../../common/DateInput";
import Table from "../../common/Table";
import connect from "react-redux/es/connect/connect";
import {get} from "../../../axios";

class Stock extends React.Component{
    constructor(props){
        super(props);
        this.state = {data:[],  value_date:moment().toDate()};
        this.getData = this.getData.bind(this);
    }

    componentDidMount(){
        this.getData();
    }

    async getData(e){
        if(e) e.preventDefault();
        let {value_date} = this.state;
        let data = await this.props.get('/report/stock',  {value_date});
        this.setState({data})
    }


    render(){
        let {data, value_date} = this.state;
        data = data.map((item, index)=>({...item, index}));
        return <div className="p-3">
            <h5 className="font-weight-bold">Stock分析</h5>
            <form onSubmit={this.getData}>
                <h6 className="d-flex mt-3">
                    <span className="mr-2">
                        至到日期
                    </span>
                    <DateInput value={value_date}  onChange={(v)=>this.setState({value_date:v})}/>
                    <button className="mx-2 btn btn-sm btn-primary">確定</button>
                </h6>
            </form>
            <Table data={data}
                   accessors={[
                       {key:'id', title:'ID'},
                       {key:'title', title:'名稱', render:((item) => <div>
                               <div className="font-weight-bold">{item.title}</div>
                               <div>{item.items.map((i, x)=><button className="btn btn-sm mr-1" type="button" key={x}>{i.remarks} x {i.quantity}</button>)}</div>
                           </div>)},
                       {key:'quantity', title:'數量'},
                       {key:'cost', title:'總成本'},
                       {key:'cost', title:'平均成本', render:((item) =><span>{item.cost / item.quantity}</span>)},
                   ]}
                   tableKey='report/stock'
                   rowKey='id'
            />
        </div>
    }
}

export default connect((state)=>{return{}}, {get})(Stock)