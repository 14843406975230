import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
export default function ({onChange, value}){
    return <div>
        <span style={{padding:5, border:"1px solid #aaa", borderRadius:'3px 3px'}} onClick={()=>onChange(!value)}>
            {value ?(
                <FontAwesomeIcon icon='check' fixedWidth={true}/>
            ):(
                <FontAwesomeIcon icon='times' fixedWidth={true}/>
                )
            }
        </span>
    </div>
}