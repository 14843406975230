import React, {useMemo, useEffect, useRef} from "react";
import moment from 'moment';
import {connect} from 'react-redux';

function Cash({profitReport, open, store, close}){
    const container = useRef(null);
    useEffect(()=>{
        if(open){
            let mywindow = window.open('', 'Cash', 'height=800,width=900');
            mywindow.document.write('<html>');
            //adding the current css file in the head
            mywindow.document.write(document.head.innerHTML);
            mywindow.document.write('<body>');
            mywindow.document.write(container.current.innerHTML);
            mywindow.document.write('</body></html>');
            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/
            mywindow.onbeforeunload = close
        }
    },[open])
    if(!profitReport){
        return <div/>
    }
    const {cash} = profitReport;
    let {date} = profitReport;
    const {current, outstanding} = cash;
    return <div className="mt-3 d-none">
        <div ref={container} className="container print-block"
             media="print"
             style={{color:'#252b75ff', backgroundColor:'#fff'}}>
            <div className="p-3">
                <div>
                    <h5 className="font-weight-bold float-left">{store.full_title}</h5>
                    <div className="float-right">{moment().format('YYYY/MM/DD hh:mm')}列印</div>
                    <div className="clearfix"/>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h5 className="font-weight-bold mt-3">{date.format('YYYY年MM月DD日')} {date.format('dddd')} 收款資料</h5>
                        <Report detail={current} outstanding={outstanding} date={date}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

function Report({detail, outstanding,date}){
   let data = Object.keys(detail[0]);
   let sum = {}
   let total = 0;
   detail.forEach((d)=>{
       Object.keys((d)).forEach((k,i)=>{
           if(i===0){
               return
           }
           if(!sum[k]){
               sum[k] = 0;
           }
           sum[k]+= +d[k];
           total += +d[k]
       })
   });
   return <table className="table table-bordered mt-3 border-dark"
                 style={{border:'2px solid #000'}}>
       <thead>
            <tr>
                {data.map((d)=><th key={d} style={{borderBottom:'2px solid #000'}}>{d}</th>)}
                <td>今日銷售</td>

            </tr>
       </thead>
       <tbody>
       {detail.map((d,i)=><tr key={i}>
           {Object.keys(d).map((k,j)=><td key={k} className={j===0?'font-weight-bold':''}>
               {d[k]?d[k].toLocaleString():''}
           </td>)}
       </tr>)}
       <tr>
           {data.map((k,j)=><td key={k} className={j===0?'font-weight-bold':''}>
               {j===0?"Total":sum[k]}
           </td>)}
           <td>
               {total?total.toLocaleString():''}
           </td>
       </tr>
       <tr>
           {data.map((k,j)=><td key={k} className={j===0?'font-weight-bold':''}>
               {j===0?"累積未付":outstanding[k]?outstanding[k].toLocaleString():''}
               <span>{j===data.length-1?`至${date.format('DD/MM/YY')}`:''}</span>
           </td>)}
       </tr>
       </tbody>
   </table>
}

export default connect((state)=>({store:state.core.store}))(Cash);