import React from 'react';
import PropTypes from 'prop-types';

class Image extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }


    render() {
        let {image, width, onClick, alt, style, className} = this.props;
        let path = '';
        let _style = {...style, width};
        if(image==null || image.path == null){
            return <img alt={alt} className={className} style={_style}/>
        }else{
            path = 'https://api2.otopac.com.hk/images/' + image.path;
            return (
                <img src={path} className={this.props.className} style={_style} alt={alt} title={alt} onClick={onClick}/>
            );
        }
    }
}

Image.propTypes = {
    image:PropTypes.object,
    width: PropTypes.number.isRequired,
    height: PropTypes.number,
    embed: PropTypes.bool,
    alt:PropTypes.string
};

export default Image