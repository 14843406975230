import {Route ,Switch, Redirect} from 'react-router-dom';
import React from 'react';
import { hot } from 'react-hot-loader/root'
import Header from './components/Header';
import SideBar from './components/SideBar';
import Login from './components/pages/Login';
import Register from './components/pages/Register';
import Dashboard from './components/pages/Dashboard';
import Car from './components/pages/Car';
import Sales from './components/pages/Sales';
import Inventory from './components/pages/Inventory';
import Purchase from './components/pages/Purchase';
import Expenditure from './components/pages/Expenditure';
import Cash from './components/pages/Cash';
import Receivable from './components/pages/Receivable';
import { connect } from 'react-redux';
import SalesItem from "./components/pages/SalesItem";
import TempSalesItem from "./components/pages/TempSalesItem";
import KioskSalesItem from "./components/pages/KioskSalesItem";
import InventoryItem from "./components/pages/InventoryItem";
import PurchaseItem from "./components/pages/PurchaseItem";
import ExpenditureItem from "./components/pages/ExpenditureItem";
import CounterParty from "./components/pages/Counterparty";
import CounterPartyItem from "./components/pages/CounterpartyItem";
import Payable from "./components/pages/Payable";
import Customer from "./components/pages/Customer";
import PayableItem from "./components/pages/PayableItem";
import ReceivableItem from "./components/pages/ReceivableItem";
import ReturnItem from "./components/pages/ReturnItem";
import CashItem from "./components/pages/CashItem";
import User from "./components/pages/User";
import CustomerReceivable from "./components/pages/CustomerReceivable";
import CustomerReceivableDetail from "./components/pages/CustomerReceivableDetail";
import SupplierPayable from "./components/pages/SupplierPayable";
import SupplierPayableDetail from "./components/pages/SupplierPayableDetail";
import SalesItemTradeIn from "./components/pages/SalesItemTradeIn";
import Discount from "./components/pages/Discount";
import Human from "./components/pages/Human";
import Stock from "./components/pages/Stock";
import CheckIn from "./components/pages/CheckIn";

function App(props){
    return <React.Fragment>
        <Switch>
            <Route component={MainPages}/>
        </Switch>
    </React.Fragment>
}

class MainPages extends React.Component{

    render(){
        const {user, data, leftBarOpen} = this.props;
        if(!user){
            return <Switch>
                <Route path='/register' component={Register}/>
                <Route component={Login}/>
            </Switch>
        }
         return  <div className="bg-faded" style={{height:'100vh'}}>
            <Header/>
            <div className="container-fluid" style={{height:'100%'}}>
                <div className="row" style={{height:'100%'}}>
                    <div style={{height:'100%', overflowY:'scroll'}} className={leftBarOpen?"bg-inverse left-bar open":"bg-inverse left-bar"}>
                        <Route component={SideBar}/>
                    </div>
                    <div className="col px-0" style={{height:'100%', overflowY:'scroll'}}>
                        <Switch>
                            <Route path='/login' component={Login}/>
                            <Route exact path='/' component={Dashboard}/>
                            <Route exact path='/cars' component={Car}/>
                            <Route exact path='/users' component={User}/>
                            <Route exact path='/sales' component={Sales}/>
                            <Route exact path='/sales/temp/item' component={TempSalesItem}/>
                            <Route exact path='/sales/temp/item/:id' component={TempSalesItem}/>
                            <Route exact path='/sales/kiosk/item/:id' component={KioskSalesItem}/>
                            <Route exact path='/sales/item' component={SalesItem}/>
                            <Route exact path='/sales/item/:id' component={SalesItem}/>
                            <Route exact path='/sales/item/:id/tradeIn' component={SalesItemTradeIn}/>
                            <Route exact path='/inventory' component={Inventory}/>
                            <Route exact path='/inventory/tyres/item' render={(props)=><InventoryItem category_id={2} {...props}/>}/>
                            <Route exact path='/inventory/batteries/item' render={(props)=><InventoryItem category_id={3} {...props}/>}/>
                            <Route exact path='/inventory/wheels/item' render={(props)=><InventoryItem category_id={4} {...props}/>}/>
                            <Route exact path='/inventory/products/item' render={(props)=><InventoryItem category_id={5} {...props}/>}/>
                            <Route exact path='/inventory/services/item' render={(props)=><InventoryItem category_id={1} {...props}/>}/>
                            <Route exact path='/inventory/:category/:id' component={InventoryItem}/>
                            <Route exact path='/purchase' component={Purchase}/>
                            <Route exact path='/purchase/item' component={PurchaseItem}/>
                            <Route exact path='/purchase/item/:id' component={PurchaseItem}/>
                            <Route exact path='/purchase/return/item' component={ReturnItem}/>
                            <Route exact path='/purchase/return/item/:id' component={ReturnItem}/>
                            <Route exact path='/expenditure' component={Expenditure}/>
                            <Route exact path='/expenditure/item' component={ExpenditureItem}/>
                            <Route exact path='/expenditure/item/:id' component={ExpenditureItem}/>
                            <Route exact path='/payable' component={Payable}/>
                            <Route exact path='/payable/item' component={PayableItem}/>
                            <Route exact path='/payable/item/:id' component={PayableItem}/>
                            <Route exact path='/supplierPayable' component={SupplierPayable}/>
                            <Route exact path='/supplierPayable/item/:id' component={SupplierPayableDetail}/>
                            <Route exact path='/receivable' component={Receivable}/>
                            <Route exact path='/receivable/item' component={ReceivableItem}/>
                            <Route exact path='/receivable/item/:id' component={ReceivableItem}/>
                            <Route exact path='/customerReceivable' component={CustomerReceivable}/>
                            <Route exact path='/customerReceivable/item/:id' component={CustomerReceivableDetail}/>
                            <Route exact path='/counterparty' component={CounterParty}/>
                            <Route exact path='/counterparty/item' component={CounterPartyItem}/>
                            <Route exact path='/counterparty/item/:id' component={CounterPartyItem}/>
                            <Route exact path='/customer' component={Customer}/>
                            <Route exact path='/cash' component={Cash}/>
                            <Route exact path='/cash/item' component={CashItem}/>
                            <Route exact path='/cash/item/:id' component={CashItem}/>
                            <Route exact path='/discount' component={Discount}/>
                            <Route exact path='/human' component={Human}/>
                            <Route exact path='/stocks' component={Stock}/>
                            <Route exact path='/checkin' component={CheckIn}/>
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    }
}

MainPages = connect((state)=>{
    return {
        user:state.core.user,
        data:state.core.data,
        leftBarOpen: state.core.leftBarOpen
    }
})(MainPages);


export default hot(App)