import React, {Fragment} from 'react';
import DateInput from '../../common/DateInput'
import {connect} from 'react-redux';
import {get} from "../../../axios";
import moment from 'moment';
class Profit extends React.Component{
    constructor(props){
        super(props);
        this.state = {data:[], detail:null, start_date:moment().startOf('month').toDate(), end_date:moment().toDate()};
        this.getProfitDetail = this.getProfitDetail.bind(this);
    }
    componentDidMount(){
        this.getData();
        this.getProfitDetail();
    }

    async getData(){
        let data = await this.props.get('/report/profit');
        // await this.props.get('/report/difference');
       this.setState({data})
    }

    async getProfitDetail(e){
        if(e){
            e.preventDefault();
        }
        let {start_date, end_date} = this.state;
        let detail = await this.props.get('/report/profit/detail', {start_date, end_date});
        this.setState({detail})
    }

    render(){
        let {data, detail, start_date, end_date} = this.state;
        console.log(start_date, end_date)
        return <div className="p-3">
            <h5 className="font-weight-bold">利潤統計</h5>
            <div className="table-responsive" style={{maxHeight:300}}>
                <table className="table bg-white">
                    <thead>
                    <tr>
                        <td>年份</td>
                        <td>月份</td>
                        <td>銷售</td>
                        <td>成本</td>
                        <td>銷售支出</td>
                        <td>銷售利潤</td>
                        <td>其他支出</td>
                        <td>利潤</td>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((item, index)=>{return <tr key={index}>
                        <td>{item.year}</td>
                        <td>{item.month}</td>
                        <td>{item.turnover.toLocaleString()}</td>
                        <td>{Number.parseInt(item.cogs).toLocaleString()}</td>
                        <td>{Number.parseInt(item.sales_expenditure).toLocaleString()}</td>
                        <td>{Number.parseInt(item.gross_profit).toLocaleString()}</td>
                        <td>{Number.parseInt(item.expenditure).toLocaleString()}</td>
                        <td>{Number.parseInt(item.net_profit).toLocaleString()}</td>
                    </tr>})}
                    </tbody>
                </table>
            </div>
            <h5 className="font-weight-bold mt-3">詳細利潤統計</h5>
            <form onSubmit={this.getProfitDetail}>
                <h6 className="d-flex mt-3">
                    <span className="mr-2">
                        開始日期
                    </span>
                    <DateInput value={start_date}  onChange={(v)=>this.setState({start_date:v})}/>
                    <span className="mx-2">-</span>
                    <span className="mx-2">
                        最後日期
                    </span>
                    <DateInput value={end_date} endTime={true} onChange={(v)=>this.setState({end_date:v})}/>
                    <button className="mx-2 btn btn-sm btn-primary">確定</button>
                </h6>
            </form>
            <div className="mt-3">
                <h6>銷售數據</h6>
                {detail &&
                <div className="table-responsive">
                    <table className="table bg-white">
                        <thead>
                        <tr>
                            <td>類別</td>
                            <td>銷售</td>
                            <td>數量</td>
                            <td>成本</td>
                            <td>銷售利潤</td>
                            <td>Margin</td>
                        </tr>
                        </thead>
                        <tbody>
                        {detail.profits.map((item, index)=>{return <tr key={index}>
                            <td>{item.title?item.title:'其他'}</td>
                            <td>{item.turnover.toLocaleString()}</td>
                            <td>{item.quantity}</td>
                            <td>{Number.parseInt(item.cogs).toLocaleString()}</td>
                            <td>{Number.parseInt(item.profit).toLocaleString()}</td>
                            <td>{Math.round(Number.parseFloat(item.margin) * 100)}%</td>
                        </tr>})}
                        </tbody>
                    </table>
                </div>
                }
            </div>
            <div className="mt-3">
                <h6>維修利潤</h6>
                {detail &&
                <table className="table bg-white">
                    <thead>
                    <tr>
                        <td>類別</td>
                        <td>次數</td>
                        <td>銷售</td>
                        <td>銷售利潤</td>
                    </tr>
                    </thead>
                    <tbody>
                    {detail.services.map((item, index) => {
                        return <tr key={index}>
                            <td>{item.title}</td>
                            <td>{item.count}</td>
                            <td>{item.turnover.toLocaleString()}</td>
                            <td>{Number.parseInt(item.profit).toLocaleString()}</td>
                        </tr>
                    })}
                    </tbody>
                </table>
                }
            </div>
            <div className="mt-3">
                <h6>銷售貨源</h6>
                {detail &&
                <table className="table bg-white">
                    <thead>
                    <tr>
                        <td>供應商</td>
                        <td>類別</td>
                        <td>次數</td>
                        <td>銷售</td>
                        <td>成本</td>
                        <td>銷售利潤</td>
                    </tr>
                    </thead>
                    <tbody>
                    {detail.sources.map((item, index) => {
                        return <tr key={index}>
                            <td>{item.supplier}</td>
                            <td>{item.category}</td>
                            <td>{item.quantity}</td>
                            <td>{item.turnover.toLocaleString()}</td>
                            <td>{item.cogs.toLocaleString()}</td>
                            <td>{Number.parseInt(item.profit).toLocaleString()}</td>
                        </tr>
                    })}
                    </tbody>
                </table>
                }
            </div>
        </div>
    }
}

export default connect((state)=>{return{}}, {get})(Profit)