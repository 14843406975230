import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import {get} from '../../axios';
import Table from "../common/Table";
import Date from "../common/Date";
import {Link} from "react-router-dom";
function CustomerReceivableDetail({get, match}){
    const [item, setItem] = useState({items:[]});
    useEffect(()=>{
        get(`/receivables/customer/${match.params.id}/detail`).then((data)=>{
            setItem(data);
        })
    }, []);
        return (
            <div className="mt-3">
                <div className="mb-3">
                    <h4 className="font-weight-bold">{item.title}應收帳</h4>
                    <h5>
                        總數: {item.amount}
                    </h5>
                    <h5>
                        欠帳: {item.amount - +item.paid}
                    </h5>
                </div>
                <div className="custom-tab-body">
                    <Table data={item.items}
                           accessors={[
                               {key:'invoice_number', title:'應付帳編號'},
                               {key:'version', title:'版本'},
                               {key:'amount', title:'總數'},
                               {key:'amount', title:'剩餘', render:((item) => <span>{+item.amount - +item.paid}</span>)},
                               {key:'customer', title:'客戶'},
                               {key:'creator', title:'建立者'},
                               {key:'invoice_date', title:'付帳日期', render:((item) => <Date
                                       date={item.invoice_date}/>)},
                               {key:'created_at', title:'建立日期', render:((item) => <Date date={item.created_at} time={true}/>)},
                               {key:'id', title:'修改', render:((item) => <Link to={`/receivable/item/${item.invoice_number}`}>修改</Link>)}
                           ]}
                           tableKey='receivable'
                           rowKey='id'
                           search={(item, text)=>item.invoice_number.toUpperCase().includes(text.toUpperCase()) ||  item.customer.toUpperCase().includes(text.toUpperCase())}
                    />
                </div>
            </div>
        );
}

export default connect((state)=>{return {}},{get})(CustomerReceivableDetail)
