import React from "react";
import Tyre from "./Tyre";
import Battery from "./Battery";
import Wheel from "./Wheel";
import SelectInput from "../common/SelectInput";
import connect from "react-redux/es/connect/connect";
import {getProduct} from "../../reducers/product";

class FormWrapper extends React.Component{
    constructor(props){
        super(props);
        this.changeProduct = this.changeProduct.bind(this);
        this.add = this.add.bind(this);
        this.form = this.form.bind(this);
    }

    componentWillReceiveProps(props){
        if(props.tab !== this.props.tab || props.edit !== this.props.edit){
            this.update(props);
        }
    }

    componentDidMount(){
        this.update(this.props);
    }

    update(props){
        const {tab, edit, items} = props;
        this.setState(this.initialState(tab, edit));
        if(typeof edit !== 'undefined'){
            let item = items[edit];
            let {category_id} = item;
            this.setState({...item});
            switch (category_id) {
                case 2:
                    this.props.getProduct('tyres', true);
                    this.setState({key:'tyres'});
                    break;
                case 3:
                    this.props.getProduct('batteries', true);
                    this.setState({key:'batteries'});
                    break;
                case 4:
                    this.props.getProduct('wheels', true );
                    this.setState({key:'wheels'});
                    break;
                case 5:
                    this.props.getProduct('products', true);
                    this.setState({key:'products'});
                    break;
                case 6:
                    this.props.getProduct('usedTyres', true);
                    this.setState({key:'usedTyres'});
                    break;
            }
        }else{
            switch (tab) {
                case 1:
                    this.props.getProduct('tyres', true);
                    this.setState({key:'tyres'});
                    break;
                case 2:
                    this.props.getProduct('batteries', true);
                    this.setState({key:'batteries'});
                    break;
                case 3:
                    this.props.getProduct('wheels', true);
                    this.setState({key:'wheels'});
                    break;
                case 4:
                    this.props.getProduct('products', true);
                    this.setState({key:'products'});
                    break;
                case 5:
                    this.props.getProduct('usedTyres', true);
                    this.setState({key:'usedTyres'});
                    break;
            }
        }
    }

    initialState(tab, edit){
        let _tab = tab;
        if(typeof edit !== 'undefined'){
            console.log(edit);
            _tab = edit + 1
        }
        switch (_tab) {
            case 1:
                return {
                    brand_id :null,
                    pattern_id:null,
                    origin_id:null,
                    category_id:2,
                    loading:'',
                    speed:'',
                    width:'',
                    height:'',
                    diameter:'',
                    remarks:'',
                    price:'',
                    quantity:'',
                    error:null
                };
            case 2:
                return {
                    brand_id :null,
                    origin_id:null,
                    category_id:3,
                    ah:'',
                    size:'',
                    price:'',
                    quantity:'',
                    remarks:'',
                    error:null
                };
            case 3:
                return {
                    brand_id :null,
                    origin_id:null,
                    category_id:4,
                    width:'',
                    model:'',
                    holes:'',
                    et_offset:'',
                    color:'',
                    price:'',
                    quantity:'',
                    remarks:'',
                    error:null
                };
            case 4:
                return {
                    category_id:5,
                    price:'',
                    quantity:'',
                    remarks:'',
                    error:null
                };
            case 5:
                return {
                    brand_id :null,
                    pattern_id:null,
                    origin_id:null,
                    category_id:6,
                    loading:'',
                    speed:'',
                    width:'',
                    height:'',
                    diameter:'',
                    remarks:'',
                    price:'',
                    quantity:'',
                    error:null
                };
            default:
                return null
        }
    }

    changeProduct(id){
        const {key} = this.state;
        const {list} = this.props;
        const item = list[key].find((product)=>product.id === id);
        if(item){
            console.log(item);
            this.setState({...item, id:null})
        }
    }


    add(){
        let error = null;
        let {category_id, quantity, price} = this.state;

        if(!quantity){
            error ='請輸入數量'
        }else if(!price){
            error ='請輸入價格'
        }
        if(category_id === 2 || category_id === 6){
            let {brand_id, pattern_id, origin_id, width, height, diameter} = this.state;
            if(!brand_id){
                error ='請輸入品牌'
            }else if(!pattern_id){
                error ='請輸入花紋'
            }else if(!width){
                error ='請輸入寬度'
            }else if(!height){
                error ='請輸入扁平比'
            }else if(!diameter){
                error ='請輸入輪輞直徑'
            }
        }else if(category_id === 3){
            let {brand_id, origin_id, ah, size} = this.state;
            if(!brand_id){
                error ='請輸入品牌'
            }else if(!origin_id){
                error ='請輸入產地'
            }else if(!ah){
                error ='請輸入寬度'
            }else if(!size){
                error ='請輸入扁平比'
            }
        }else if(category_id === 4){
            let {brand_id, origin_id, model, holes, et_offset, color} = this.state;
            if(!brand_id){
                error ='請輸入品牌'
            }else if(!origin_id){
                error ='請輸入產地'
            }else if(!model){
                error ='請輸入款色'
            }else if(!holes){
                error ='請輸入Holes'
            }else if(!et_offset){
                error ='請輸入Offset'
            }else if(!color){
                error ='請輸入顏色'
            }
        }

        if(error){
            this.setState({error})
        }else{
            this.props.addItem(this.state);
        }
    }

    form(){
        let {tab} = this.props;
        let {category_id} = this.state;
        if(tab=== 1 || category_id === 2){
            return <Tyre values={this.state} onChange={(o)=>this.setState({...o})}/>
        }else if(tab=== 2 || category_id === 3){
            return <Battery values={this.state} onChange={(o)=>this.setState({...o})}/>
        }else if(tab=== 3 || category_id === 4){
            return <Wheel values={this.state} onChange={(o)=>this.setState({...o})}/>
        }else if(tab=== 5 || category_id === 6){
            return <Tyre values={this.state} onChange={(o)=>this.setState({...o})}/>
        }
        return <div/>
    }

    render() {
        let {list} = this.props;
        if(!this.state){
            return <div/>
        }
        const {price, quantity, remarks, key} = this.state;
        return <div style={{border: '1px solid #0275d8'}}>
            <div className="p-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4">
                            <label>產品</label>
                            <SelectInput items={list[key]?list[key]:[]} onChange={this.changeProduct}/>
                        </div>
                    </div>
                </div>
            </div>
            {this.form()}
            <div className="p-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4">
                            <label>價格</label>
                            <input className="form-control" type='number' value={price} onChange={(e)=>this.setState({price:e.target.value})}/>
                        </div>
                        <div className="col-md-4">
                            <label>數量</label>
                            <input className="form-control" type='number' value={quantity} onChange={(e)=>this.setState({quantity:+e.target.value})}/>
                        </div>
                        <div className="col-md-4">
                            <label>Remarks</label>
                            <input className="form-control" value={remarks}  onChange={(e)=>this.setState({remarks:e.target.value})}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-4">
                            <div className="text-danger">{this.state.error}</div>
                        </div>
                        <div className="col-md-4"/>
                        <div className="col-md-4">
                            <button className="btn btn-primary float-right" onClick={this.add}>{this.props.item? "修改":"加入"}</button>
                            <div className="clearfix"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

}
FormWrapper =connect((state)=>{return{list: state.product.list}}, {getProduct})(FormWrapper);
export default FormWrapper