import {recieveErrorMessaqe, quietChangeStore} from './reducers/core';
const fetchMiddleware = (axios) => store => next =>  action => {
    const {store_id} = store.getState().core;
    if (action.type === 'FETCH_GET') {
        const { url, params, headers} = action;
        return axios({
            method:'get',
            url,
            params,
            headers
        }).then((response)=>{
            if(+response.headers['store-id']!== store_id){
                store.dispatch(quietChangeStore(+response.headers['store-id']))
            }
            return response.data
        }).catch(function(e){
            let {data, status} = e.response;
            if(status === 401 && window.location.pathname !== '/'){
                window.location.href = '/';
            }
            if(data.message){
                store.dispatch(recieveErrorMessaqe(data.message));
            }
            throw e
        });
    }else if(action.type === 'FETCH_POST'){
        const { url, data, headers} = action;
        return axios({
            method:'post',
            url,
            data,
            headers
        }).then((response)=>{
            if(+response.headers['store-id']!== store_id){
                store.dispatch(quietChangeStore(+response.headers['store-id']))
            }
            return response.data
        }).catch(function(e){
            let {data, status} = e.response;
            if(data.message){
                store.dispatch(recieveErrorMessaqe(data.message))
            }
            throw e
        });
    }else if(action.type === 'FETCH_DELETE'){
        const { url, data, headers} = action;
        return axios({
            method:'delete',
            url,
            data,
            headers
        }).then((response)=>{
            if(+response.headers['store-id']!== store_id){
                store.dispatch(quietChangeStore(+response.headers['store-id']))
            }
            return response.data
        }).catch(function(e){
            let {data, status} = e.response;
            if(data.message){
                store.dispatch(recieveErrorMessaqe(data.message))
            }
            throw e
        });
    }else {
        return next(action);
    }
};


const get = (url, params, headers) =>({
    type: 'FETCH_GET',
    url,
    params,
    headers
});


const post = (url, data, headers) =>({
    type: 'FETCH_POST',
    url,
    data,
    headers
});

const deleteRequest = (url, data, headers) =>({
    type: 'FETCH_DELETE',
    url,
    data,
    headers
});

export {get, post, deleteRequest, fetchMiddleware}