import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {get} from '../../axios';
import {connect} from 'react-redux';
import SelectInput from "./SelectInput";
class ServiceInput extends React.Component{
    constructor(props){
        super(props);
        this.state  = {
            product_id:null,
            quantity:"",
            loading:false,
            submitError:'',
        };
        this.submit = this.submit.bind(this);
        this.getProducts = this.getProducts.bind(this);
    }

    componentDidMount(){
        this.getProducts(this.props.category_id)
    }

    getProducts(category_id){
        let {get} = this.props;
        get('/services/items', {category_id}).then((services)=>{
            this.setState({services})
        }).catch((e)=>{
        })
    }

    submit(e){
        e.preventDefault();
        let {quantity, product_id, services} = this.state;
        if(!product_id || !+quantity){
            let submitError = '請輸入所有資料';
            this.setState({submitError})
        }else{
            let {onChange} = this.props;
            let service = services.find((s)=>s.id === product_id);
            onChange({quantity:+quantity, product_id: product_id, title:service.title, price:service.price})
        }
    }


    render(){
        const {services, product_id, quantity, submitError} = this.state;
        if(!services){
            return <div/>
        }
        return <div>
            <form className="form" onSubmit={this.submit}>
                <div className="row">
                    <label className="col-md-2 font-weight-bold">產品</label>
                    <div className="col-md-10 font-weight-bold form-inline">
                        <SelectInput value={product_id}
                                     items={services}
                                     onChange={(v)=>this.setState({product_id:v})}
                                     uniqueKey="id"/>
                    </div>
                </div>
                <div className="row">
                    <label className="col-md-2 font-weight-bold">數量</label>
                    <div className="col-md-10 font-weight-bold form-inline">
                        <input className="form-control" value={quantity} type="number" onChange={(e)=>this.setState({quantity:e.target.value})}/>
                        <button className="btn btn-warning btn-sm ml-2">
                            <FontAwesomeIcon icon='search' fixedWidth={true}/>
                            加入
                        </button>
                        <span className="text-danger ml-2">{submitError}</span>
                    </div>
                </div>
            </form>
        </div>
    }
}

export default connect((state)=>{return{}},{get})(ServiceInput)