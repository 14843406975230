import React, { useMemo, useState } from "react";
import Date from "../../common/Date";
import Image from "../../common/Image";
import { connect } from "react-redux";
import moment from "moment";

const Sales = ({ store, sales, profitMode }) => {
  const cost = useMemo(() => {
    const { items, expenditures } = sales;
    let total = 0;
    items
      .filter((item) => !item.is_deleted)
      .forEach((item) => {
        if (item.costs) {
          item.costs.forEach((i) => {
            total += +i.price * +item.quantity;
          });
        }
      });
    expenditures.forEach((item) => {
      total += +item.total;
    });
    return total;
  }, [sales]);
  const { items } = sales;
  const services = items.filter(
    (i) => i.category_id === 1 || i.category_id === null
  );
  const frontTyres = items.filter((i) => i.category_id === 2 && !i.rear);
  const rearTyres = items.filter((i) => i.category_id === 2 && i.rear);
  const batteries = items.filter((i) => i.category_id === 3);
  const wheels = items.filter((i) => i.category_id === 4);
  const accessories = items.filter((i) => i.category_id === 5);
  const referral_type = (referral_type) => {
    switch (referral_type) {
      case 1:
        return "公司";
      case 2:
        return "車房";
      case 3:
        return "拖車";
      case 4:
        return "顧客";
      default:
        return "";
    }
  };
  let recommendation = sales.recommendation;
  switch (sales.message_type) {
    case 1:
      recommendation += " 更換輪胎調呔/戥呔四輪定位";
      break;
    case 2:
      recommendation += " 調呔/戥呔";
      break;
    case 3:
      recommendation += " 四輪定位";
      break;
  }

  return (
    <>
      <h3 className="text-center pt-3 font-weight-bold">{store.full_title}</h3>
      <div className="d-flex align-items-start">
        <div style={{ flexGrow: 1 }}>
          <table className="table table-bordered border-dark">
            <thead>
              <tr>
                <th>項目</th>
                <th>說明</th>
                <th>數量</th>
                <th>單價</th>
                <th>金額</th>
              </tr>
            </thead>
            <tbody>
              <Tyre front={true} items={frontTyres} profitMode={profitMode} />
              <Tyre front={false} items={rearTyres} profitMode={profitMode} />
              <Battery items={batteries} profitMode={profitMode} />
              <Wheel items={wheels} profitMode={profitMode} />
              <Services
                items={services.filter((a) => !a.title.includes("贈"))}
                profitMode={profitMode}
              />
              <Others
                items={accessories.filter((a) => !a.title.includes("贈"))}
              />
              <tr>
                <td colSpan={3}>
                  <div className="d-flex">
                    <div style={{ flex: 2 }}>保養卡:{sales.warranty}</div>
                    <div
                      style={{ flex: 3 }}
                      className="font-weight-bold d-flex"
                    >
                      <div>贈品:</div>
                      <div>
                        {accessories
                          .filter((a) => a.title.includes("贈"))
                          .map((a) => (
                            <div key={a.id}>
                              {a.title} {a.quantity}個
                            </div>
                          ))}
                        {services
                          .filter((a) => a.title.includes("贈"))
                          .map((a) => (
                            <div key={a.id}>
                              {a.title} {a.quantity}個
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </td>
                <td colSpan={2}>
                  <div style={{ borderBottom: "1px solid #000" }}>回購:</div>
                  <div style={{ borderBottom: "1px solid #000" }}>
                    優惠碼: {sales.discount_code}
                  </div>
                  <div>折扣: {sales.discount}</div>
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <div className="d-flex">
                    <div style={{ flex: 1 }}>
                      來訂:{sales.invoice.paid}
                      {sales.paymentReceived.filter((i) => !i.is_deleted)
                        .length > 0 ? (
                        <div>
                          付款方式：
                          {sales.paymentReceived
                            .filter((i) => !i.is_deleted)
                            .map((p) => `${p.title}`)
                            .join(",")}
                        </div>
                      ) : (
                        <div>付款方式： 未付</div>
                      )}
                    </div>
                    <div style={{ flex: 1 }}>
                      尚欠:{sales.invoice.amount - +sales.invoice.paid}
                    </div>
                  </div>
                </td>
                <td colSpan={2}>
                  <div className="font-weight-bold h4">
                    合計: {sales.invoice.amount}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          {profitMode && (
            <div style={{ border: "1px solid #000" }} className="p-2">
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="font-weight-bold">內部專用</h5>
                  <div>
                    <div>
                      總計：{sales.invoice.currency}
                      {(
                        +sales.invoice.amount + +sales.discount
                      ).toLocaleString()}
                    </div>
                  </div>
                  {+sales.discount > 0 && (
                    <>
                      <div className="d-flex">
                        {sales.discount_code && (
                          <div>優惠碼：{sales.discount_code}</div>
                        )}
                        <div>
                          折扣：{sales.invoice.currency}
                          {(+sales.discount).toLocaleString()}
                        </div>
                      </div>
                      <div>
                        實收：{sales.invoice.currency}
                        {(+sales.invoice.amount).toLocaleString()}
                      </div>
                    </>
                  )}
                  <div>
                    已付：{sales.invoice.currency}
                    {(+sales.invoice.paid).toLocaleString()}
                  </div>
                  <div>
                    剩餘：{sales.invoice.currency}
                    {(
                      sales.invoice.amount - +sales.invoice.paid
                    ).toLocaleString()}
                  </div>
                  <div>
                    成本：{sales.invoice.currency}
                    {cost.toLocaleString()}
                  </div>
                  <div className="font-weight-bold">
                    毛利：{sales.invoice.currency}
                    {(sales.invoice.amount - cost).toLocaleString()} (
                    {(
                      ((sales.invoice.amount - cost) * 100) /
                      sales.invoice.amount
                    ).toFixed(2)}
                    )%
                  </div>
                  <div className="pt-3 font-weight-bold">
                    備註:{sales.remarks}
                  </div>
                </div>
                <div>
                  <h5 className="font-weight-bold">其他支出</h5>
                  {sales.expenditures &&
                    sales.expenditures.length > 0 &&
                    sales.expenditures.map((ex) => (
                      <div key={ex.id}>
                        <div>{ex.provider}</div>
                        {ex.items.map((item, index) => (
                          <React.Fragment key={item.id}>
                            <div>
                              <div className="d-inline-block">{item.title}</div>
                              <div className="d-inline-block">
                                ${item.price * item.quantity}
                              </div>
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                    ))}
                  <div className="pl-2">
                    <Image image={sales.image} width={150} height={150} />
                  </div>
                </div>
              </div>
              {sales.service_record && (
                <div>
                  上次光顧日期:
                  {moment(sales.service_record.latest_service_date).format(
                    "DD/MM/YYYY"
                  )}{" "}
                  (第{+sales.service_record.count + 1}次)
                </div>
              )}
              {sales.service_record && (
                <table
                  className="table"
                  style={{ border: "1px solid #252b75ff" }}
                >
                  <thead>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>數量</td>
                      <td>單價</td>
                    </tr>
                  </thead>
                  <tbody>
                    {sales.service_record.items
                      .filter((item) => !item.is_deleted)
                      .map((item, index) => (
                        <tr
                          key={item.id}
                          style={{ border: "1px solid #252b75ff" }}
                        >
                          <td style={{ borderRight: "1px solid #252b75ff" }}>
                            {index + 1}
                          </td>
                          <td>
                            <label>
                              {item.title}{" "}
                              {profitMode && item.remarks
                                ? `(${item.remarks})`
                                : ""}
                            </label>
                          </td>
                          <td>
                            <label>{item.quantity}</label>
                          </td>
                          <td>
                            <label>{item.net_price}</label>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          )}
        </div>
        <div style={{ flex: "0 1 320px" }} className="pl-2">
          <table className="table table-bordered border-dark font-weight-bold">
            <tbody>
            <tr>
              <td>入電腦日期:</td>
              <td className="text-right">
                {moment(sales.initiated_at).format("HH:mm DD/MM/YYYY")}
              </td>
            </tr>
              <tr>
                <td>最後修改時間:</td>
                <td className="text-right">
                  {moment(sales.created_at).format("HH:mm DD/MM/YYYY")}
                </td>
              </tr>
              <tr>
                <td>發票號碼:</td>
                <td className="text-right">{sales.order_number}</td>
              </tr>
              <tr>
                <td>紀錄號碼:</td>
                <td className="text-right">{sales.reference_number}</td>
              </tr>
              <tr>
                <td>維修時間:</td>
                <td className="text-right">
                  {moment(sales.service_at).format("HH:mm DD/MM/YYYY")}
                </td>
              </tr>
              <tr>
                <td>
                  <div>銷售員:</div>
                  <div>維修員:</div>
                </td>
                <td className="text-right">
                  <div>{sales.sales.nick_name}</div>
                  <div>
                    {sales.servicers.map((s) => s.nick_name).join(", ")}
                  </div>
                </td>
              </tr>
              <tr>
                <td>電腦輸入員:</td>
                <td className="text-right">{sales.creator}</td>
              </tr>
              <tr>
                <td>開單員:</td>
                <td className="text-right">{sales.invoicer}</td>
              </tr>
              <tr>
                <td>顧客姓名:</td>
                <td className="text-right">{sales.customer.title}</td>
              </tr>
              <tr>
                <td>聯絡電話:</td>
                <td className="text-right">{sales.contact}</td>
              </tr>
              <tr>
                <td>車牌號碼:</td>
                <td className="text-right">{sales.car_plate}</td>
              </tr>
              <tr>
                <td>車輛型號:</td>
                <td className="text-right">
                  {sales.car_brand} {sales.car_model}
                </td>
              </tr>
              <tr>
                <td>轉介:</td>
                <td className="text-right">
                  {referral_type(sales.referral_type)} {sales.referral}
                </td>
              </tr>
            </tbody>
          </table>
          <h5 className="font-weight-bold">檢查輪胎</h5>
          <table className="table table-bordered border-dark font-weight-bold">
            <tbody>
              <tr>
                <td>輪胎品牌:</td>
                <td>花紋:</td>
              </tr>
              <tr>
                <td>前左: {sales.car_tyre_fl}</td>
                <td>花紋深度: {sales.car_tyre_flp}</td>
              </tr>
              <tr>
                <td>前右: {sales.car_tyre_fr}</td>
                <td>花紋深度: {sales.car_tyre_frp}</td>
              </tr>
              <tr>
                <td>後左: {sales.car_tyre_rl}</td>
                <td>花紋深度: {sales.car_tyre_rlp}</td>
              </tr>
              <tr>
                <td>後右: {sales.car_tyre_rr}</td>
                <td>花紋深度: {sales.car_tyre_rrp}</td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span>建議:</span>
                  <span>{recommendation}</span>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  下次檢查日期
                  {sales.reservice_date
                    ? moment(sales.reservice_date).format("DD/MM/YYYY")
                    : ""}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

function Profit({ costs, item }) {
  if (costs) {
    return (
      <>
        {costs.map((cost) => (
          <div key={cost.tracking_id}>
            <div className="font-weight-bold">{cost.supplier}</div>
            <div>
              成本{cost.price} 單個毛利{item.net_price - cost.price}(
              {(((item.net_price - cost.price) * 100) / item.net_price).toFixed(
                2
              )}
              %) x {item.quantity} = 總毛利{" "}
              {(item.net_price - cost.price) * item.quantity}
            </div>
            <div>備註 {cost.remarks}</div>
          </div>
        ))}
      </>
    );
  }
  return <></>;
}

function Tyre({ front, items, profitMode }) {
  const _front = front ? "前" : "後";
  if (items.length === 0) {
    return (
      <tr>
        <th style={front ? { borderBottom: "0px" } : { borderTop: "0px" }}>
          輪胎 {_front}
        </th>
        <td />
        <td />
        <td />
        <td />
      </tr>
    );
  }
  return items.map((item, index) => {
    const showDiscount = item.price > item.net_price;
    const showAddPrice = profitMode && item.price < item.net_price;
    return (
      <React.Fragment key={index}>
        <tr key={index}>
          {index === 0 && (
            <th
              style={front ? { borderBottom: "0px" } : { borderTop: "0px" }}
              rowSpan={Math.max(
                1,
                items.length +
                  items.filter(
                    (item) =>
                      item.price > item.net_price ||
                      (profitMode && item.price < item.net_price)
                  ).length
              )}
            >
              輪胎 {_front}
            </th>
          )}
          <td className="font-weight-bold">
            <div>{item.title}</div>
            {profitMode && <Profit costs={item.costs} item={item} />}
          </td>
          <td className="font-weight-bold">{item.quantity}</td>
          <td className="font-weight-bold">
            {showDiscount || showAddPrice ? item.price : item.net_price}
          </td>
          <td className="font-weight-bold">
            {showDiscount || showAddPrice
              ? item.quantity * item.price
              : item.quantity * item.net_price}
          </td>
        </tr>
        {(showDiscount || showAddPrice) && (
          <tr>
            <td>{showDiscount ? "折扣" : "溢價"}</td>
            <td />
            <td>{item.net_price - item.price}</td>
            <td>{(item.net_price - item.price) * +item.quantity}</td>
          </tr>
        )}
      </React.Fragment>
    );
  });
}

function Battery({ items, profitMode }) {
  if (items.length === 0) {
    return (
      <tr>
        <th>電池</th>
        <td />
        <td />
        <td />
        <td />
      </tr>
    );
  }
  return items.map((item, index) => {
    const showDiscount = item.price > item.net_price;
    const showAddPrice = profitMode && item.price < item.net_price;
    return (
      <React.Fragment key={index}>
        <tr>
          {index === 0 && (
            <th
              rowSpan={Math.max(
                1,
                items.length +
                  items.filter(
                    (item) =>
                      item.price > item.net_price ||
                      (profitMode && item.price < item.net_price)
                  ).length
              )}
            >
              電池
            </th>
          )}
          <td className="font-weight-bold">
            <div>{item.title}</div>
            {profitMode && <Profit costs={item.costs} item={item} />}
          </td>
          <td className="font-weight-bold">{item.quantity}</td>
          <td className="font-weight-bold">
            {showDiscount || showAddPrice ? item.price : item.net_price}
          </td>
          <td className="font-weight-bold">
            {showDiscount || showAddPrice
              ? item.quantity * item.price
              : item.quantity * item.net_price}
          </td>
        </tr>
        {(showDiscount || showAddPrice) && (
          <tr>
            <td>{showDiscount ? "折扣" : "溢價"}</td>
            <td />
            <td>{item.net_price - item.price}</td>
            <td>{(item.net_price - item.price) * +item.quantity}</td>
          </tr>
        )}
      </React.Fragment>
    );
  });
}

function Wheel({ items, profitMode }) {
  if (items.length === 0) {
    return (
      <tr>
        <th>合金鈴</th>
        <td />
        <td />
        <td />
        <td />
      </tr>
    );
  }
  return items.map((item, index) => {
    const showDiscount = item.price > item.net_price;
    const showAddPrice = profitMode && item.price < item.net_price;
    return (
      <React.Fragment key={index}>
        <tr>
          {index === 0 && (
            <th
              rowSpan={Math.max(
                1,
                items.length +
                  items.filter(
                    (item) =>
                      item.price > item.net_price ||
                      (profitMode && item.price < item.net_price)
                  ).length
              )}
            >
              合金鈴
            </th>
          )}
          <td className="font-weight-bold">
            <div>{item.title}</div>
            {profitMode && <Profit costs={item.costs} item={item} />}
          </td>
          <td className="font-weight-bold">{item.quantity}</td>
          <td className="font-weight-bold">
            {showDiscount || showAddPrice ? item.price : item.net_price}
          </td>
          <td className="font-weight-bold">
            {showDiscount || showAddPrice
              ? item.quantity * item.price
              : item.quantity * item.net_price}
          </td>
        </tr>
        {(showDiscount || showAddPrice) && (
          <tr>
            <td>{showDiscount ? "折扣" : "溢價"}</td>
            <td />
            <td>{item.net_price - item.price}</td>
            <td>{(item.net_price - item.price) * +item.quantity}</td>
          </tr>
        )}
      </React.Fragment>
    );
  });
}

function Others({ items, profitMode }) {
  if (items.length === 0) {
    return (
      <tr>
        <th>其他</th>
        <td />
        <td />
        <td />
        <td />
      </tr>
    );
  }
  return items.map((item, index) => {
    const showDiscount = item.price > item.net_price;
    const showAddPrice = profitMode && item.price < item.net_price;
    return (
      <React.Fragment key={index}>
        <tr>
          {index === 0 && (
            <th
              rowSpan={Math.max(
                1,
                items.length +
                  items.filter(
                    (item) =>
                      item.price > item.net_price ||
                      (profitMode && item.price < item.net_price)
                  ).length
              )}
            >
              其他
            </th>
          )}
          <td className="font-weight-bold">
            <div>{item.title}</div>
            {profitMode && <Profit costs={item.costs} item={item} />}
          </td>
          <td className="font-weight-bold">{item.quantity}</td>
          <td className="font-weight-bold">
            {showDiscount || showAddPrice ? item.price : item.net_price}
          </td>
          <td className="font-weight-bold">
            {showDiscount || showAddPrice
              ? item.quantity * item.price
              : item.quantity * item.net_price}
          </td>
        </tr>
        {(showDiscount || showAddPrice) && (
          <tr>
            <td>{showDiscount ? "折扣" : "溢價"}</td>
            <td />
            <td>{item.net_price - item.price}</td>
            <td>{(item.net_price - item.price) * +item.quantity}</td>
          </tr>
        )}
      </React.Fragment>
    );
  });
}

function Services({ items, profitMode }) {
  if (items.length === 0) {
    return (
      <tr>
        <th>服務</th>
        <td />
        <td />
        <td />
        <td />
      </tr>
    );
  }
  return items.map((item, index) => {
    const showDiscount = item.price > item.net_price;
    const showAddPrice = profitMode && item.price < item.net_price;
    return (
      <React.Fragment key={index}>
        <tr>
          {index === 0 && (
            <th
              rowSpan={Math.max(
                1,
                items.length +
                  items.filter(
                    (item) =>
                      item.price > item.net_price ||
                      (profitMode && item.price < item.net_price)
                  ).length
              )}
            >
              服務
            </th>
          )}
          <td className="font-weight-bold">
            <div>{item.title}</div>
            {profitMode && <Profit costs={item.costs} item={item} />}
          </td>
          <td className="font-weight-bold">{item.quantity}</td>
          <td className="font-weight-bold">
            {showDiscount || showAddPrice ? item.price : item.net_price}
          </td>
          <td className="font-weight-bold">
            {showDiscount || showAddPrice
              ? item.quantity * item.price
              : item.quantity * item.net_price}
          </td>
        </tr>
        {(showDiscount || showAddPrice) && (
          <tr>
            <td>{showDiscount ? "折扣" : "溢價"}</td>
            <td />
            <td>{item.net_price - item.price}</td>
            <td>{(item.net_price - item.price) * +item.quantity}</td>
          </tr>
        )}
      </React.Fragment>
    );
  });
}
class Sales2 extends React.Component {
  cost(items, expenditures) {
    let total = 0;
    items
      .filter((item) => !item.is_deleted)
      .forEach((item) => {
        if (item.costs) {
          item.costs.forEach((i) => {
            total += +i.price * +item.quantity;
          });
        }
      });
    expenditures.forEach((item) => {
      total += +item.total;
    });
    return total;
  }

  render() {
    const { store, sales, profitMode } = this.props;
    const rightBottomBorderStyle = {
      borderRight: "1px solid #252b75ff",
      borderBottom: "1px solid #252b75ff",
      paddingLeft: "5px",
    };
    return (
      <React.Fragment>
        <h3 className="text-center pt-3">{store.full_title}</h3>
        <div
          className="row no-gutters mt-3"
          style={{ border: "1px solid #252b75ff" }}
        >
          <div className="col-6" style={rightBottomBorderStyle}>
            <label>顧客名稱</label>
            <span className="mr-3 float-right">{sales.customer.title}</span>
          </div>
          <div className="col-6" style={rightBottomBorderStyle}>
            <label>訂單號碼</label>
            <span className="mr-3 float-right">{sales.order_number}</span>
          </div>
        </div>
        <div
          className="row no-gutters"
          style={{ border: "1px solid #252b75ff" }}
        >
          <div className="col-6" style={rightBottomBorderStyle}>
            <label>顧客電話</label>
            <span className="mr-3 float-right">{sales.customer.contact}</span>
          </div>
          <div className="col-6" style={rightBottomBorderStyle}>
            <label>維修時間</label>
            <span className="mr-3 float-right">
              <Date date={sales.service_at} time={true} day={true} />
            </span>
          </div>
        </div>
        <div
          className="row no-gutters"
          style={{ border: "1px solid #252b75ff" }}
        >
          <div className="col-6" style={rightBottomBorderStyle}>
            <label>車輛號碼</label>
            <span className="mr-3 float-right">
              {sales.car_plate}{" "}
              {sales.service_record && sales.service_record.last_message
                ? "(*)"
                : null}
            </span>
          </div>
          <div className="col-6" style={rightBottomBorderStyle}>
            <div>
              <label>開單時間</label>
              <span className="mr-3 float-right">
                <Date date={sales.initiated_at} time={true} day={true} />
              </span>
            </div>
            {sales.version > 1 && (
              <div>
                <label>更新時間</label>
                <span className="mr-3 float-right">
                  <Date date={sales.created_at} time={true} day={true} />
                </span>
              </div>
            )}
          </div>
        </div>
        <div
          className="row no-gutters"
          style={{ border: "1px solid #252b75ff" }}
        >
          <div className="col-6" style={rightBottomBorderStyle}>
            <label>技術員</label>
            <span className="mr-3 float-right">
              {sales.servicers.map((s) => s.nick_name).join()}
            </span>
          </div>
          <div className="col-6" style={rightBottomBorderStyle}>
            <label>車輛型號</label>
            <span className="mr-3 float-right">
              {sales.car_brand} {sales.car_model}
            </span>
          </div>
        </div>
        <div
          className="row no-gutters"
          style={{ border: "1px solid #252b75ff" }}
        >
          <div className="col-6" style={rightBottomBorderStyle}>
            <label>銷售員</label>
            <span className="mr-3 float-right">{sales.sales.nick_name}</span>
          </div>
          <div className="col-6" style={rightBottomBorderStyle}>
            <label>手寫單號碼</label>
            <span className="mr-3 float-right">
              {sales.invoice.reference_number}
            </span>
          </div>
        </div>
        <div
          className="row no-gutters"
          style={{ border: "1px solid #252b75ff" }}
        >
          <div className="col-6" style={rightBottomBorderStyle}>
            <label>輪胎尺寸</label>
            <span className="mr-3 float-right">{sales.tyre_size}</span>
          </div>
          <div className="col-6" style={rightBottomBorderStyle}>
            <label>下次檢查日期</label>
            <span className="mr-3 float-right">
              {sales.reservice_date && (
                <span>
                  <span className="mr-1">
                    {Math.round(
                      moment(sales.reservice_date).diff(sales.service_at, "d") /
                        30
                    )}
                    個月後
                  </span>
                  <span>
                    {moment(sales.reservice_date).format("DD/MM/YYYY")}
                  </span>
                </span>
              )}
            </span>
          </div>
        </div>
        {sales.service_record && (
          <div
            className="row no-gutters"
            style={{ border: "1px solid #252b75ff" }}
          >
            <div className="col-6" style={rightBottomBorderStyle} />
            <div className="col-6" style={rightBottomBorderStyle}>
              <label>上次光顧日期</label>
              <span className="mr-3 float-right">
                {moment(sales.service_record.latest_service_date).format(
                  "DD/MM/YYYY"
                )}{" "}
                (第{+sales.service_record.count + 1}次)
              </span>
            </div>
          </div>
        )}
        <div
          className="row no-gutters mt-3"
          style={{ border: "1px solid #252b75ff" }}
        >
          <div className="col-6" style={rightBottomBorderStyle}>
            <label>詳情</label>
          </div>
          <div className="col-2" style={rightBottomBorderStyle}>
            <label>數量</label>
          </div>
          <div className="col-2" style={rightBottomBorderStyle}>
            <label>單價</label>
          </div>
          <div className="col-2" style={rightBottomBorderStyle}>
            <label>小計</label>
          </div>
        </div>
        {sales.items
          .filter((item) => !item.is_deleted)
          .map((item, index) => (
            <div
              key={item.id}
              className="row no-gutters"
              style={{ border: "1px solid #252b75ff" }}
            >
              <div
                className="col-1"
                style={{ borderRight: "1px solid #252b75ff" }}
              >
                {index + 1}
              </div>
              <div className="col-5" style={rightBottomBorderStyle}>
                <label>
                  {item.title}{" "}
                  {profitMode && item.remarks ? `(${item.remarks})` : ""}
                </label>
              </div>
              <div className="col-2" style={rightBottomBorderStyle}>
                <label>{item.quantity}</label>
              </div>
              <div className="col-2" style={rightBottomBorderStyle}>
                <label>{profitMode ? item.price : item.net_price}</label>
              </div>
              <div className="col-2" style={rightBottomBorderStyle}>
                <label>
                  {profitMode
                    ? +item.price * item.quantity
                    : +item.net_price * item.quantity}
                </label>
              </div>
              {profitMode && item.price !== item.net_price && (
                <React.Fragment>
                  <div
                    className="col-1"
                    style={{ borderRight: "1px solid #252b75ff" }}
                  ></div>
                  <div className="col-5" style={rightBottomBorderStyle}>
                    {+item.net_price - +item.price > 0 ? "溢價" : "折扣"}
                  </div>
                  <div className="col-2" style={rightBottomBorderStyle}>
                    {item.quantity}
                  </div>
                  <div className="col-2" style={rightBottomBorderStyle}>
                    <label>{+item.net_price - +item.price}</label>
                  </div>
                  <div className="col-2" style={rightBottomBorderStyle}>
                    <label>
                      {(+item.net_price - +item.price) * item.quantity}
                    </label>
                  </div>
                </React.Fragment>
              )}
              {profitMode &&
                item.costs &&
                item.costs.map((cost) => (
                  <React.Fragment key={cost.tracking_id}>
                    <div
                      className="col-1"
                      style={{ borderRight: "1px solid #252b75ff" }}
                    />
                    <div className="col-11" style={rightBottomBorderStyle}>
                      <div className="font-weight-bold">{cost.supplier}</div>
                      <div>
                        成本{cost.price} 單個毛利{item.net_price - cost.price}(
                        {(
                          ((item.net_price - cost.price) * 100) /
                          item.net_price
                        ).toFixed(2)}
                        %) x {item.quantity} = 總毛利{" "}
                        {(item.net_price - cost.price) * item.quantity}
                      </div>
                      <div>備註 {cost.remarks}</div>
                    </div>
                  </React.Fragment>
                ))}
            </div>
          ))}
        {profitMode && sales.service_record && (
          <div>
            <div
              className="row no-gutters mt-3"
              style={{ border: "1px solid #252b75ff" }}
            >
              <div className="col-6" style={rightBottomBorderStyle}>
                <label>
                  上次維修紀錄{" "}
                  {moment(sales.service_record.latest_service_date).format(
                    "DD/MM/YYYY"
                  )}{" "}
                  (第{+sales.service_record.count + 1}次)
                </label>
              </div>
              <div className="col-2" style={rightBottomBorderStyle}>
                <label>數量</label>
              </div>
              <div className="col-2" style={rightBottomBorderStyle}>
                <label>單價</label>
              </div>
              <div className="col-2" style={rightBottomBorderStyle}>
                <label>小計</label>
              </div>
            </div>
            {sales.service_record.items
              .filter((item) => !item.is_deleted)
              .map((item, index) => (
                <div
                  key={item.id}
                  className="row no-gutters"
                  style={{ border: "1px solid #252b75ff" }}
                >
                  <div
                    className="col-1"
                    style={{ borderRight: "1px solid #252b75ff" }}
                  >
                    {index + 1}
                  </div>
                  <div className="col-5" style={rightBottomBorderStyle}>
                    <label>
                      {item.title}{" "}
                      {profitMode && item.remarks ? `(${item.remarks})` : ""}
                    </label>
                  </div>
                  <div className="col-2" style={rightBottomBorderStyle}>
                    <label>{item.quantity}</label>
                  </div>
                  <div className="col-2" style={rightBottomBorderStyle}>
                    <label>{item.net_price}</label>
                  </div>
                </div>
              ))}
          </div>
        )}
        <div
          className="row no-gutters"
          style={{ border: "1px solid #252b75ff" }}
        >
          <div className="col-6" style={rightBottomBorderStyle}></div>
          <div className="col-2" style={rightBottomBorderStyle}></div>
          <div className="col-2" style={rightBottomBorderStyle}></div>
          <div className="col-2" style={rightBottomBorderStyle}>
            <label></label>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-7">
            {sales.paymentReceived.filter((i) => !i.is_deleted).length > 0 ? (
              <label>
                付款方式：
                {sales.paymentReceived
                  .filter((i) => !i.is_deleted)
                  .map((p) => `${p.title}`)
                  .join(",")}
              </label>
            ) : (
              <label>付款方式： 未付</label>
            )}
          </div>
          <div className="col-5">
            總計：{sales.invoice.currency}
            {(+sales.invoice.amount + +sales.discount).toLocaleString()}
          </div>
        </div>
        {+sales.discount > 0 && (
          <>
            <div className="row">
              折扣：{sales.invoice.currency}
              {(+sales.discount).toLocaleString()}
            </div>
            <div className="row">
              <div className="col-7" />
              <div className="col-5">
                實收：{sales.invoice.currency}
                {(+sales.invoice.amount).toLocaleString()}
              </div>
            </div>
          </>
        )}
        <div className="row">
          <div className="col-7">
            <label>備註：</label>
          </div>
          <div className="col-5">
            已付：{sales.invoice.currency}
            {(+sales.invoice.paid).toLocaleString()}
          </div>
        </div>
        <div className="row">
          <div className="col-7">
            {sales.remarks}
            <Image
              image={sales.image}
              width={150}
              height={150}
              style={{ position: "absolute" }}
            />
          </div>
          <div className="col-5">
            剩餘：{sales.invoice.currency}
            {(sales.invoice.amount - +sales.invoice.paid).toLocaleString()}
          </div>
        </div>
        {profitMode && (
          <div className="row">
            <div className="col-7"></div>
            <div className="col-5">
              成本：{sales.invoice.currency}
              {this.cost(sales.items, sales.expenditures).toLocaleString()}
            </div>
          </div>
        )}
        {profitMode && (
          <div className="row">
            <div className="col-7"></div>
            <div className="col-5">
              毛利：{sales.invoice.currency}
              {(
                sales.invoice.amount -
                this.cost(sales.items, sales.expenditures)
              ).toLocaleString()}{" "}
              (
              {(
                ((sales.invoice.amount -
                  this.cost(sales.items, sales.expenditures)) *
                  100) /
                sales.invoice.amount
              ).toFixed(2)}
              )%
            </div>
          </div>
        )}
        {profitMode && sales.expenditures && sales.expenditures.length > 0 && (
          <div style={{ border: "1px solid #252b75ff" }}>成本支出</div>
        )}
        {profitMode &&
          sales.expenditures &&
          sales.expenditures.length > 0 &&
          sales.expenditures.map((ex) => (
            <div
              key={ex.id}
              className="row no-gutters"
              style={{ border: "1px solid #252b75ff" }}
            >
              <div
                className="col-12"
                style={{ borderRight: "1px solid #252b75ff" }}
              >
                <div>{ex.provider}</div>
              </div>
              {ex.items.map((item, index) => (
                <React.Fragment key={item.id}>
                  <div
                    className="col-1"
                    style={{ borderRight: "1px solid #252b75ff" }}
                  >
                    {index + 1}
                  </div>
                  <div className="col-5" style={rightBottomBorderStyle}>
                    <label>{item.title}</label>
                  </div>
                  <div className="col-2" style={rightBottomBorderStyle}>
                    <label>{item.quantity}</label>
                  </div>
                  <div className="col-2" style={rightBottomBorderStyle}>
                    <label>{item.price}</label>
                  </div>
                  <div className="col-2" style={rightBottomBorderStyle}>
                    <label>{item.price * item.quantity}</label>
                  </div>
                </React.Fragment>
              ))}
            </div>
          ))}
        {profitMode && (
          <div className="row">
            <div className="col-8"></div>
            <div className="col-4 text-right" style={rightBottomBorderStyle}>
              <div>
                總支出 {sales.invoice.currency}
                {this.cost([], sales.expenditures).toLocaleString()}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default connect((state) => ({ store: state.core.store }))(Sales);
