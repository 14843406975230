import React from "react";
import Sales from './common/Sales'
class Invoice extends React.Component{
    constructor(props){
        super(props);
    }

    componentDidMount(){
        let mywindow = window.open('', 'PRINT', 'height=800,width=600');
        mywindow.document.write('<html>');
        //adding the current css file in the head
        mywindow.document.write(document.head.innerHTML);
        mywindow.document.write('<body>');
        mywindow.document.write(this.refs.container.innerHTML);
        mywindow.document.write('</body></html>');
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
        mywindow.onbeforeunload = this.props.close
    }

    componentDidUpdate(){
        let mywindow = window.open('', 'PRINT', 'height=800,width=600');
        mywindow.document.write('<html>');
        //adding the current css file in the head
        mywindow.document.write(document.head.innerHTML);
        mywindow.document.write('<body>');
        mywindow.document.write(this.refs.container.innerHTML);
        mywindow.document.write('</body></html>');
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
        mywindow.onbeforeunload = this.props.close
    }

    render() {
        const {sales, profitMode} = this.props;
        return (
            <div className="mt-3 d-none">
                <button className="btn btn-sm btn-primary">印刷</button>
                <div ref="container" className="container print-block" media="print" style={{color:'#252b75ff', backgroundColor:'#fff'}}>
                    <Sales sales={sales} profitMode={profitMode}/>
                </div>
            </div>
        );
    }
}

export default Invoice;