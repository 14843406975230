import React from 'react';
import Date from "../common/Date";
import TrueFalseInput from "../common/TrueFalseInput";
import {connect} from 'react-redux';
import {get} from "../../axios";
import {getFormData, getItem, submit} from "../../reducers/counterparty";
import FileUpload from "../common/FileUpload";
import ClickableImage from "../common/ClickableImage";
class CounterpartyItem extends React.Component{
    constructor(props){
        super(props);
        this.state = {

        };
        this.submit = this.submit.bind(this);
    }

    componentDidMount(){
        if(this.props.match && this.props.match.params.id){
            this.props.getItem(this.props.match.params.id);
        }
    }

    componentWillReceiveProps(props){
        if(props.item && props.item !== this.props.item){
            this.setState({...props.item})
        }
    }

    submit(){
        let {history} = this.props;
        this.props.submit( this.state, history);
    }

    render(){
        const {created_at, person, title, is_supplier, is_customer, is_company, contact, is_garage, is_towing, is_deleted, path, id} = this.state;
        return <div style={{position:'relative', height:'100%'}}>
            <div style={{position:'absolute', left:0, top:0, bottom:0, right:0}}>
                <div className="mt-3">
                    <button className="btn btn-primary btn-sm" onClick={this.submit}>
                        儲存
                    </button>
                </div>
                <div className="p-3 mt-3" style={{background:'#fff'}}>
                    <div className="row">
                        <label className="col-md-2 font-weight-bold">客戶號碼</label>
                        <div className="col-md-3 font-weight-bold">
                            {id}
                        </div>
                    </div>
                    <div className="row mt-2">
                        <label className="col-md-2 font-weight-bold">輸入日期</label>
                        <div className="col-md-10 font-weight-bold">
                            <Date date={created_at}/>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <label className="col-md-2 font-weight-bold">名稱</label>
                        <div className="col-md-10 font-weight-bold">
                            <input className="form-control" value={title} onChange={(e)=>this.setState({title:e.target.value})}/>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <label className="col-md-2 font-weight-bold">聯絡人</label>
                        <div className="col-md-10 font-weight-bold">
                            <input className="form-control" value={person} onChange={(e)=>this.setState({person:e.target.value})}/>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <label className="col-md-2 font-weight-bold">聯絡電話</label>
                        <div className="col-md-10 font-weight-bold">
                            <input className="form-control" value={contact} onChange={(e)=>this.setState({contact:e.target.value})}/>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <label className="col-md-2 font-weight-bold">是公司客</label>
                        <div className="col-md-10">
                            <TrueFalseInput className="form-control" value={is_company} onChange={(value)=>this.setState({is_company:value})}/>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <label className="col-md-2 font-weight-bold">是顧客</label>
                        <div className="col-md-10">
                            <TrueFalseInput className="form-control" value={is_customer} onChange={(value)=>this.setState({is_customer:value})}/>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <label className="col-md-2 font-weight-bold">是供應商</label>
                        <div className="col-md-10">
                            <TrueFalseInput className="form-control" value={is_supplier} onChange={(value)=>this.setState({is_supplier:value})}/>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <label className="col-md-2 font-weight-bold">是車房</label>
                        <div className="col-md-10">
                            <TrueFalseInput className="form-control" value={is_garage} onChange={(value)=>this.setState({is_garage:value})}/>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <label className="col-md-2 font-weight-bold">是拖車公司</label>
                        <div className="col-md-10">
                            <TrueFalseInput className="form-control" value={is_towing} onChange={(value)=>this.setState({is_towing:value})}/>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <label className="col-md-2 font-weight-bold">卡片</label>
                        <div className="col-md-10">
                            {path &&
                            <ClickableImage src={`https://api2.otopac.com.hk/files/${path}`}/>
                            }
                            <FileUpload uploaded={(d)=>this.setState({file_id:d.id, path:d.path})}
                            single={true}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}


export default connect((state)=>{
        return{data: state.counterparty.data, item:state.counterparty.item,}},
    {get, getItem, getFormData, submit})(CounterpartyItem);
