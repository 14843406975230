import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import Profit from '../component/dashboard/Profit';
import Stock from '../component/dashboard/Stock';
import Inventory from '../component/dashboard/Inventory';
import Cashflow from '../component/dashboard/Cashflow';
import Performance from '../component/dashboard/Performance';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {get} from "../../axios";
import DateInput from '../common/DateInput'
import moment from 'moment';
class Dashboard extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            tab:2,
            start_date:moment().startOf('month').toDate()
        }
        this.exportReceivesExcel = this.exportReceivesExcel.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
    }

    async handleDownload(){

        try {
            let {start_date} = this.state;
            const {receives} = await this.props.get(`/report/invoice-receives`,{start_date});
            if(receives){
                this.exportReceivesExcel(receives); // 下載 CSV
            }
        } catch (error) {
            console.error('獲取資料時出錯:', error);
        }
    };

    exportReceivesExcel(data) {
        const csvRows = [];

        // 獲取表頭
        const headers = Object.keys(data[0]);
        csvRows.push(headers.join(','));

        // 獲取數據行
        for (const row of data) {
            const values = headers.map(header => {
                const escaped = ('' + row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`; // 用引號包住每個值
            });
            csvRows.push(values.join(','));
        }

        const csv = csvRows.join('\n');
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        // 創建一個<a>標籤，並自動點擊下載
        let filename = 'invoice_receives.csv';
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', filename);
        a.click();  // 自動觸發下載
    }

    render() {
        const {user} = this.props;
        const {tab,start_date} = this.state;
        const tabClass = "custom-tab";
        const aTabClass = "custom-tab active";
        return (
            <div className="mt-3">
                <div>
                    <Link to='/sales/item' className='btn btn-primary btn-sm'>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>新銷售
                    </Link>
                    <Link to='/sales/temp/item' className='btn btn-primary btn-sm ml-2'>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>臨時銷售
                    </Link>
</div>
                <div className="mt-3">
                    {user.is_admin &&
                    <div className={tab === 1 ? aTabClass: tabClass} onClick={()=>this.setState({tab:1})}>
                        營利分析
                    </div>
                    }
                    <div className={tab === 2 ? aTabClass: tabClass}  onClick={()=>this.setState({tab:2})}>
                        資金分折
                    </div>
                    <div className={tab === 3 ? aTabClass: tabClass}  onClick={()=>this.setState({tab:3})}>
                        倉庫分折
                    </div>
                    <div className={tab === 4 ? aTabClass: tabClass}  onClick={()=>this.setState({tab:4})}>
                        員工表現
                    </div>
                    <div className={tab === 5 ? aTabClass: tabClass}  onClick={()=>this.setState({tab:5})}>
                        Stock分折
                    </div>
                </div>
                <div className="custom-tab-body">
                    {tab === 1 &&
                    <Profit/>
                    }
                    {tab === 2 &&(
                    <>
                        <div style={{flex:1,flexDirection:'row',alignItem:'center',margin:15}}>
                            <div style={{borderWidth:1,borderStyle:'solid',display:'inline-block',padding:2,backgroundColor:'#808080',color:'#fff'}}>開始日期</div><DateInput value={start_date} className={"d-inline-block"} onChange={(v)=>this.setState({start_date:v})}/>
                            <button type="submit" onClick={this.handleDownload} className={"d-flex my-1"}>Generate Invoice Receives</button>
                        </div>
                        <Cashflow/>
                    </>
                    )}
                    {tab === 3 &&
                    <Inventory/>
                    }
                    {tab === 4 &&
                    <Performance/>
                    }
                    {tab === 5 &&
                    <Stock/>
                    }
                </div>
            </div>
        );
    }
}

export default connect((state)=>({user:state.core.user}),{get})(Dashboard)
