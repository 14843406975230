import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
class Supplier extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            tab:1
        }
    }
    
    render() {
        const {tab} = this.state;
        const tabClass = "custom-tab";
        const aTabClass = "custom-tab active";
        return (
            <div className="mt-3">
                <div>
                    <Link to='/supplier/item' className='btn btn-primary btn-sm'>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                        新增顧客
                    </Link>
                </div>
                <div className="mt-3">
                    <div className={tab === 1 ? aTabClass: tabClass} onClick={()=>this.setState({tab:1})}>
                        註冊顧客
                    </div>
                    <div className={tab === 2 ? aTabClass: tabClass} onClick={()=>this.setState({tab:2})}>
                        過往顧客
                    </div>
                </div>
                <div className="custom-tab-body">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>顧客名稱</th>
                                <th>名稱</th>
                                <th>數量</th>
                                <th>定價</th>
                                <th>建立日期</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default Supplier