const TOGGLE_LEFT_BAR = 'TOGGLE_LEFT_BAR';
const GOT_CORE_DATA = 'GOT_CORE_DATA';
const LOGGED_IN = 'LOGGED_IN';
const LOG_IN_FAILED = 'LOG_IN_FAILED';
const LOGGED_OUT = 'LOGGED_OUT';
const ERROR_MESSAGE = 'ERROR_MESSAGE';
const CHANGE_STORE = 'CHANGE_STORE';
const CHECKIN = 'CHECKIN';
import {get, post} from '../axios';
const initialState= {
    data:{

    },
    checkIns:[],
    store:{},
    store_id:null,
    stores:[],
    errors:[],
    leftBarOpen:false,
    message:'',
    user:null
};

export default function (state = initialState, action){
    switch (action.type){
        case TOGGLE_LEFT_BAR:
            return Object.assign({}, state, {leftBarOpen: !state.leftBarOpen});
        case ERROR_MESSAGE:
            return Object.assign({}, state, {errors: [...state.errors, action.data]});
        case GOT_CORE_DATA:
            return Object.assign({}, state, {data: action.data});
        case LOGGED_IN:
            return Object.assign({}, state, {user: action.data, stores:action.stores, store:action.store});
        case LOGGED_OUT:
            return Object.assign({}, state, {user: initialState.user});
        case LOG_IN_FAILED:
            return Object.assign({}, state, {message: action.data});
        case CHANGE_STORE:
            return Object.assign({}, state, {user: action.data, store_id:action.store_id, store:action.store});
        case CHECKIN:
            return Object.assign({}, state, {checkIns: action.data});
        default:
            return state;
    }
}


export function toggleLeftBar(){
    return {type:TOGGLE_LEFT_BAR}
}

export function getCoreData(){
    return async(dispatch)=>{
        const data = await dispatch(get(`/core`));
        return dispatch({type:GOT_CORE_DATA, data: data})
    }
}

export function recieveErrorMessaqe(err){
    return {type:ERROR_MESSAGE, data: err}
}

export function initialLogin(){
    return async (dispatch) =>{
        try{
            const data = await dispatch(get(`/login`));
            const stores = await dispatch(get(`/stores`));
            const {store_id} = data;
            const store = stores.find((s)=>+s.store_id === +store_id);
            return dispatch({type:LOGGED_IN, data, stores, store})
        }catch (e) {
            //401
            console.error(e)
            return dispatch({type:LOG_IN_FAILED})
        }
    }
}

export function monitorCheckIns(){
    return async(dispatch)=>{
        const results = await dispatch(get('/management/checkInStatus', {}));
        return dispatch({type:CHECKIN, data:results})
    }
}
export function changeStore(store_id){
  return async (dispatch)=>{
      try{
          await dispatch(post(`/stores/${store_id}`));
          window.location.reload();
      }catch (e) {
         console.error(e)
      }
  }
}


export function quietChangeStore(store_id){
    return (dispatch, getState)=>{
        const {stores} = getState().core;
        const store = stores.find((s)=>+s.store_id === +store_id);
        return dispatch({type:CHANGE_STORE, store_id:+store_id, store})
    }
}

export function login(username, password){
    return async (dispatch) =>{
        try{
            let data = await dispatch(post(`/login`, {username, password}));
            const {user} = data;
            const stores = await dispatch(get(`/stores`));
            const {store_id} = user;
            const store = stores.find((s)=>+s.store_id === +store_id);
            dispatch({type:LOGGED_IN, data:user, stores, store})
        }catch (e) {
            console.log(e);
            dispatch({type:LOG_IN_FAILED, data:e});
        }
    }
}


export function register(username, nick_name, password){
    return async (dispatch) =>{
        try{
            let data = await dispatch(post(`/register`, {username, nick_name, password}));
            const stores = await dispatch(get(`/stores`));
            dispatch({type:LOGGED_IN, data, stores})
        }catch (e) {
            console.log(e);
            dispatch({type:LOG_IN_FAILED, data:e});
        }
    }
}

export function logout(){
    return async (dispatch) =>{
        try{
            await dispatch(get(`/logout`));
        }catch (e) {
            console.log(e)
        }finally {
            dispatch({type:LOGGED_OUT})
        }
    }
}
