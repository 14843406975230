import React from 'react';
import {Link} from "react-router-dom";
import { connect } from 'react-redux';
import {toggleLeftBar, logout, changeStore, monitorCheckIns } from '../reducers/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { io } from "socket.io-client";

class Header extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            showError: false
        }
    }

    componentDidMount() {
        const store_id = this.props.store.store_id;
        this.socket = io("https://api2.otopac.com.hk", {
            withCredentials:true,
            extraHeaders: {
                "store-id": store_id
            },
        });
        this.socket.on("refresh_checkin", () => {
            console.log('refresh_checkin');
            this.props.monitorCheckIns();
        });
        this.socket.on("connect", () => {
            this.props.monitorCheckIns();
        });
        this.socket.connect();
        //this.i = setInterval(this.props.monitorCheckIns,60000)
    }

    componentWillUnmount() {
        //clearInterval(this.i)
    }

    render(){
        const {showError} = this.state;
        const {user, toggleLeftBar, errors, stores, store, checkIns} = this.props;
        return  <React.Fragment>
                <div className="container-fluid" style={{backgroundColor:'#fff'}}>
                <div className="row align-items-center">
                    <div className="col-5">
                        <button onClick={toggleLeftBar} className="btn navbar-toggler" type='btn'>
                            <FontAwesomeIcon icon='bars'/>
                        </button>
                    </div>
                    <div className="col-2 text-center">
                        <a href={'/'}>
                            <h5 className="navbar-brand">
                                <span className="align-middle font-weight-bold" style={{color:'#f2321f', fontSize:'1.5rem'}}>{store.full_title} POS</span>
                            </h5>
                        </a>
                    </div>
                    <div className="col-5 text-right">
                        <div className="d-inline-block mr-5">
                            {user && <div className="d-inline">
                                <span className="hidden-sm-down">{user.nick_name}，你好!</span>
                            </div>}
                        </div>
                        <div className="mr-3 d-inline-block">
                            <div className="nav-holder">
                                <span>設定</span>
                                <div className="dropdown">
                                    <div className="p-2" style={{backgroundColor:'#fff'}}>
                                        <Link to={'/users'} className="d-block">
                                            用戶設定
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-inline-block mr-3 px-4">
                            <div className="nav-holder">
                                <span>{store.title}</span>
                                <div className="dropdown">
                                    {stores.filter((s)=>s.store_id !== store.store_id).map((s)=>
                                        <div key={s.store_id} onClick={()=>this.props.changeStore(s.store_id)} className="p-2" style={{backgroundColor:'#000', color:'#fff', cursor:'pointer'}}>{s.title}</div>)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="d-inline-block mr-3" style={{cursor:'pointer'}} onClick={()=>this.setState({showError:true})}>
                            <span className="p-2 bg-danger text-white font-weight-bold rounded-circle" style={{width:20, height:20}}>{errors.length}</span>個錯誤
                        </div>
                        {user &&
                        <div className="d-inline-block mr-3" style={{cursor:'pointer'}} onClick={this.props.logout}>
                            登出
                        </div>
                        }
                    </div>
                </div>
            </div>
            {showError &&
            <div style={{position:'absolute', left:0, right:0, top:0, bottom:0, backgroundColor:'#ff0000c9', zIndex:100, cursor:'pointer'}} onClick={()=>this.setState({showError:false})}>
                {errors.map((e, i )=><div key={i} className="p-2" style={{color:'#fff'}}>{e}</div>)}
            </div>
            }
            <CheckInWarning checkIns={checkIns}/>
        </React.Fragment>
    }
}

function CheckInWarning({checkIns}){
    const _checkins = checkIns.filter(({type, start_time})=> start_time===null && type===null).map(({nick_name})=>nick_name).join(',');
    if(_checkins.length ===0){
        return <div/>
    }
    return <div className="bg-warning p-3">
        <h5 className="font-weight-bold">請注意! {_checkins} 仲未打卡返工! <Link className="text-danger" to="/checkin">請前往打卡!</Link></h5>
    </div>
}

export default connect(
    (state)=>{return {user:state.core.user, errors: state.core.errors, stores:state.core.stores, store:state.core.store, checkIns:state.core.checkIns}},
    {toggleLeftBar, logout, changeStore, monitorCheckIns})(Header)
