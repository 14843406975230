import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
class SelectInput extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            items:[],
            value:'',
            active:false,
            selected:false
        };
        this.onTextChange = this.onTextChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    componentDidMount(){
        let {items, value, title} = this.props;
        let item =  items.find((item)=>item.id === +value);
        this.setState({items})
    }

    componentWillReceiveProps(props){
        let {items, value} = props;
        if(items !== this.props.items || value !== this.props.value){
            items = items.filter((item)=> !value.includes(item.id));
            this.setState({items});
        }
    }

    onTextChange(e){
        let {items, title} = this.props;
        let value = e.target.value;
        let _items = items.filter((item)=>title? item[title].toLowerCase().includes(value.toLowerCase()): item.title.toLowerCase().includes(value.toLowerCase()));
        this.setState({items:_items, value, selected:false})
    }

    onClick(id){
        let {value} = this.props;
        let _value = [...value, id];
        this.props.onChange(_value);
        this.refs.input.blur();
        this.setState({active:false, selected:true});
    }

    remove(index){
        let {value} = this.props;
        let _value = [...value];
        _value.splice(index, 1);
        this.props.onChange(_value);
    }

    onBlur(){
        if(!this.state.selected){
            this.setState({value:''});
        }
        this.setState({active:false});
    }

    render() {
        const {title, value : values, items:_items, valueKey} = this.props;
        let id = 'id';
        if(valueKey){
            id = valueKey;
        }
        let {items, value, active} = this.state;
        let _values = values.map((v)=> _items.find((i=>i[id] === v))).filter((i)=>i);
        return (
            <div style={{position:'relative'}}>
                <div style={{zIndex:100, border:'1px solid #aaa'}} className="p-1">
                    {_values.map((v, index)=><button key={index}
                                                     onClick={()=>this.remove(index)}
                                                     className="btn btn-sm btn-primary mr-2">{title ? v[title]:v.title}
                                                     <FontAwesomeIcon icon='times' fixedWidth={true}/></button>)
                    }
                    <input style={{border:'none'}} value={value}
                           ref="input"
                           onChange={this.onTextChange}
                           onFocus={()=>this.setState({active:true})}
                           onBlur={this.onBlur}/>
                </div>
                <div style={{display:active?'block':'none', position:'absolute', left:0, right:0, top:'100%', maxHeight:400, overflowY:'scroll', zIndex:200}} className="bg-inverse text-white">
                    {items.map((item)=><div key={item[id]} style={{cursor:'pointer'}} className="p-2"
                                            onMouseDown={event => event.preventDefault()}
                                            onClick={()=>this.onClick(item[id])}>{title ? item[title]:item.title}</div>)}
                </div>
            </div>
        );
    }
}

export default SelectInput