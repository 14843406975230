import React from "react";
import Sales from './common/Sales'
class Invoices extends React.Component{

    componentDidMount(){
        this.generateReport();
    }

    componentDidUpdate(){
        if(this.props.open){
            this.generateReport();
        }
    }

    generateReport(){
        let mywindow = window.open('', 'PRINT', 'height=600,width=800');
        mywindow.document.write('<html>');
        //adding the current css file in the head
        mywindow.document.write(document.head.innerHTML);
        mywindow.document.write('<body>');
        mywindow.document.write(this.refs.container.innerHTML);
        mywindow.document.write('</body></html>');
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
        mywindow.onbeforeunload = this.props.close
    }

    render() {
        const {items, files} = this.props;
        console.log(files)
        return (
            <div className="mt-3">
                <div ref='container' className="d-none">
                {items.map((item, index)=>{
                   return <div key={index} className="container-fluid print-block" media="print" style={{color:'#252b75ff', backgroundColor:'#fff', pageBreakBefore:'always'}}>
                       <div className="row no-gutters">
                           <div className="col-6  pl-1 pr-2">
                               <Sales sales={item.sales1} profitMode={true}/>
                           </div>
                           <div className="col-6 pl-2 pr-2">
                               {item.sales2 &&
                               <Sales sales={item.sales2} profitMode={true}/>
                               }
                           </div>
                       </div>
                    </div>
                })}
                    {files&& files.map((item, index)=>{
                        return <div key={index} className="h-100 w-100 print-block" media="print"
                                    style={{color:'#252b75ff', backgroundColor:'#fff',
                                        backgroundImage:`url(https://api2.otopac.com.hk/files/${item.path})`,
                                        backgroundSize:'contain' ,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center center',
                                        pageBreakBefore:'always'}}>
                        </div>
                    })}
                </div>
            </div>
        );
    }
}

export default Invoices