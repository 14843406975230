import React from "react";
import {post} from '../../axios'
import {connect} from 'react-redux';

class FileUpload extends React.Component {
    constructor(props){
        super(props);
    }

    async upload(files){
        const {post, uploaded, single} = this.props;
        try{
            let formData = new FormData();
            console.log(files);
            for(let i=0; i< files.length; i++){
                formData.append('files', files[i]);
            }
            console.log(formData);
            let data = await post('/files', formData, {
                'Content-Type': 'multipart/form-data'
            });
            if(data && data.length>0){
                if(single){
                    uploaded(data[0]);
                }else{
                    uploaded(data);
                }
            }
        }catch (e) {
            console.error(e)
        }
    }

    render(){
        const {single} = this.props;
        if(single){
            return <div><input type="file"
                               onChange={(e)=>this.upload(e.target.files)}/></div>
        }
       return <div><input multiple type="file"
                          onChange={(e)=>this.upload(e.target.files)}/></div>
    }
}

export default connect((state)=>{return {}}, {post})(FileUpload)