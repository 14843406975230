import React from 'react';
import {connect} from 'react-redux';
import SelectInput from "../common/SelectInput";
import Date from "../common/Date";
import {getFormData, submit, getItem} from "../../reducers/returns";
import {get} from '../../axios'
import DateInput from "../common/DateInput";
import {awaitAll} from  '../../helper';
import TrackingCodeInput from "../common/TrackingCodeInput";
import ServiceInput from "../common/ServiceInput";
import OtherItemInput from "../common/OtherItemInput";
import ProductTrackingCodeInput from "../common/ProductTrackingCodeInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReceivePayment from "../component/ReceivePayment";
class ReturnItem extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            items:[],
            payment: {
                reference_number:'',
                remarks:'',
            },
            tab:0,
            edit:null,
            data:null
        };
        this.addItem = this.addItem.bind(this);
        this.editItem = this.editItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.submit = this.submit.bind(this);
    }


    componentDidMount(){
        if(this.props.match && this.props.match.params.id){
            this.props.getItem(this.props.match.params.id);
        }
        this.getData();
    }

    componentWillReceiveProps(props){
        if(props.item && props.item !== this.props.item){
            console.log(props.item);
            this.setState({...props.item})
        }
    }
    async getData(){
        let {getFormData, get} = this.props;
        try{
            let suppliers = get(`/suppliers`);
            let data = await awaitAll({suppliers});
            getFormData(data)
        }catch (e) {
            console.error(e)
        }
    }

    submit(){
        console.log('submit');
        let {history} = this.props;
        this.props.submit( this.state, history);
    }

    addItem(item){
        let {items} = this.state;
        console.log(item);
        let {cost} = item;
        let _item = {...item, price:cost};
        items.push(_item);
        this.setState({items, tab:null});
    }

    editItem(item){
        let {items, edit} = this.state;
        let _items = items.filter((item)=>!item.is_deleted).map((i,index)=>{
            if(index === edit){
                return item
            }else{
                return i
            }
        });
        this.setState({items : _items, edit:null});
    }

    removeItem(tracking_id){
        let {items} = this.state;
        let _items = items.map((item)=>{
            if(tracking_id === item.tracking_id){
                return {...item, is_deleted:true}
            }else{
                return item
            }
        });
        this.setState({items : _items, edit:null});
    }


    render() {
        const {data} = this.props;
        const {items, tab, return_number, created_at, return_date, supplier_id, payment} = this.state;
        if(data === null){
            return <div/>
        }
        let activeTab = 'btn btn-sm btn-primary mr-2';
        let inactiveTab = 'btn btn-sm btn-outline-primary mr-2';
        return <div style={{position:'relative', height:'100%'}}>
            <div style={{position:'absolute', left:0, top:0, bottom:0, right:0}}>
                <div className="mt-3">
                    <button className="btn btn-primary btn-sm" onClick={this.submit}>
                        儲存
                    </button>
                </div>
                <div className="p-3 mt-3" style={{background:'#fff'}}>
                    <div className="row">
                        <label className="col-md-2 font-weight-bold">退貨編號</label>
                        <div className="col-md-10 font-weight-bold">
                            {return_number}
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-md-2 font-weight-bold">建立日期</label>
                        <div className="col-md-10 font-weight-bold">
                            <Date date={created_at} time={true}/>
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-md-2 font-weight-bold">退貨日期</label>
                        <div className="col-md-10 font-weight-bold">
                            <DateInput value={return_date} onChange={(v)=>this.setState({return_date:v})}/>
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-md-2 font-weight-bold">供應商</label>
                        <div className="col-md-10 font-weight-bold">
                           <SelectInput items={data.suppliers} onChange={(v)=>this.setState({supplier_id:v})} value={supplier_id}/>
                        </div>
                    </div>
                </div>
                <div className="p-3 mt-3" style={{background:'#fff'}}>
                    <h6 className="font-weight-bold">退貨項目</h6>
                    <table className="table">
                        <thead>
                        <tr>
                            <th>追蹤號碼</th>
                            <th>產品</th>
                            <th>價格</th>
                            <th>數量</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {items.filter((item)=>!item.is_deleted).map((item, index)=><tr key={index}>
                            <td>{item.tracking_id}</td>
                            <td>{item.title}</td>
                            <td>{item.price}</td>
                            <td>{item.quantity}</td>
                            <td><button className="btn btn-sm btn-danger" onClick={()=>this.removeItem(item.tracking_id)}>刪除</button></td>
                        </tr>)}
                        </tbody>
                    </table>
                    <h6>加入項目</h6>
                    <button className={tab===0? activeTab:inactiveTab} onClick={()=>this.setState({tab:0})}>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                        追蹤號碼
                    </button>
                    <button className={tab===2? activeTab:inactiveTab} onClick={()=>this.setState({tab:2})}>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                        輪胎
                    </button>
                    <button className={tab===3? activeTab:inactiveTab} onClick={()=>this.setState({tab:3})}>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                        電池
                    </button>
                    <button className={tab===4? activeTab:inactiveTab} onClick={()=>this.setState({tab:4})}>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                        車鈴
                    </button>
                    <button className={tab===5? activeTab:inactiveTab} onClick={()=>this.setState({tab:5})}>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                        汽車用品
                    </button>
                    <FormWrapper tracking_mode={tab===0}
                                 category_id={tab}
                                 addItem={this.addItem}
                                 items={items.filter((item)=>!item.is_deleted)}/>
                </div>
                <ReceivePayment onChange={(values)=>this.setState({payment: {...payment, ...values}})} values={payment}/>
            </div>
        </div>
    }
}

class FormWrapper extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
    }

    render(){
        const {item} = this.state;
        let {tracking_mode, addItem, items, category_id} = this.props;
        if(tracking_mode){
            return (
                <div style={{border: '1px solid #0275d8'}}>
                    {!item &&
                    <div className="p-3">
                        <TrackingCodeInput onChange={(item)=>addItem({...item, net_price:item.cost})} items={items.filter((item)=>!item.is_deleted)}/>
                    </div>
                    }
                </div>
            );
        }else{
            if(category_id === 1){
                return (
                    <div style={{border: '1px solid #0275d8'}}>
                        {!item &&
                        <div className="p-3">
                            <ServiceInput onChange={(item)=>addItem({...item, net_price:item.cost})} items={items.filter((item)=>!item.is_deleted)}/>
                        </div>
                        }
                    </div>
                );
            }else if(category_id === 6){
                return   <div style={{border: '1px solid #0275d8'}}>
                    {!item &&
                    <div className="p-3">
                        <OtherItemInput
                            onChange={(item)=>addItem({...item, net_price:item.cost})}/>
                    </div>
                    }
                </div>
            }
            return (
                <div style={{border: '1px solid #0275d8'}}>
                    {!item &&
                    <div className="p-3">
                        <ProductTrackingCodeInput
                            onChange={(item)=>addItem({...item, net_price:item.cost})}
                            items={items.filter((item)=>!item.is_deleted)}
                            category_id={category_id}/>
                    </div>
                    }
                </div>
            );
        }
    }
}

export default connect((state)=>{return{data: state.returns.data, item:state.returns.item,}}, {get, getItem, getFormData, submit})(ReturnItem);