import {deleteRequest, get, post} from '../axios';
const GET_FORM_DATA = 'GET_TEMP_SALE_FORM_DATA';
const GET_ITEMS = 'GET_TEMP_SALES';
const GET_ITEM = 'GET_TEMP_SALE_ITEM';
const initialState= {
    data:null,
    list:[],
    item:null,
    return:null
};


export default function (state = initialState, action){
    switch (action.type){
        case GET_ITEMS:
            return Object.assign({}, state, {list: action.data});
        case GET_FORM_DATA:
            return Object.assign({}, state, {data: action.data});
        case GET_ITEM:
            return Object.assign({}, state, {item: action.data});
        default:
            return state;
    }
}

export function getItems(){
    return async (dispatch)=>{
        let data = await dispatch(get(`/tempSales`));
        return dispatch({type:GET_ITEMS, data:data})
    }
}

export function getItem(id){
    return async (dispatch)=>{
        let data = await dispatch(get(`/tempSales/${id}`));
        return dispatch({type:GET_ITEM, data:data})
    }
}

export function getFormData(data){
    return  {type:GET_FORM_DATA, data}
}

export function cancel(id, history){
    return async (dispatch)=>{
        try{
            await dispatch(deleteRequest(`/tempSales/${id}`));
            history.push('/sales')
        }catch (e) {
            console.log(e)
        }
    }
}

export function submit(data, history, convert){
    return async (dispatch)=>{
        try{
            await dispatch(post(`/tempSales/${data.id?data.id:''}`, data));
            if(convert){
                history.push(`/sales/item?temp_sales_id=${data.id}`)
            }else{
                history.push('/sales')
            }
        }catch (e) {
            console.log(e)
        }
    }
}