import React from 'react';
class SelectInput extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            items:[],
            value:'',
            active:false,
            selected:false,
            key:'id'
        };
        this.onTextChange = this.onTextChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    componentDidMount(){
        let {key} = this.state;
        let {items, value, title, uniqueKey} = this.props;
        if(uniqueKey){
            key = uniqueKey;
            this.setState({key});
        }
        let item =  items.find((item)=>item[key] === +value);
        if(item){
            this.setState({value:title ? item[title]: item.title, items})
        }else{
            this.setState({items})
        }
    }

    componentWillReceiveProps(props){
        let {key} = this.state;
        let {items, value, title, uniqueKey} = props;
        if(uniqueKey && uniqueKey !== this.props.uniqueKey){
            key = uniqueKey;
            this.setState({key});
        }
        if(items !== this.props.items || value !== this.props.value){
            this.setState({items});
            let item =  items.find((item)=>item[key] === value);
            if(item){
                this.setState({value:title ? item[title]: item.title, items})
            }
        }
    }

    onTextChange(e){
        let {items, title} = this.props;
        let value = e.target.value;
        let _items = items.filter((item)=>title? item[title].toLowerCase().includes(value.toLowerCase()): item.title.toLowerCase().includes(value.toLowerCase()));
        this.setState({items:_items, value, selected:false})
    }

    onClick(id){
        this.props.onChange(id);
        this.refs.input.blur();
        this.setState({active:false, selected:true});
    }

    onBlur(){
        if(!this.state.selected){
            this.setState({value:''});
        }
        this.setState({active:false});
    }

    render() {
        const {title} = this.props;
        let {items, value, active, key} = this.state;
        return (
            <div style={{position:'relative'}}>
                <input style={{zIndex:100}} value={value}
                       ref="input"
                       onChange={this.onTextChange}
                       onFocus={()=>this.setState({active:true})}
                       onBlur={this.onBlur}/>
                <div style={{display:active?'block':'none', position:'absolute', left:0, right:0, top:'100%', maxHeight:400, overflowY:'scroll', zIndex:200}} className="bg-inverse text-white">
                    {items.slice(0,90).map((item)=><div key={item[key]} style={{cursor:'pointer'}} className="p-2"
                                            onMouseDown={event => event.preventDefault()}
                                            onClick={()=>this.onClick(item[key])}>{title ? item[title]:item.title}</div>)}
                </div>
            </div>
        );
    }
}

export default SelectInput