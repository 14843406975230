import React from 'react';
import {getItems as getUsers} from '../../reducers/user'
import {getItems as getSuppliers} from '../../reducers/supplier'
import {connect} from 'react-redux';
import SelectInput from "../common/SelectInput";
import DateInput from "../common/DateInput";
import {Link} from "react-router-dom";
import {get} from "../../axios";
class Payment extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            paymentMethods:[]
        }
    }

    componentDidMount(){
        this.props.getSuppliers();
        this.props.getUsers();
        this.getData();
    }

    async getData(){
        let {get} = this.props;
        try{
            let paymentMethods =  await get(`/payment_methods`);
            this.setState({paymentMethods});
        }catch (e) {
            console.error(e)
        }
    }

    render(){
        let {paymentMethods} = this.state;
        let {onChange, values, users, suppliers} = this.props;
        return  <div className="p-3 mt-3" style={{background:'#fff'}}>
            <h6 className="font-weight-bold">建立付款帳</h6>
            <div className="container-fluid">
                <div className="row">
                    <label className="col-md-2 font-weight-bold">付款帳號碼</label>
                    <div className="col-md-10 font-weight-bold">
                        <Link className={"btn btn-sm btn-primary"} to={'/payable/item/'+ values.invoice_number}>{values.invoice_number}</Link>
                    </div>
                </div>
                <div className="row">
                    {!values.invoice_number &&
                    <div className="col-md-4">
                        <label>付款方式 *(如非即時付款，我們會建立應付帳單)</label>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <select className="form-control" value={values.payment_method} onChange={(e)=>onChange({payment_method: e.target.value})}>
                                    <option>選擇付款方式</option>
                                    <option value='1'>即時</option>
                                    <option value='2'>後續</option>
                                </select>
                            </div>
                            <div className="col-8">
                                {+values.payment_method === 1 &&
                                <SelectInput items={paymentMethods}
                                             onChange={(id) => onChange({payment_method_id: id})}
                                             value={values.payment_method_id}/>
                                }
                            </div>
                        </div>
                    </div>
                    }
                    <div className="col-md-4">
                        <label>入貨員*</label>
                        <SelectInput items={users} onChange={(id)=>onChange({purchaser_id: id})} value={values.purchaser_id} title="nick_name"/>
                    </div>
                    <div className="col-md-4">
                        <label>帳單日期 *</label>
                        <DateInput onChange={(date)=>onChange({invoice_date:date})}  value={values.invoice_date}/>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-4">
                        <label>供應商*</label>
                        <SelectInput items={suppliers} onChange={(id)=> onChange({supplier_id: id})} value={values.supplier_id}/>
                    </div>
                    <div className="col-md-4">
                        <label>供應商紀錄號碼</label>
                        <input className="form-control" onChange={(e)=>onChange({reference_number: e.target.value})}  value={values.reference_number}/>
                    </div>
                    <div className="col-md-4">
                        <label>備註</label>
                        <textarea className="form-control" onChange={(e)=>onChange({remarks: e.target.value})}  value={values.remarks}/>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default connect((state)=>{return {users:state.user.list, suppliers:state.supplier.list}}, {getUsers, getSuppliers, get})(Payment)