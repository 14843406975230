import React, {Fragment} from 'react';
import DateInput from '../../common/DateInput'
import moment from 'moment';
import {connect} from 'react-redux';
import {get} from "../../../axios";
class Cashflow extends React.Component{
    constructor(props){
        super(props);
        this.state = {data:[], detail:null, start_date:moment().startOf('month').toDate(), end_date:moment().toDate()};
        this.getData = this.getData.bind(this);
    }
    componentDidMount(){
        this.getData();
    }

    async getData(e){
        if(e){
            e.preventDefault();
        }
        let {start_date, end_date} = this.state;
        let detail = await this.props.get('/report/cashflow/detail', {start_date, end_date});
        let data = {};
        const initialObject = {sales:0, receivable:0, purchases:0, purchases_payable:0, expenditure:0, expenditure_payable:0, return_receivable:0, returns:0};
        let {cash_from_sales, cash_from_receivable, cash_to_purchases, cash_to_purchases_payable, cash_to_expenditure, cash_to_expenditure_payable, cash_from_returns_receivable, cash_from_returns} = detail;
        cash_from_sales.forEach((c)=>{
           if(!data[c.payment_method]){
               data[c.payment_method] = {...initialObject};
           }
            data[c.payment_method].sales = c.total
        });
        cash_from_receivable.forEach((c)=>{
            if(!data[c.payment_method]){
                data[c.payment_method] = {...initialObject};
            }
            data[c.payment_method].receivable = c.total
        });
        cash_from_returns.forEach((c)=>{
            if(!data[c.payment_method]){
                data[c.payment_method] = {...initialObject};
            }
            data[c.payment_method].returns = c.total
        });
        cash_from_returns_receivable.forEach((c)=>{
            if(!data[c.payment_method]){
                data[c.payment_method] = {...initialObject};
            }
            data[c.payment_method].return_receivable = c.total
        });
        cash_to_purchases.forEach((c)=>{
            if(!data[c.payment_method]){
                data[c.payment_method] = {...initialObject};
            }
            data[c.payment_method].purchases = c.total
        });
        cash_to_purchases_payable.forEach((c)=>{
            if(!data[c.payment_method]){
                data[c.payment_method] = {...initialObject};
            }
            data[c.payment_method].purchases_payable = c.total
        });
        cash_to_expenditure.forEach((c)=>{
            if(!data[c.payment_method]){
                data[c.payment_method] = {...initialObject};
            }
            data[c.payment_method].expenditure = c.total
        });
        cash_to_expenditure_payable.forEach((c)=>{
            if(!data[c.payment_method]){
                data[c.payment_method] = {...initialObject};
            }
            data[c.payment_method].expenditure_payable = c.total
        });

        this.setState({data})
    }

    render(){
        let {data, start_date, end_date} = this.state;
        return <div className="p-3">
            <h5 className="font-weight-bold mt-3">詳細資金統計</h5>
            <form onSubmit={this.getData}>
                <h6 className="d-flex mt-3">
                    <span className="mr-2">
                        開始日期
                    </span>
                    <DateInput value={start_date} onChange={(v)=>this.setState({start_date:v})}/>
                    <span className="mx-2">-</span>
                    <span className="mx-2">
                        最後日期
                    </span>
                    <DateInput value={end_date} endTime={true} onChange={(v)=>this.setState({end_date:v})}/>
                    <button className="mx-2 btn btn-sm btn-primary">確定</button>
                </h6>
            </form>
            <div className="">
                {data &&
                <div className="table-responsive">
                    <table className="table bg-white">
                        <thead>
                        <tr>
                            <td>付款方式</td>
                            <td>來自銷售資金</td>
                            <td>來自收數資金</td>
                            <td>來自退貨資金</td>
                            <td>來自退貨收數資金</td>
                            <td>購貨使用資金</td>
                            <td>購貨過往資金</td>
                            <td>支出使用資金</td>
                            <td>支出過往資金</td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        {Object.keys(data).map((key)=> <tr key={key}>
                            <td>{key}</td>
                            <td>{data[key].sales.toLocaleString()}</td>
                            <td>{data[key].receivable.toLocaleString()}</td>
                            <td>{data[key].returns.toLocaleString()}</td>
                            <td>{data[key].return_receivable.toLocaleString()}</td>
                            <td>{data[key].purchases.toLocaleString()}</td>
                            <td>{data[key].purchases_payable.toLocaleString()}</td>
                            <td>{data[key].expenditure.toLocaleString()}</td>
                            <td>{data[key].expenditure_payable.toLocaleString()}</td>
                        </tr>)}
                    </tbody>
                    </table>
                </div>
                }
            </div>
        </div>
    }
}

export default connect((state)=>{return{}}, {get})(Cashflow)