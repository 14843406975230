import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {get, post} from '../../axios'
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProductForm from "../component/ProductForm";
function TradeIn({match, history, get, post}){
    const order_number = match.params.id;
    const [edit, setEdit] = useState(null);
    const [tab, setTab] = useState(null);
    const [items, setItems] = useState([]);
    const activeTab = 'btn btn-sm btn-primary mr-2';
    const inactiveTab = 'btn btn-sm btn-outline-primary mr-2';
    function addItem(item){
        const _items = [...items, item];
        setItems(_items);
    }

    useEffect(()=>{
        async function getDate(){
            const items = await get(`/sales/${order_number}/tradeIn`);
            setItems(items);
        }
        getDate();
    },[]);

    async function submit(){
        try{
            if(items.length>0){
                await post(`/sales/${order_number}/tradeIn`, {items});
                history.push(`/sales/item/${order_number}`)
            }
        }catch (e) {
            console.error(e)
        }
    }


    function editItem(item){
        let _items = items.filter((item)=>!item.is_deleted).map((i,index)=>{
            if(index === edit){
                return item
            }else{
                return i
            }
        });
        setItems(_items);
        setEdit(null);
    }

    function removeItem(index){
        let _items = items.map((item , i)=>{
            if(index === i){
                return {...item, is_deleted:true}
            }else{
                return item
            }
        });
        setItems(_items);
        setEdit(null);
    }

    return <div style={{position:'relative', height:'100%'}}>
        <div style={{position:'absolute', left:0, top:0, bottom:0, right:0}}>
            <div className="mt-3">
                <button className="btn btn-primary btn-sm" onClick={submit}>
                    完成Trade In
                </button>
            </div>
            <div className="mt-2">
                <Link className={ "custom-tab"} to={"/sales/item/"+order_number}>
                    銷售
                </Link>
                <Link className={"custom-tab active"}  to={"/sales/item/"+order_number+"/tradeIn"}>
                    Trade In
                </Link>
            </div>
            <div className="p-3" style={{background:'#fff'}}>
                <div className="row">
                    <label className="col-md-2 font-weight-bold">銷售編號</label>
                    <div className="col-md-10 font-weight-bold">
                        {order_number}
                    </div>
                </div>
                <h6 className="font-weight-bold">回收項目</h6>
                <table className="table">
                    <thead>
                    <tr>
                        <th>追蹤號碼</th>
                        <th>產品</th>
                        <th>備註</th>
                        <th>數量</th>
                        <th>價格</th>
                        <th>小計</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {items.map((item, index)=><tr key={index} className={item.is_deleted?"d-none":""}>
                        <td>{item.tracking_id}</td>
                        <td>{item.title}</td>
                        <td>{item.remarks}</td>
                        <td>{item.quantity}</td>
                        <td>{item.price}</td>
                        <td>{+item.price * +item.quantity}</td>
                        <td>
                            <button className="btn btn-sm btn-info" onClick={()=>{
                            setEdit(index);
                            setTab(null);
                        }}>修改</button>
                            <button className="btn btn-sm btn-danger" onClick={()=>{
                            removeItem(index)}}>刪除</button>
                        </td>
                    </tr>)}
                    </tbody>
                </table>
                <h6>加入項目</h6>
                <button className={activeTab} onClick={()=>setTab(5)}>
                    <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                    二手輪胎
                </button>
                {edit !== null && <ProductForm edit={edit} addItem={editItem} items={items}/>}
                {tab !== null && <ProductForm tab={tab} addItem={addItem} items={items}/>}
            </div>
        </div>
    </div>
}

export default connect((state)=>{return{
    user:state.core.user}}, {get, post})(TradeIn);
