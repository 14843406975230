import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {get} from '../../axios';
import {connect} from 'react-redux';
class TrackingCodeInput extends React.Component{
    constructor(props){
        super(props);
        this.state  = {
            text:"",
            quantity:"",
            loading:false,
            error:'',
            submitError:'',
        };
        this.search = this.search.bind(this);
        this.submit = this.submit.bind(this);
    }

    search(e){
        e.preventDefault();
        let {text} = this.state;
        let {get, supplier_id} = this.props;
        this.setState({loading:true, error:''});
        get('/stocks/available', {tracking_id:text, supplier_id}).then((data)=>{
            if(data === null){
                this.setState({error:'找不到', loading:false})
            }else{
                this.setState({data, loading:false})
            }
        }).catch((e)=>{
            this.setState({error:'找不到', loading:false})
        })
    }


    submit(e){
        e.preventDefault();
        let {onChange, items} = this.props;
        let {quantity, data} = this.state;
        if(data.quantity < +quantity) {
            this.setState({submitError: '產品剩餘數量不足'})
        }else if(items && items.find((item)=>item.tracking_id === data.tracking_id)){
            this.setState({submitError: '同一項目不能重複加入'})
        }else{
            onChange({quantity:+quantity, tracking_id: data.tracking_id, title:data.title, price:data.price, product_id:data.product_id, remarks:data.remarks, cost:data.cost})
        }
    }


    render(){
        const {text, data, loading, error, quantity, submitError} = this.state;
        return <div>
            <form className="form" onSubmit={this.search}>
                <div className="row">
                    <label className="col-md-2 font-weight-bold">追蹤號碼</label>
                    <div className="col-md-10 font-weight-bold form-inline">
                        <input className="form-control" value={text} onChange={(e)=>this.setState({text:e.target.value})}/>
                        <button className="btn btn-warning btn-sm ml-2">
                            <FontAwesomeIcon icon='plus' fixedWidth={true} spin={loading}/>
                            搜索
                        </button>
                        <span className="text-danger ml-2">{error}</span>
                    </div>
                </div>
            </form>
            {data &&
            <form className="mt-3 pt-3" style={{borderTop:'1px solid #aaa'}} onSubmit={this.submit}>
                <div>
                    <div className="font-weight-bold">{data.title} 剩餘數量 {data.quantity}</div>
                </div>
                <div className="row mt-3">
                    <label className="col-md-2 font-weight-bold">數量</label>
                    <div className="col-md-10 font-weight-bold form-inline">
                        <input className="form-control" value={quantity} onChange={(e)=>this.setState({quantity:e.target.value})} type="number"/>
                        <button className="btn btn-primary btn-sm ml-2">
                            <FontAwesomeIcon icon='plus' fixedWidth={true} spin={loading}/>
                            加入
                        </button>
                        <span className="text-danger ml-2">{submitError}</span>
                    </div>
                </div>
            </form>
            }
        </div>
    }
}

export default connect((state)=>{return{}},{get})(TrackingCodeInput)