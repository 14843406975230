import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from "react-redux";
import {getItems} from "../../reducers/expenditure";
import Date from "../common/Date";
import Table from "../common/Table";
class Expenditure extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            tab:1
        };
        this.switchTab = this.switchTab.bind(this);
    }

    componentDidMount(){
        this.props.getItems();
    }

    switchTab(tab){
        if(tab ===1){
            this.props.getItems();
        }else if(tab === 2){
            this.props.getItems(true);
        }
        this.setState({tab})
    }

    render() {
        const {list} = this.props;
        const {tab} = this.state;
        const tabClass = "custom-tab";
        const aTabClass = "custom-tab active";
        return (
            <div className="mt-3">
                <div>
                    <Link to='/expenditure/item' className='btn btn-primary btn-sm'>
                        <FontAwesomeIcon icon='plus' fixedWidth={true}/>
                        新支出
                    </Link>
                </div>
                <div className="mt-3">
                    <div className={tab === 1 ? aTabClass: tabClass} onClick={()=>this.switchTab(1)}>
                        已完成支出
                    </div>
                    <div className={tab === 2 ? aTabClass: tabClass}  onClick={()=>this.switchTab(2)}>
                        已取消支出
                    </div>
                </div>
                <div className="custom-tab-body">
                    <Table data={list}
                           accessors={[
                               {key:'order_number', title:'支出編號'},
                               {key:'version', title:'版本'},
                               {key:'total', title:'總數'},
                               {key:'provider', title:'供應商'},
                               {key:'creator', title:'建立者'},
                               {key:'invoice_date', title:'支出日期', render:((item) => <Date date={item.invoice_date}/>)},
                               {key:'created_at', title:'建立日期', render:((item) => <Date date={item.created_at} time={true}/>)},
                               {key:'id', title:'修改', render:((item) => <Link to={`/expenditure/item/${item.order_number}`}>修改</Link>)}
                           ]}
                           tableKey='expenditures'
                           rowKey='id'
                           search={(item, text)=>item.order_number.toUpperCase().includes(text.toUpperCase())}
                    />
                </div>
            </div>
        );
    }
}
export default connect((state)=>{return {list:state.expenditure.list, users:state.user.list}},{getItems})(Expenditure)
