import {deleteRequest, get, post} from '../axios';
const GET_FORM_DATA = 'GET_EXPENDITURE_FORM_DATA';
const GET_ITEMS = 'GET_EXPENDITURES';
const GET_ITEM = 'GET_EXPENDITURE_ITEM';
const initialState= {
    data:null,
    list:[],
    item:null,
    return:null
};


export default function (state = initialState, action){
    switch (action.type){
        case GET_ITEMS:
            return Object.assign({}, state, {list: action.data});
        case GET_FORM_DATA:
            return Object.assign({}, state, {data: action.data});
        case GET_ITEM:
            return Object.assign({}, state, {item: action.data});
        default:
            return state;
    }
}

export function getItems(is_deleted){
    return async (dispatch)=>{
        dispatch({type:GET_ITEMS, data:[]});
        let data = await dispatch(get(`/expenditures`, {is_deleted}));
        return dispatch({type:GET_ITEMS, data:data})
    }
}

export function getItem(id){
    return async (dispatch)=>{
        let data = await dispatch(get(`/expenditures/${id}`));
        return dispatch({type:GET_ITEM, data:data})
    }
}

export function getFormData(data){
    return  {type:GET_FORM_DATA, data}
}


export function submit(data, history){
    return async (dispatch)=>{
        try{
            await dispatch(post(`/expenditures/${data.order_number?data.order_number:''}`, data));
            history.push('/expenditure')
        }catch (e) {
            console.log(e)
        }
    }
}


export function deleteItem(data, history){
    return async (dispatch)=>{
        try{
            await dispatch(deleteRequest(`/expenditures/${data.order_number?data.order_number:''}`, data));
            history.push('/expenditure')
        }catch (e) {
            console.log(e)
        }
    }
}