import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import {get} from '../../axios';
import Table from "../common/Table";
import Date from "../common/Date";
import {Link} from "react-router-dom";
function SupplierPayable({get}){
    const [items, setItems] = useState([]);
    useEffect(()=>{
        get('/payables/supplier').then((data)=>{
            setItems(data);
        })
    }, []);
        return (
            <div className="mt-3">
                <div className="custom-tab-body">
                    <Table data={items}
                           accessors={[
                               {key:'title', title:'應付帳編號'},
                               {key:'amount', title:'總數'},
                               {key:'amount', title:'剩餘', render:((item) => <span>{+item.amount - +item.paid}</span>)},
                               {key:'latest_invoice_date', title:'最後帳單日期', render:((item) => <Date
                                       date={item.latest_invoice_date}/>)},
                               {key:'id', title:'修改', render:((item) => <Link to={`/supplierPayable/item/${item.supplier_id}`}>修改</Link>)}
                           ]}
                           tableKey='supplierPayable'
                           rowKey='supplier_id'
                           search={(item, text)=>item.title.toUpperCase().includes(text.toUpperCase())}
                    />
                </div>
            </div>
        );
}

export default connect((state)=>{return {}},{get})(SupplierPayable)
