import React from 'react';
import {NavLink } from "react-router-dom";
import {getItems} from "../../reducers/user";
import { connect } from 'react-redux';
import user from "../../reducers/user";
class User extends React.Component{

    componentDidMount(){
        this.props.getItems();
    }

    render() {
        const {list} = this.props;
        return (
            <div style={{maxWidth:800, backgroundColor:'#fff'}} className="mx-auto p-3 mt-3">
                <h5 className="font-weight-bold">用戶設定</h5>
                <div className="container mt-2">
                    {list.map((user)=>{
                        return <div key={user.id} className="row bg-faded p-2 my-2">
                            <div className="col-2 font-weight-bold">{user.id}</div>
                            <div className="col-4 font-weight-bold">{user.nick_name}</div>
                        </div>
                    })}
                </div>
            </div>
        );
    }
}


export default connect((state)=>{return {list:state.user.list}}, {getItems})(User)