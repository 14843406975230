import React,{useState, useEffect, useMemo} from 'react';
import moment from 'moment';
import {get, post, deleteRequest} from "../../axios";
import {useDispatch, useSelector} from 'react-redux'
export default function Human(){
    const[users, setUsers]= useState([]);
    const[month, setMonth]= useState(moment().format('MM/YYYY'));
    const[checkIns, setCheckIns]= useState([]);
    const[workShifts, setWorkShifts]= useState([]);
    const[holidays, setHolidays] = useState([]);
    const dispatch = useDispatch();
    const getData = async()=>{
        let workShifts = await dispatch(get('/management/workShift', {date:month}));
        workShifts=workShifts.map((ws)=>({...ws, date:moment(ws.date).format('DD/MM/YYYY')}));
        setWorkShifts(workShifts);

        let checkIns = await dispatch(get('/management/checkinReport', {date:month}));
        checkIns=checkIns.map((ws)=>({...ws, date:moment(ws.date).format('DD/MM/YYYY')}));
        setCheckIns(checkIns);

        const users = await dispatch(get('/users'));
        const _users = users.filter(({is_worker})=>is_worker);
        setUsers(_users);
        let holidays = await dispatch(get('/holidays', {date:month}));
        holidays = holidays.map((h)=>({...h, date:moment(h.date).format('DD/MM/YYYY'), sunday:moment(h.date).days()===0}));
        setHolidays(holidays)
    };

    useEffect(()=>{
        getData();
    },[month]);

    const holidayCount = useMemo(()=>{
        const date = new moment(month,'MM/YYYY');
        date.date(1);
        let dif = (7 + (0 - date.weekday()))%7+1;
        console.log("weekday: "+ 0 +", FirstOfMonth: "+ date.weekday() +", dif: "+ dif);
        return  Math.floor((date.daysInMonth()- dif) / 7)+1 + holidays.filter((h)=>!h.sunday).length;
    },[holidays, month]);
    return <div className="p-2 mt-2">
        <h5>編更表</h5>
        <div className="p-3" style={{backgroundColor:'#fff'}}>
            <form className="form-inline">
                <div className="form-group">
                    <label className="mr-2">選擇月份</label>
                    <select value={month} onChange={(e)=>setMonth(e.target.value)} style={{width:200}}>
                        <option value="" title="select"/>
                        <Month/>
                    </select>
                </div>
            </form>
            <div className="mt-2">
                {month} 一共有<span className="h3">{holidayCount}</span>日例假
            </div>
            <Summary  users={users} workShifts={workShifts}/>
            <Calendar month={month} users={users} holidays={holidays} workShifts={workShifts} checkIns={checkIns} getData={getData}/>
        </div>
    </div>
}

const Month = ()=>{
    const currentMonth = moment('12/2021', 'MM/YYYY');
    const options = [];
    for (let i = 0; i<= 48; i++){
        options.push(<option value={currentMonth.format('MM/YYYY')} key={i}>{currentMonth.format('MM/YYYY')}</option>)
        currentMonth.add(1, 'month');
    }
    return options
};

const Summary = ({users, workShifts})=>{
    const values = [
        {id:1, title:'例假'},
        {id:2, title:'病假'},
        {id:3, title:'請假'},
        {id:4, title:'補假'},
        {id:5, title:'年假'},
    ];
    return <div className="mt-5">
        <table className="table table-responsive text-center">
            <thead>
                <tr>
                    <th style={{width:200}}>總計</th>
                    {users.map((user)=><th key={user.id} className="text-center" style={{width:300}}>{user.nick_name}</th>)}
                </tr>
            </thead>
            <tbody>
            {values.map((v)=><tr key={v.id}>
                <th>{v.title}</th>
                {users.map((user)=>{
                    const count = workShifts.filter((ws)=>ws.user_id===user.id && ws.type===v.id).reduce((a,b)=>{
                       return a+ (b.day_type===1?1:0.5)
                    },0);
                    return <td key={user.id} className="font-weight-bold">
                        {count}
                    </td>
                })}
            </tr>)}
            </tbody>
        </table>
    </div>
};

const Calendar = ({month, users, holidays, workShifts, checkIns, getData})=>{
    const _date1 = moment(month,'MM/YYYY').startOf('month');
    const days = useMemo(()=>{
        return moment(month,'MM/YYYY').endOf('month').daysInMonth();
    },[month]);
    const store_id= useSelector((state)=>state.core.store_id)
    const items = [];
    for(let i=0; i < days; i++){
        items.push({title:_date1.format('DD/MM dddd'), day:_date1.days(), date:_date1.format('DD/MM/YYYY')});
        _date1.add(1,'day');
    }
    return <div className="mt-5">
        <table className="table table-responsive">
            <thead>
            <tr>
                <th style={{width:200}}>日期</th>
                {users.map((user)=><th key={user.id} className="text-center" style={{width:300}}>{user.nick_name}</th>)}
            </tr>
            </thead>
            <tbody>
            {items.map((item)=>{
                const holiday = holidays.find((h)=>h.date===item.date);
                const _workShifts = workShifts.filter((ws)=>ws.date === item.date);
                const _checkIns = checkIns.filter((ws)=>ws.date === item.date);
                return <tr key={item.date} style={{backgroundColor:(item.day===0||holiday) ? 'orange':''}}>
                    <td className="font-weight-bold">
                        <div>{item.title}</div>
                        {holiday &&
                        <small className="font-weight-bold">({holiday.title})</small>
                        }
                    </td>
                    {users.map((user)=><td key={user.id}>
                       <Selector user={user} date={item.date} store_id={store_id}
                                 workShift={_workShifts.find((ws)=>ws.user_id===user.id)}
                                 checkIn={_checkIns.find((ws)=>ws.user_id===user.id)}
                                 getData={getData}/>
                    </td>)}
                </tr>
            })}
            </tbody>
        </table>
    </div>
};


function Selector({user, date, workShift, checkIn, getData, store_id}){
    const dispatch = useDispatch();
    const [type, setType] = useState('');
    const [day_type, setDay] = useState('1');
    const [active, setActive] = useState(false);
    const [error, setError] = useState(false);
    const currentUser = useSelector((state)=>state.core.user);
    const submit = async ()=>{
      setActive(false);
      await dispatch(post('/management/workShift/'+(workShift?workShift.id:''), {date, user_id:user.id, type, day_type:day_type}));
      await getData()
    };

    const remove = async ()=>{
        setActive(false);
        await dispatch(deleteRequest('/management/workShift/'+workShift.id, ));
        await getData()
    };

    const activate = ()=>{
        const day1 = moment(date, 'DD/MM/YYYY');
        const day2 = moment().endOf('week').add(1,'day').toDate()
        if (day1.isBefore(day2) && !currentUser.is_admin && !currentUser.is_manager){
            setError('如需更改假期，請通知主管。')
        }else{
            setActive(true);
        }
    };
    if(error){
        return <div className="text-center">
            <span href="#" className="text-danger">{error}</span>
        </div>
    }
    if(active){
        return <div>
            <div>
                <select style={{width:60}} onChange={(e)=>setDay(e.target.value)} value={day_type}>
                    <option value="1">全日</option>
                    <option value="2">上午</option>
                    <option value="3">下午</option>
                </select>
                <select style={{width:120}} onChange={(e)=>setType(e.target.value)} value={type}>
                    <option>選擇</option>
                    <option value="1">例假</option>
                    <option value="2">病假</option>
                    <option value="3">請假</option>
                    <option value="4">補假</option>
                    <option value="5">年假</option>
                </select>
            </div>
            <button className="btn btn-sm badge-pill" onClick={submit}>確定</button>
            {workShift&&
            <button className="btn btn-sm badge-pill btn-danger" onClick={remove}>Delete</button>
            }
            <button className="btn btn-sm badge-pill btn-danger-mute" onClick={()=>setActive(false)}>取消</button>
        </div>
    }
    let w =' 一 ', checkin = '';
    if(workShift) {
        switch (workShift.type) {
            case 1:
                w = '例假';
                break;
            case 2:
                w = '病假';
                break;
            case 3:
                w = '事假';
                break;
            case 4:
                w = '補假';
                break;
            case 5:
                w = '年假';
                break;
        }
        switch (workShift.day_type) {
            case 2:
                w += '(上午)';
                break;
            case 3:
                w += '(下午)';
                break;
        }
    }
    if(checkIn){
        if(checkIn.start_time){
            checkin += `上班:${moment(checkIn.start_time).format('HH:mm')}`
        }
        if(checkIn.end_time){
            checkin += `- 下班:${moment(checkIn.end_time).format('HH:mm')}`
        }
    }
    return <div className="text-center">
        <a href="#" className="text-primary" onClick={()=>activate(true)}>{w}</a>
        <div>
            <small className="font-weight-bold">{checkin}</small>
            {checkIn && checkIn.checkin_store && checkIn.checkin_store_id !== store_id &&
            <small className="font-weight-bold text-danger"> ({checkIn.checkin_store})</small>
            }
        </div>
    </div>
}