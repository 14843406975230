import React from 'react';
import {connect} from 'react-redux';
import {getFormData} from '../../reducers/product'
import {get} from '../../axios'
import {awaitAll} from '../../helper'
import SelectInput from '../common/SelectInput';
class Tyre extends React.Component{
    componentDidMount(){
        this.getData();
    }

    async getData(){
        let {getFormData, get} = this.props;
        try{
            let brands = get(`/tyres/brands`);
            let patterns = get(`/tyres/patterns`);
            let origins = get(`/products/origins`);
            let data = await awaitAll({brands, patterns, origins});
            getFormData('tyre', data)
        }catch (e) {
            console.error(e)
        }
    }

    render() {
        let {data, onChange, values} = this.props;
        let {brand_id, pattern_id, origin_id, loading, speed, width, height, diameter} = values;
        if(!data){
            return <div/>
        }
        return  <div className="p-3 mt-3" style={{background:'#fff'}}>
            <h6 className="font-weight-bold">輪胎資料</h6>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4">
                        <label>品牌*</label>
                        <SelectInput items={data.brands} value={brand_id} onChange={(v)=>onChange({brand_id:v})}/>
                    </div>
                    <div className="col-md-4">
                        <label>花紋*</label>
                        <SelectInput items={brand_id? data.patterns.filter((p)=>p.brand_id === brand_id):data.patterns} value={pattern_id} onChange={(v)=>onChange({pattern_id:v})}/>
                    </div>
                    <div className="col-md-4">
                        <div className="row no-gutters">
                            <div className="col-4">
                                <label>產地</label>
                                <SelectInput items={data.origins} value={origin_id} onChange={(v)=>onChange({origin_id:v})}/>
                            </div>
                            <div className="col-4">
                                <label>負重</label>
                                <input className="form-control" value={loading} type="number"  onChange={(v)=>onChange({loading:v.target.value.toUpperCase()})}/>
                            </div>
                            <div className="col-4">
                                <label>速度</label>
                                <input className="form-control" value={speed}  onChange={(v)=>onChange({speed:v.target.value})}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-4">
                        <label>胎面寬度 eg 225*</label>
                        <input className="form-control" type="number" value={width} onChange={(v)=>onChange({width:v.target.value})}/>
                    </div>
                    <div className="col-md-4">
                        <label>扁平比 eg 45*</label>
                        <input className="form-control" type="number" value={height} onChange={(v)=>onChange({height:v.target.value})}/>
                    </div>
                    <div className="col-md-4">
                        <label>輪輞直徑 eg 19*</label>
                        <input className="form-control" value={diameter} onChange={(v)=>onChange({diameter:v.target.value})}/>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default connect((state)=>{return{ data:state.product.data['tyre']}},{getFormData, get})(Tyre)