import React, {Fragment} from 'react';
import DateInput from '../../common/DateInput'
import {connect} from 'react-redux';
import {get, post} from "../../../axios";
import moment from 'moment';
import Table from "../../common/Table";
class Inventory extends React.Component{
    constructor(props){
        super(props);
        this.state = {data:[], detail:null, start_date:moment().startOf('month').toDate(), end_date:moment().toDate()};
        this.generateQRCode =this.generateQRCode.bind(this);
    }
    componentDidMount(){
        this.getData();
        this.socket= new WebSocket('ws://localhost:8880');
        this.socket.onopen = function() {
            console.log('Connected!');
        };
        this.socket.onclose  = function(s) {
            console.log(s)
        };
        this.socket.onerror = function(s) {
            alert('Unable to detect any printing software')
        };
    }

    async getData(){
       let data = await this.props.get('/report/inventory');
       this.setState({data})
    }

    generateQRCode(list){
        this.props.post('/report/inventory/qr', {list}).then((list)=>{
            for (let item of list){
                this.socket.send(JSON.stringify(item));
            }
        });
    }

    render(){
        let {generateQRCode} = this;
        let {data} = this.state;
        return <div className="p-3">
            <h5 className="font-weight-bold">倉庫資料</h5>
            <Table data={data}
                   accessors={[
                       {key:'product_id', title:'產品號碼'},
                       {key:'title', title:'產品'},
                       {key:'average_cost', title:'平均成本', render:((item) => Number.parseInt(item.average_cost).toLocaleString())},
                       {key:'quantity', title:'數量'},
                       {key:'product_id', title:'', render:(({items}) => <button className="btn btn-secondary btn-sm" onClick={()=>generateQRCode(items)}>列印QR</button>)},
                   ]}
                   tableKey='report/inventory'
                   rowKey='product_id'
                   expandable={true}
                   search={(item, text)=>{
                       if(!text){
                           return true
                       }
                       return item.title?item.title.toUpperCase().includes(text.toUpperCase()):false;
                   }}
                   hiddenRow={(item)=>{
                       let {items} = item;
                       return items.map((item)=><tr className="text-muted" key={item.tracking_id}>
                           <td>
                               供應商{item.supplier}
                           </td>
                           <td>
                               追蹤號碼{item.tracking_id}
                           </td>
                           <td>
                               {item.price}
                           </td>
                           <td>
                               {item.quantity}
                           </td>
                       </tr>)}}
            />
        </div>
    }
}

export default connect((state)=>{return{}}, {get, post})(Inventory)