import React from 'react';
import Tyre from '../component/Tyre';
import Battery from '../component/Battery';
import Wheel from '../component/Wheel';
import {connect} from 'react-redux';
import {getProductItem, getProductTransactions, submit, deleteItem} from '../../reducers/product';
import Table from "../common/Table";
import Date from "../common/Date";
import {Link} from "react-router-dom";
class InventoryItem extends React.Component{
    constructor(props){
        super(props);
        this.state = this.initialState(props.category_id);
        this.additionalForm = this.additionalForm.bind(this);
        this.delete = this.delete.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount(){
        if(this.props.match && this.props.match.params.id){
            this.props.getProductItem(this.props.match.params.category, this.props.match.params.id);
            this.props.getProductTransactions(this.props.match.params.id);
        }
    }

    componentWillReceiveProps(newProps){
        if(this.props.item !== newProps.item && newProps.item){
            this.setState({...newProps.item})
        }
    }

    initialState(category_id){
        switch (category_id) {
            case 2:
                return {
                    brand_id :null,
                    pattern_id:null,
                    origin_id:null,
                    category_id:2,
                    job_count:1,
                    loading:'',
                    speed:'',
                    width:'',
                    height:'',
                    diameter:'',
                    remarks:'',
                    price:'',
                    title:'',
                    error:null
                };
            case 3:
                return {
                    brand_id :null,
                    origin_id:null,
                    category_id:3,
                    job_count:1,
                    ah:'',
                    size:'',
                    price:'',
                    title:'',
                    remarks:'',
                    error:null
                };
            case 4:
                return {
                    brand_id :null,
                    origin_id:null,
                    category_id:4,
                    job_count:1,
                    width:'',
                    model:'',
                    holes:'',
                    et_offset:'',
                    color:'',
                    price:'',
                    title:'',
                    remarks:'',
                    error:null
                };
            case 5:
                return {
                    category_id:5,
                    job_count:0,
                    price:'',
                    title:'',
                    remarks:'',
                    error:null
                };
            case 1:
                return {
                    category_id:1,
                    job_count:1,
                    price:'',
                    title:'',
                    remarks:'',
                    error:null
                };
            default:
                return null
        }
    }

    additionalForm(category_id){
        switch (category_id) {
            case 2:
                return <Tyre values={this.state} onChange={(o)=>this.setState({...o})}/>;
            case 3:
                return <Battery values={this.state} onChange={(o)=>this.setState({...o})}/>;
            case 4:
                return <Wheel values={this.state} onChange={(o)=>this.setState({...o})}/>;
            default:
                return <div/>
        }
    }
    delete(){
        let {history, match} = this.props;
        let {category_id} = this.state;
        switch (category_id) {
            case 2:
                this.props.deleteItem('tyres' , match.params.id, history);
                break;
            case 3:
                this.props.deleteItem('batteries',match.params.id, history);
                break;
            case 4:
                this.props.deleteItem('wheels',match.params.id, history);
                break;
            case 5:
                this.props.deleteItem('products',match.params.id, history);
                break;
            case 1:
                this.props.deleteItem('services',match.params.id, history);
                break;
        }
    }

    submit(){
        let {history} = this.props;
        let {category_id} = this.state;
        switch (category_id) {
            case 2:
                this.props.submit('tyres' , this.state, history);
                break;
            case 3:
                this.props.submit('batteries',this.state, history);
                break;
            case 4:
                this.props.submit('wheels',this.state, history);
                break;
            case 5:
                this.props.submit('products',this.state, history);
                break;
            case 1:
                this.props.submit('services',this.state, history);
                break;
            }
    }

    render() {
        if(!this.state) return <div/>;
        const {category_id, product_code, price, title, remarks, job_count} = this.state;
        const {transactions} = this.props;
        const _transactions = transactions.map((t, i)=>({...t, key:i}));
        return <div style={{position:'relative', height:'100%'}}>
            <div style={{position:'absolute', left:0, top:0, bottom:0, right:0}}>
                <div className="mt-3">
                    <button className="btn btn-primary btn-sm" onClick={this.submit}>
                        儲存產品
                    </button>
                    <button className="btn btn-danger btn-sm ml-2" onClick={this.delete}>
                        刪除產品
                    </button>
                    <span>只限產品沒有入貨及銷售紀錄</span>
                </div>
                {this.additionalForm(category_id)}
                <div className="p-3 mt-3" style={{background:'#fff'}}>
                    <h6 className="font-weight-bold">產品資料</h6>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-4">
                                <label>產品編號*</label>
                                <input className="form-control" value={product_code} onChange={(e)=>this.setState({product_code:e.target.value.toUpperCase()})}/>
                            </div>
                            <div className="col-md-4">
                                <label>定價*</label>
                                <input type='number' className="form-control" value={price} onChange={(e)=>this.setState({price:e.target.value})}/>
                            </div>
                            <div className="col-md-4">
                                <label>產品名稱*</label>
                                <input className="form-control" value={title} onChange={(e)=>this.setState({title:e.target.value})}/>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-4">
                                <label>工作計算(Job Count)</label>
                                <input className="form-control" value={job_count} onChange={(e)=>this.setState({job_count:e.target.value})}/>
                            </div>
                            <div className="col-md-4">
                                <label>備註*</label>
                                <textarea className="form-control" value={remarks} onChange={(e)=>this.setState({remarks:e.target.value})}/>
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.match.params.id &&
                <div className="p-3 mt-3" style={{background:'#fff'}}>
                    <h6 className="font-weight-bold">銷售資料</h6>
                    <div className="custom-tab-body py-2">
                        <Table data={_transactions}
                               accessors={[
                                   {key:'type', title:'Type'},
                                   {key:'order_number', title:'Order Number', render:((item) =>
                                           {
                                               if(item.type ==='sales'){
                                                   return <Link className="btn btn-sm btn-secondary" to={`/sales/item/${item.order_number}`}>{item.order_number}</Link>
                                               }else{
                                                   return <Link className="btn btn-sm btn-secondary"  to={`/purchase/item/${item.order_number}`}>{item.order_number}</Link>
                                               }
                                           }
                                       )},
                                   {key:'quantity', title:'數量'},
                                   {key:'price', title:'價格'},
                                   {key:'reference_number', title:'Reference'},
                                   {key:'remarks', title:'Remarks'},
                                   {key:'value_date', title:'建立日期', render:((item) => <Date date={item.value_date} time={true}/>)},
                               ]}
                               tableKey='transaction'
                               rowKey='key'
                               search={(item, text)=>item.order_number.toUpperCase().includes(text.toUpperCase()) ||
                                   (item.reference_number? item.reference_number.toUpperCase().includes(text.toUpperCase()):false)}
                        />
                    </div>
                </div>
                }
            </div>
        </div>
    }
}

export default connect((state)=>{return {item: state.product.item, transactions: state.product.transactions}},
    {getProductItem, getProductTransactions, submit, deleteItem})(InventoryItem)