import React from 'react';
import {NavLink } from "react-router-dom";
import { connect } from 'react-redux';
class SideBar extends React.Component{
    constructor(props){
        super(props);
    }

    render() {
        return (
            <div className="bg-inverse text-white" style={{width:'300px'}}>
                <div className="">
                    <NavLink exact={true} className="py-2 px-3 d-block text-white side-button" to={'/'}>
                        綜合資料
                    </NavLink >
                    <NavLink className="py-2 px-3 d-block text-white side-button" to={'/checkin'}>
                        打卡易
                    </NavLink >
                    <div style={{borderBottom:'1px solid #555'}}>
                        <small className="px-2">
                            管理店舖
                        </small>
                    </div>
                    <NavLink className="py-2 px-3 d-block text-white side-button" to={'/cars'}>
                        車輛紀錄
                    </NavLink>
                    <NavLink className="py-2 px-3 d-block text-white side-button" to={'/human'}>
                        人才管理
                    </NavLink >
                    <NavLink className="py-2 px-3 d-block text-white side-button" to={'/stocks'}>
                        點倉易
                    </NavLink >
                    <div style={{borderBottom:'1px solid #555'}}>
                        <small className="px-2">
                            管理買賣
                        </small>
                    </div>
                    <NavLink className="py-2 px-3 d-block text-white side-button" to={'/sales'}>
                        銷售項目
                    </NavLink >
                    <NavLink className="py-2 px-3 d-block text-white side-button" to={'/inventory'}>
                        產品目錄
                    </NavLink>
                    <NavLink className="py-2 px-3 d-block text-white side-button" to={'/purchase'}>
                        入貨資料
                    </NavLink >
                    <NavLink className="py-2 px-3 d-block text-white side-button" to={'/expenditure'}>
                        支出項目
                    </NavLink>
                    <div style={{borderBottom:'1px solid #555'}}>
                        <small className="px-2">
                            管理資金
                        </small>
                    </div>
                    <NavLink  className="py-2 px-3 d-block text-white side-button" to={'/cash'}>
                        現金管理
                    </NavLink>
                    <NavLink  className="py-2 px-3 d-block text-white side-button" to={'/payable'}>
                        應付帳管理
                    </NavLink>
                    <NavLink  className="py-2 px-3 d-block text-white side-button" to={'/supplierPayable'}>
                        供應商應付帳管理
                    </NavLink>
                    <NavLink  className="py-2 px-3 d-block text-white side-button" to={'/receivable'}>
                        應收帳管理
                    </NavLink>
                    <NavLink  className="py-2 px-3 d-block text-white side-button" to={'/customerReceivable'}>
                        客戶應收帳管理
                    </NavLink>
                    <div style={{borderBottom:'1px solid #555'}}>
                        <small className="px-2">
                            管理員設定
                        </small>
                    </div>
                    <NavLink  className="py-2 px-3 d-block text-white side-button" to={'/counterparty'}>
                        客戶設定
                    </NavLink>
                    <NavLink  className="py-2 px-3 d-block text-white side-button" to={'/discount'}>
                        優惠卷設定
                    </NavLink>
                </div>
            </div>
        );
    }
}

export default connect((state)=>{return {data:state.core.data}},{})(SideBar)