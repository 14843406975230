import {get, post, deleteRequest} from '../axios';
const GET_FORM_DATA = 'GET_FORM_DATA';
const GET_PRODUCTS = 'GET_PRODUCTS';
const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';
const GET_PRODUCT_ITEM = 'GET_PRODUCT_ITEM';
const GET_PRODUCT_ITEM_TRANSACTION = 'GET_PRODUCT_ITEM_TRANSACTION';
const initialState= {
    data:{
        //for storing form require data
    },
    list:{

    },
    item:null,
    transactions:[]
};


export default function (state = initialState, action){
    switch (action.type){
        case GET_PRODUCTS:
            return Object.assign({}, state, {list: list(state.list, action)});
        case GET_PRODUCT_LIST:
            return Object.assign({}, state, {list: list(state.list, action)});
        case GET_FORM_DATA:
            return Object.assign({}, state, {data: data(state.data, action)});
        case GET_PRODUCT_ITEM:
            return Object.assign({}, state, {item:action.data});
        case GET_PRODUCT_ITEM_TRANSACTION:
            return Object.assign({}, state, {transactions:action.data});
        default:
            return state;
    }
}

function list(state, action){
    switch (action.type){
        case GET_PRODUCTS:
            return Object.assign({}, state, {[action.key]: action.data});
       case GET_PRODUCT_LIST:
            return Object.assign({}, state, {[action.key]: action.data});
        default:
            return state;
    }
}

function data(state, action){
    switch (action.type){
        case GET_FORM_DATA:
            return Object.assign({}, state, {[action.key]: action.data});
        default:
            return state;
    }
}

export function getProduct(key, showAll, allStores){
    const params = {
        showAll:!!showAll,
        allStores:!!allStores
    };
    return async (dispatch)=>{
        let data = await dispatch(get(`/${key}/items`, params));
        return dispatch({type:GET_PRODUCTS, key, data:data})
    }
}
export function getProductList(key, showAll, allStores){
    const params = {
        showAll:!!showAll,
        allStores:!!allStores
    };
    return async (dispatch,getState)=>{
        let {list} = getState().product
        let data = await dispatch(get(`/${key}/items`, params))||[];
        const plist = list[key]||[]
        if(plist.length !== data.length) {
            return dispatch({type: GET_PRODUCT_LIST, key, data: data})
        }
    }
}

export function getProductItem(key, id){
    return async (dispatch)=>{
        let data = await dispatch(get(`/${key}/items/${id}`));
        return dispatch({type:GET_PRODUCT_ITEM, key, data:data})
    }
}

export function getProductTransactions(id){
    return async (dispatch)=>{
        dispatch({type:GET_PRODUCT_ITEM_TRANSACTION, data:[]});
        let data = await dispatch(get(`/products/items/${id}/transactions`));
        return dispatch({type:GET_PRODUCT_ITEM_TRANSACTION, data:data})
    }
}

export function deleteItem(key, product_id, history){
    return async (dispatch)=>{
        try{
            await dispatch(deleteRequest(`/${key}/items/${product_id}`));
            history.push('/inventory')
        }catch (e) {
            console.log(e)
        }
    }
}

export function submit(key, data, history){
    return async (dispatch)=>{
        try{
            await dispatch(post(`/${key}/items/${data.id?data.id:''}`, data));
            history.push('/inventory')
        }catch (e) {
            console.log(e)
        }
    }
}

export function getFormData(key, data){
    return  {type:GET_FORM_DATA, key, data}
}
