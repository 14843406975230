import { combineReducers } from 'redux'
import core from './core'
import product from './product'
import supplier from './supplier'
import counterparty from './counterparty'
import user from './user'
import purchase from './purchase'
import sale from './sale'
import tempSale from './tempSale'
import kioskSale from './kioskSale'
import expenditure from './expenditure'
import returns from './returns'
import payable from './payable'
import receivable from './receivable'
import cash from './cash'
import table from './table'
const app = combineReducers({
    core, product, user, supplier, counterparty, purchase, sale, tempSale,
    kioskSale,
    expenditure, returns, payable, receivable, cash, table
});

export default app